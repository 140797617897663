import PropTypes from 'prop-types';
import React from 'react';
import { getCsrfToken } from 'Shared/resources/assets/app/js/utils/csrfToken';

const Form = ({
    id,
    action,
    className,
    method = 'POST',
    acceptCharset = 'UTF-8',
    defaultClasses = 'ui-ignore-changes',
    classPrefix = 'ui-form-',
    layout = 'horizontal',
    enctype = 'application/x-www-form-urlencoded',
    isSearchForm = false,
    onSubmit = (e) => e.preventDefault(),
    children,
    ...otherProps
}) => {
    const buildClassName = () => {
        return defaultClasses + (layout ? ` ${classPrefix}${layout}` : '') + (className ? ` ${className}` : '');
    };

    return (
        <form
            id={id}
            method={method}
            action={action}
            acceptCharset={acceptCharset}
            className={buildClassName()}
            encType={enctype}
            onSubmit={onSubmit}
            {...otherProps}
        >
            <input type="hidden" name="_token" value={getCsrfToken()} />
            <div className={`ui-form-generator ${isSearchForm ? 'ui-form-generator-search-form' : ''}`}>
                <div className="ui-form-generator-columns">{children}</div>
            </div>
        </form>
    );
};

Form.propTypes = {
    id: PropTypes.string,
    method: PropTypes.string,
    acceptCharset: PropTypes.string,
    action: PropTypes.string,
    className: PropTypes.string,
    defaultClasses: PropTypes.string,
    classPrefix: PropTypes.string,
    layout: PropTypes.oneOf(['horizontal', 'vertical']),
    enctype: PropTypes.oneOf(['application/x-www-form-urlencoded', 'multipart/form-data']),
    isSearchForm: PropTypes.bool,
    onSubmit: PropTypes.func,
};

export { Form };
