import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { DebouncedTextInput } from 'Shared/resources/assets/app/js/ui/forms/inputs';

const NumberRange = class NumberRange extends React.Component {
    static propTypes = {
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
        identifier: PropTypes.string,
        value: PropTypes.object,
        isLoading: PropTypes.bool,
        isOpened: PropTypes.bool,
        isHidden: PropTypes.bool,
        zIndex: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        onToggle: PropTypes.func,
    };

    static defaultProps = {
        identifier: 'number-range',
        value: { from: '', to: '' },
        isLoading: false,
        isOpened: false,
        isHidden: false,
        zIndex: 1,
        onToggle: () => {},
    };

    state = {
        isOpened: this.props.isOpened,
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.isOpened !== this.state.isOpened) {
            this.setState({
                isOpened: newProps.isOpened,
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.closeTimeout);
    }

    onToggle = (event) => {
        if (event && (event?.target.matches('.fa-times-circle') || !event?.target.closest('.title'))) {
            return;
        }

        this.setState(
            {
                isOpened: !this.state.isOpened,
            },
            this.props.onToggle.bind(null, this.props.identifier, this.state.isOpened)
        );
    };

    /**
     * On change the value.
     *
     * @param {string} fromOrTo
     * @param {Object} e
     */
    onChange = (fromOrTo, e) => {
        if (!e.target.validity.valid) {
            return;
        }

        let value = e.target.value;

        this.props.onChange(this.props.identifier, {
            ...this.props.value,
            [fromOrTo]: value.length === 0 ? '' : value.indexOf('.') !== -1 ? parseFloat(value) : parseInt(value),
        });
    };

    /**
     * On mouse leave.
     *
     * @param {Event|int} timeout
     */
    onMouseLeave = (timeout) => {
        timeout = _.isInteger(timeout) ? timeout : 500;

        this.closeTimeout = setTimeout(() => this.state.isOpened && this.onToggle(), timeout);
    };

    /**
     * On mouse enter.
     */
    onMouseEnter = () => {
        if (this.state.isOpened) {
            clearTimeout(this.closeTimeout);
        }
    };

    render() {
        if (this.props.isHidden) {
            return null;
        }

        const fromIsSet = !_.isEmpty(String(this.props.value.from));
        const toIsSet = !_.isEmpty(String(this.props.value.to));
        const isActive = fromIsSet || toIsSet;

        let valueLabel = '';

        if (isActive) {
            const from = fromIsSet ? this.props.value.from : '...';
            const to = toIsSet ? this.props.value.to : '...';

            valueLabel = `${from} - ${to}`;
        }

        return (
            <div
                key={this.props.identifier}
                className={[
                    'ui-filter',
                    'number-range',
                    isActive ? 'active' : '',
                    this.props.isLoading ? 'loading' : '',
                    this.state.isOpened ? 'opened' : '',
                ].join(' ')}
                onClick={this.onToggle}
                onMouseOver={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                <div className="title" style={{ zIndex: this.props.zIndex + 1 }}>
                    {ts.apply(null, _.isArray(this.props.name) ? this.props.name : [this.props.name])}
                    {isActive ? (
                        <div className="value-label" title={valueLabel}>
                            <span>{valueLabel}</span>

                            {this.props.isLoading ? (
                                <i className="fa fa-spinner fa-spin" />
                            ) : (
                                <i
                                    title={ts('Reset filter')}
                                    className="fa fa-times-circle"
                                    onClick={this.props.onChange.bind(null, this.props.identifier, {
                                        from: '',
                                        to: '',
                                    })}
                                />
                            )}
                        </div>
                    ) : this.props.isLoading ? (
                        <i className="fa fa-spinner fa-spin" />
                    ) : (
                        <i className={'fa fa-sort-' + (this.state.isOpened ? 'asc' : 'desc')} />
                    )}
                </div>

                <div className="toggling-list" style={{ zIndex: this.props.zIndex }}>
                    <div className="ui-form-horizontal">
                        <div className="ui-form-group">
                            <label className="ui-form-label" title={ts('From')}>
                                {ts('From')}
                            </label>
                            <DebouncedTextInput
                                placeholder={ts('e.g. 99.95')}
                                className="ui-form-element-text"
                                pattern="^[1-9]\d*(\.\d+)?$"
                                timeout={400}
                                value={String(this.props.value.from)}
                                onChange={this.onChange.bind(null, 'from')}
                            />
                        </div>
                        <div className="ui-form-group">
                            <label className="ui-form-label" title={ts('To')}>
                                {ts('To')}
                            </label>
                            <DebouncedTextInput
                                placeholder={ts('e.g. 99.95')}
                                className="ui-form-element-text"
                                pattern="^[1-9]\d*(\.\d+)?$"
                                timeout={400}
                                value={String(this.props.value.to)}
                                onChange={this.onChange.bind(null, 'to')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export { NumberRange };
