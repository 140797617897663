let uniqueIdCalls = 0;

export const uniqueId = function (prefix = '') {
    uniqueIdCalls++;
    return prefix + (new Date().getTime() + uniqueIdCalls).toString(16) + Math.random().toString(16).slice(2);
};

export function waitFor(obj, propertyName, attempts = 0) {
    return new Promise((resolve, reject) => {
        const maxAttempts = 10;
        const delay = 700;

        if (obj[propertyName]) {
            resolve(obj[propertyName]);
        } else if (attempts < maxAttempts) {
            setTimeout(() => {
                resolve(waitFor(obj, propertyName, attempts + 1));
            }, delay);
        } else {
            reject(new Error(`Max attempts reached. ${propertyName} is still null.`));
        }
    });
}

export const isEmpty = (obj) => [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;
