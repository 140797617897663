import _ from 'lodash';
import { App } from 'Shared/resources/assets/app/js/App';
import { onDocumentReady } from 'Shared/resources/assets/app/js/helpers/domHelpers';
import 'Shared/resources/assets/ckeditor4';
import { Popup } from 'Shared/resources/assets/app/js/ui/libs/Popup';

const WysiwygEditor = {
    /**
     * Editor assets
     */
    basePath: '/dist/ckeditor4',
    /**
     * Initializes a new editor instance
     *
     * @param {string|object} target  ID of target text area or the dom element
     * @param {object}        options The options for the newly initiated CKEDITOR
     */
    init: function (target, options) {
        // Make sure DOM is loaded entirely, just in case somebody calls init too early...
        onDocumentReady(() => {
            this.loadEditor(target, options);
        });
    },

    /**
     * Replaces target object with editor
     *
     * @param {string|object} target  ID of target text area, or simply the textarea dom object
     * @param {object}        options The options to be passed to the CKEDITOR
     */
    loadEditor: function (target, options = {}) {
        const defaultOptions = {
            initMethod: 'replace',
            settings: {},
            plugins: '',
            pluginAttributes: '',
            onInstanceReadyCallback: () => {},
        };
        const mergedOptions = _.defaults({}, options, defaultOptions);

        const targetObject = _.isString(target) ? document.getElementById(target) : target;

        // Call editor's internal loader
        CKEDITOR[mergedOptions.initMethod](target, {
            width: '100%',
            height: getComputedStyle(targetObject).height,
            extraAllowedContent: 'span(placeholder,name,editor-attribute,entity)[contenteditable,class]', // Required for plugins
            extraPlugins: mergedOptions.plugins,
            on: {
                instanceReady: (event) => {
                    mergedOptions.onInstanceReadyCallback(event);
                    Popup.parentDocument(true).adjustHeight();
                },
                pluginsLoaded: function (event) {
                    event.editor.dataProcessor.dataFilter.addRules({
                        comment: () => false,
                    });
                },
            },
            ...mergedOptions.settings,
        });

        CKEDITOR.config['attributes'] = mergedOptions.pluginAttributes;
    },

    /**
     * Destroys editor instance.
     *
     * @param {string} target ID of target text area
     */
    destroyEditor: function (target) {
        CKEDITOR.instances[target].destroy(true);
    },

    /**
     * Destroy all editor instances.
     */
    destroyAll: function () {
        for (let instanceName in CKEDITOR.instances) {
            CKEDITOR.instances[instanceName].destroy(true);
        }
    },

    /**
     * Since the editor only updates on form submit you may want to get the content manually.
     *
     * @param {string} target ID of target text area
     *
     * @returns {string} HTML
     */
    getData: function (target) {
        return CKEDITOR.instances[target].getData();
    },

    /**
     * Returns plain text content of editor.
     *
     * @param {string} target ID of target text area
     *
     * @returns {string} Plain text
     */
    getPlainText: function (target) {
        return CKEDITOR.instances[target].editable().getText();
    },

    /**
     * Update the values of all editor instances.
     *
     * This method may be required for example for validation.
     * If this method is not being called, the value will only update on form submit.
     */
    updateAll: function () {
        if (typeof CKEDITOR === 'undefined') {
            return;
        }

        for (let instanceName in CKEDITOR.instances) {
            if (CKEDITOR.instances.hasOwnProperty(instanceName)) {
                CKEDITOR.instances[instanceName].updateElement();
            }
        }
    },
};

// TODO: remove the following line when its use will be removed from blade.php files
App.WysiwygEditor = WysiwygEditor;

export { WysiwygEditor };
