import React from 'react';
import _ from 'lodash';
import { App } from 'Shared/resources/assets/app/js/App';
import { Flatpickr, FlatpickrClearDatePlugin } from 'Shared/resources/assets/app/js/ui/pickers/flatpickr';
import { i18n, ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { Config } from 'Shared/resources/assets/app/js/utils/Config';
import { configureFlatpickr } from 'Shared/resources/assets/app/js/utils/configureFlatpickr';
import { BaseDateTimePicker } from 'Shared/resources/assets/app/js/ui/pickers';

const TimePicker = class TimePicker extends React.Component {
    static defaultProps = {
        className: '',
        getDateTimePickerInstance: () => {},
    };

    constructor(props) {
        super(props);
        let value = '';

        if (props.hasOwnProperty('defaultValue')) {
            value = props.defaultValue;
        }

        if (props.hasOwnProperty('value')) {
            value = props.value;
        }

        this.state = { value };
    }

    UNSAFE_componentWillReceiveProps(props) {
        let value = this.state.value;

        if (props.hasOwnProperty('value')) {
            value = props.value;
        } else if (props.hasOwnProperty('defaultValue')) {
            value = props.defaultValue;
        }

        this.setState({ value });
    }

    getValue = () => {
        return this.state.value;
    };

    reset = () => {
        this.setState({
            value: '',
        });
    };

    render() {
        return (
            <BaseDateTimePicker
                type="TimePicker"
                {...this.props}
                value={this.state.value}
                placeholder={this.props.placeholder || ts('e.g. %1', [i18n('2001-01-01 01:01:01').time()])}
                className={'ui-form-element-time ui-date-time-picker ui-time-picker ' + this.props.className}
                onChange={(k, value) => {
                    if (this.state.value === value) {
                        return;
                    }

                    this.setState({ value }, () => {
                        if (this.props.onChange !== undefined) {
                            this.props.onChange(value);
                        }
                    });
                }}
                onClose={(k, value) => {
                    if (this.props.onClose !== undefined) {
                        this.props.onClose(value);
                    }
                }}
                onOpen={(k, value) => {
                    if (this.props.onOpen === undefined) {
                        return;
                    }

                    let response = this.props.onOpen(this.state.value);

                    if (response) {
                        if (this.state.value === response) {
                            return;
                        }

                        this.setState({
                            value: response,
                        });
                    }
                }}
            />
        );
    }
};

/**
 * Time picker.
 *
 * @param {Object} element
 * @param {Object} config
 *
 * @return {Flatpickr}
 */
function UiTimePicker(element, config) {
    configureFlatpickr();

    return new Flatpickr(
        element,
        _.extend(
            {
                altInput: true,
                altFormat: Config.get('app.i18n').time.replace('g', 'h').replace('a', 'K'),
                enableTime: true,
                noCalendar: true,
                time_24hr: Config.get('app.i18n').time === 'H:i',
                wrap: false,
                allowInput: true,
                defaultHour: '__',
                defaultMinute: '__',
                defaultDate: element.getAttribute('data-valueRaw') ? element.getAttribute('data-valueRaw') : undefined,
                plugins: [
                    new FlatpickrClearDatePlugin({
                        confirmText: ts('Clear'),
                    }),
                ],
            },
            config || {},
        ),
    );
}

// Needed in Shared/resources/views/form_builder/input.blade.php
App.Ui = App.Ui ?? {};
App.Ui.TimePicker = UiTimePicker;

export { TimePicker, UiTimePicker };
