import * as PropType from 'prop-types';
import React from 'react';
import { Button } from 'antd';

const IconButton = ({ icon, ...otherProps }) => {
    return <Button type="text" shape="circle" icon={icon} {...otherProps} />;
};

IconButton.propTypes = {
    icon: PropType.node.isRequired,
};

export { IconButton };
