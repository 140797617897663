import { Config } from 'Shared/resources/assets/app/js/utils/Config';

export function getApp() {
    return Config.get('app.application').toLowerCase();
}

export function isAuthenticationApp() {
    return getApp() === 'authentication';
}

export function isEmployeeApp() {
    return getApp() === 'employee';
}

export function isPlannerApp() {
    return getApp() === 'planner';
}

export function isRecruitingApp() {
    return getApp() === 'recruiting';
}
