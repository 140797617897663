import PropTypes from 'prop-types';
import React from 'react';

class TextInputMultipleLines extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.string,
        className: PropTypes.string,
        rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        id: PropTypes.string,
        width: PropTypes.string,
        defaultClasses: PropTypes.string,
        classPrefix: PropTypes.string,
        disabled: PropTypes.bool,

        getRef: PropTypes.func,
        onChange: PropTypes.func,
    };

    /**
     * Returns default component properties.
     *
     * @returns {object}
     */
    static defaultProps = {
        classPrefix: 'ui-form-element-',
        defaultClasses: '',
        width: 'large',
        value: '',
        rows: '10',
        disabled: false,

        getRef: () => {},
        onChange: () => {},
    };

    type = 'textarea';

    /**
     * Builds the class string.
     *
     * @returns {string}
     */
    buildClassName = () => {
        return (
            this.props.defaultClasses +
            (this.type ? ' ' + this.props.classPrefix + this.type : '') +
            (this.props.width ? ' ' + this.props.classPrefix + 'width-' + this.props.width : '') +
            (this.props.className ? ' ' + this.props.className : '')
        );
    };

    render() {
        return (
            <textarea
                ref={this.props.getRef}
                id={this.props.id}
                name={this.props.name}
                value={this.props.value || ''}
                className={this.buildClassName()}
                onChange={this.props.onChange}
                rows={this.props.rows}
                disabled={this.props.disabled}
            />
        );
    }
}

export { TextInputMultipleLines };
