import PropTypes from 'prop-types';
import React from 'react';

const Input = ({
    type,
    name,
    value = '',
    title,
    placeholder = '',
    className,
    id,
    width = 'large',
    defaultClasses = '',
    classPrefix = 'ui-form-element-',
    readOnly = false,
    disabled = false,
    pattern = '',

    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    onClick = () => {},
    setRef = () => {},
}) => {
    /**
     * Builds the class string.
     *
     * @returns {string}
     */
    const buildClassName = () => {
        return (
            defaultClasses +
            (type ? ` ${classPrefix}${type}` : '') +
            (width ? ` ${classPrefix}width-${width}` : '') +
            (className ? ` ${className}` : '')
        );
    };

    return (
        <input
            ref={setRef}
            type={type}
            name={name}
            value={value}
            id={id}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onClick={onClick}
            className={buildClassName()}
            title={title}
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={disabled}
            pattern={pattern}
        />
    );
};

Input.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    width: PropTypes.string,
    defaultClasses: PropTypes.string,
    classPrefix: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    pattern: PropTypes.string,

    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    setRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export { Input };
