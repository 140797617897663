import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { CheckboxCollection } from 'Shared/resources/assets/app/js/ui/filters';

class RadioCollection extends React.Component {
    static propTypes = {
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // could be missing if customToggler is set.
        collection: PropTypes.objectOf(
            PropTypes.shape({
                name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                row: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            })
        ).isRequired,
        collectionOrder: PropTypes.array,
        collectionKeysAreNumeric: PropTypes.bool,
        identifier: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.any,
        defaultValue: PropTypes.any,
        isLoading: PropTypes.bool,
        isOpened: PropTypes.bool,
        isHidden: PropTypes.bool,
        alwaysDisplayCollectionFilter: PropTypes.bool,
        enableResetFilter: PropTypes.bool,
        zIndex: PropTypes.number,
        icon: PropTypes.string,
        customToggler: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onToggle: PropTypes.func,
    };

    static defaultProps = {
        identifier: 'radio-collection',
        icon: 'circle',
        placeholder: '',
        value: null,
        isOpened: false,
        onToggle: () => {},
    };

    state = {
        value: this.props.value,
        isOpened: this.props.isOpened,
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        const state = {};

        if (!_.isEqual(newProps.value, this.state.value)) {
            state.value = newProps.value;
        }

        if (this.props.isOpened !== newProps.isOpened) {
            state.isOpened = newProps.isOpened;
        }

        if (!_.isEmpty(state)) {
            this.setState(state);
        }
    }

    /**
     * On toggle component,
     *
     * @param {string} identifier
     * @param {bool}   isOpened
     */
    onToggle = (identifier, isOpened) => {
        this.setState({ isOpened }, this.props.onToggle.bind(null, identifier, isOpened));
    };

    /**
     * Handle the filter change.
     *
     * @param {string} identifier
     * @param {array}  values
     */
    onChange = (identifier, values) => {
        let value;

        if (_.isEmpty(values) && this.props.defaultValue !== undefined) {
            value = this.props.defaultValue;
        } else {
            value = _.find(values, (value) => !_.isEqual(value, this.state.value));
            value = value === undefined ? null : value;
        }

        this.setState({ value, isOpened: false }, this.props.onChange.bind(null, identifier, value));
    };

    render() {
        const enableResetFilter =
            this.props.enableResetFilter !== undefined
                ? this.props.enableResetFilter
                : this.props.defaultValue === undefined || this.props.defaultValue !== this.props.value;

        return (
            <CheckboxCollection
                {...this.props}
                isOpened={this.state.isOpened}
                enableSelectAll={false}
                value={this.state.value === null ? [] : [this.state.value]}
                enableResetFilter={enableResetFilter}
                onToggle={this.onToggle}
                onChange={this.onChange}
            />
        );
    }
}

export { RadioCollection };
