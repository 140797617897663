import React from 'react';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { LightTooltip } from 'Shared/resources/assets/app/js/ui/AntDesign/LightTooltip';

const DivergedIcon = () => (
    <LightTooltip title={ts('Diverged')} overlayClassName="right">
        <i className="fa fa-fw fa-exclamation-triangle ui-margin-right-5" />
    </LightTooltip>
);
export { DivergedIcon };
