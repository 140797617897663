import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import { App } from 'Shared/resources/assets/app/js/App';

const BaseDateTimePicker = createReactClass({
    /* Flatpickr specific hooks */
    hooks: [
        'onChange',
        'onOpen',
        'onClose',
        'onMonthChange',
        'onYearChange',
        'onReady',
        'onValueUpdate',
        'onDayCreate',
    ],

    propTypes: {
        type: PropTypes.string.isRequired,
        defaultValue: PropTypes.string,
        onChange: PropTypes.func,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        onMonthChange: PropTypes.func,
        onYearChange: PropTypes.func,
        onReady: PropTypes.func,
        onValueUpdate: PropTypes.func,
        onDayCreate: PropTypes.func,
        getDateTimePickerInstance: PropTypes.func,
        options: PropTypes.object,
        value: PropTypes.string,
        children: PropTypes.node,
        editable: PropTypes.bool,
    },

    getDefaultProps() {
        return {
            options: {},
            getDateTimePickerInstance: () => {},
            editable: true,
        };
    },

    UNSAFE_componentWillReceiveProps: function (newProps) {
        if (
            newProps.hasOwnProperty('value') &&
            (!this.props.hasOwnProperty('value') || newProps.value !== this.props.value)
        ) {
            this.dateTimePicker.setDate(newProps.value, false);
        } else if (
            newProps.hasOwnProperty('defaultValue') &&
            (!this.props.hasOwnProperty('value') || newProps.defaultValue !== this.props.value)
        ) {
            this.dateTimePicker.setDate(newProps.defaultValue, false);
        }

        if (newProps.options !== this.props.options) {
            let options = newProps.options;

            // Add prop hooks to options
            this.hooks.forEach((hook) => {
                if (newProps.hasOwnProperty(hook)) {
                    options[hook] = [newProps[hook]];
                }
            });

            const optionsKeys = Object.getOwnPropertyNames(options);

            for (let index = optionsKeys.length - 1; index >= 0; index--) {
                const key = optionsKeys[index];
                this.dateTimePicker.set(key, newProps.options[key]);
            }
        }
    },

    componentDidMount: function () {
        const options = {
            onClose: () => {
                this.node.blur && this.node.blur();
            },
            ...this.props.options,
        };

        // Add prop hooks to options
        this.hooks.forEach((hook) => {
            if (this.props.hasOwnProperty(hook)) {
                options[hook] = [this.props[hook]];
            }
        });

        if (this.props.hasOwnProperty('value')) {
            options.value = this.props.value;
        } else if (this.props.hasOwnProperty('defaultValue')) {
            options.defaultValue = this.props.defaultValue;
        }

        if (this.props.hasOwnProperty('onMouseEnter')) {
            options.onMouseEnter = this.props.onMouseEnter;
        }

        if (this.props.hasOwnProperty('onMouseLeave')) {
            options.onMouseLeave = this.props.onMouseLeave;
        }

        this.dateTimePicker = new App.Ui[this.props.type](this.node, options, this.props.editable);

        this.props.getDateTimePickerInstance(this.dateTimePicker);
    },

    componentWillUnmount: function () {
        this.dateTimePicker.destroy();
    },

    render: function () {
        const { options, defaultValue, value, children, getDateTimePickerInstance, editable, ...props } = this.props;
        const wrap = typeof options.wrap !== 'undefined' ? false : options.wrap;

        // Don't pass hooks to dom node
        this.hooks.forEach((hook) => {
            delete props[hook];
        });

        return wrap ? (
            <div
                {...props}
                editable={editable.toString()}
                ref={(node) => {
                    this.node = node;
                }}
            >
                {children}
            </div>
        ) : (
            <input
                {...props}
                editable={editable.toString()}
                onChange={() => {}}
                value={value || defaultValue || ''}
                ref={(node) => {
                    this.node = node;
                }}
            />
        );
    },
});

export { BaseDateTimePicker };
