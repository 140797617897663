import { get } from 'lodash';
import dayjs from 'dayjs';
import { removeAccents } from 'Shared/resources/assets/app/js/helpers/stringHelpers';
import { tp } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';

export const sortColumn = (a, b, column) => {
    if (!a || !get(a, column)) {
        return -1;
    }

    if (get(a, column) instanceof Date && !isNaN(get(a, column))) {
        return get(a, column) - get(b, column);
    }

    if (typeof get(a, column) === 'string') {
        if (dayjs(get(a, column)).isValid() && dayjs(get(b, column)).isValid()) {
            return dayjs(get(a, column)) - dayjs(get(b, column));
        }

        return get(a, column).localeCompare((b ? get(b, column) : null) ?? '', undefined, { sensitivity: 'base' });
    }

    if (typeof get(a, column) === 'number') {
        return get(a, column) - ((b ? get(b, column) : null) ?? Number.NEGATIVE_INFINITY);
    }
};

/**
 * Filters 'collection' based on the presence of the 'filter' param in any of the 'fieldsToFilter'
 */
export const applyGlobalFilter = (collection = [], filter = '', fieldsToFilter = []) => {
    const normalizedFilter = removeAccents(filter).toLowerCase();

    return collection.length && filter.length && fieldsToFilter.length
        ? collection.filter((item) =>
              fieldsToFilter
                  .map((fieldToFilter) =>
                      typeof item[fieldToFilter] === 'string' || item[fieldToFilter] instanceof String
                          ? removeAccents(item[fieldToFilter]).toLowerCase()
                          : '',
                  )
                  .some((fieldToFilter) => fieldToFilter.includes(normalizedFilter)),
          )
        : collection;
};

export const getDefaultPaginationPageSize = () => 20;

export const getDefaultPaginationProps = (count) => {
    const defaultPageSize = getDefaultPaginationPageSize();

    return count > defaultPageSize
        ? {
              showTotal: (total) => tp('Total 1 item|Total %1 items', total, [total]),
              defaultPageSize: defaultPageSize,
              showSizeChanger: true,
              pageSizeOptions: Array.from(new Set([10, 20, 50, 100, defaultPageSize])),
          }
        : false;
};
