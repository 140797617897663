import $ from 'jquery';
import _ from 'lodash';
import { App } from 'Shared/resources/assets/app/js/App';
import { i18n, ts, unformatNumber } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { config } from 'Shared/resources/assets/app/js/helpers/configHelpers';
import { fire } from 'Shared/resources/assets/app/js/helpers/eventHelpers';
import { error } from 'Shared/resources/assets/app/js/helpers/notificationHelpers';
import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';
import { FormBuilder, Popup, WysiwygEditor } from 'Shared/resources/assets/app/js/ui/libs';

const topWindowDocument = getTopWindowDocument();

const Validator = {
    /**
     * Event which will be fired on validation failure.
     */
    VALIDATION_ERROR_EVENT: 'VALIDATION_ERROR_EVENT',

    /**
     * Event which will be fired on validation success.
     */
    VALIDATION_SUCCESS_EVENT: 'VALIDATION_SUCCESS_EVENT',

    /**
     * INT64 limits.
     */
    INT64_MIN: '-9223372036854775808',
    INT64_MAX: '9223372036854775807',

    /**
     * CSS class of input container
     */
    groupClass: 'ui-form-group',

    /**
     * CSS class of error message
     */
    errorClass: 'ui-form-hint',

    /**
     * CSS class of container that contains an error message
     * This class will be applied to container if an error occurs
     */
    errorGroupClass: 'ui-form-group-negative',

    /**
     * These types are storing their actual values in hidden inputs
     */
    specialTypesClass: ['ui-form-element-multi-select', 'ui-form-element-level-select'],

    /**
     * Defines which validators are available for which elements
     */
    applicableValidators: {
        input: [
            'nullable',
            'required',
            'alpha',
            'alpha_num',
            'alpha_num_dash',
            'min_length',
            'max_length',
            'min',
            'max',
            'email',
            'phone',
            'url',
            'date',
            'time',
            'date_time',
            'number',
            'positive_number',
            'float',
            'currency',
            'file',
            'same',
            'decimal',
            'color',
            'before_or_equal',
            'after_or_equal',
        ],
        textArea: ['nullable', 'required', 'min_length', 'max_length'],
        wysiwyg: ['nullable', 'required', 'min_length', 'max_length'],
        select: ['nullable', 'required'],
        multiSelect: ['nullable', 'required'],
        levelSelect: ['nullable', 'required'],
        checkbox: ['nullable', 'required'],
        file: ['nullable', 'required', 'file'],
    },

    init: function (formId, config, alertErrors, onFailure, onSuccess, checkRequiredFields = true) {
        alertErrors = alertErrors || false;
        onFailure = onFailure || function () {};
        onSuccess = onSuccess || function () {};

        // Laravel validator keys use dot notation 'input.0' but our input ids use bracket notation 'input[0]'
        // So we need to transform the id from dot to bracket notation.
        _.forEach(config, function (validators, index) {
            const bracketNotationKey = index.replace(/[.]([^\.]*)/g, '[$1]');
            if (bracketNotationKey != index) {
                config[bracketNotationKey] = config[index];
                delete config[index];
            }
        });

        this.setConfig(formId, config || {});

        // Mark required elements
        this.markElements(config);

        const form = $('#' + formId);

        form.on(
            'submit',
            this.onFormSubmit.bind(this, { alertErrors, form, formId, onFailure, onSuccess, checkRequiredFields }),
        );
    },

    addFiles: function (formId, files = {}) {
        _.setWith(
            topWindowDocument,
            ['validator', formId, 'files'],
            {
                ...this.files(formId),
                ...files,
            },
            Object,
        );
    },

    removeFiles: function (formId, fileId) {
        _.setWith(topWindowDocument, ['validator', formId, 'files'], _.omit(this.files(formId), [fileId]), Object);
    },

    files: function (formId) {
        return _.get(topWindowDocument, ['validator', formId, 'files'], {});
    },

    setConfig: function (formId, config = {}) {
        _.setWith(topWindowDocument, ['validator', formId, 'config'], config, Object);
    },

    config: function (formId) {
        return _.get(topWindowDocument, ['validator', formId, 'config'], {});
    },

    onFormSubmit: function ({ alertErrors, form, formId, onFailure, onSuccess, checkRequiredFields }, e) {
        if (Object.hasOwn(e, 'originalEvent')
            && e.originalEvent.submitter.hasAttribute('data-validator-skip-required-fields-check')
        ) {
            checkRequiredFields = false;
        }

        // Update the values of all WYSIWYG editor instances
        WysiwygEditor.updateAll();

        const passed = this.validateForm(formId, this.config(formId), alertErrors, checkRequiredFields);

        if (!passed) {
            // If validation failed, enable fake inputs for custom form elements
            // User must be able to edit them again
            FormBuilder.enableFakeInputs(form);

            // Also adjust the height of the popup, if any
            Popup.adjustHeight();

            // Scroll to first error
            $('.' + this.errorGroupClass)
                .get(0)
                ?.scrollIntoView();

            // Execute failure callback
            onFailure(form);

            // Fire failure event
            fire(this.VALIDATION_ERROR_EVENT);
        } else {
            // Execute passed callback
            onSuccess(form);

            // Fire success event
            fire(this.VALIDATION_SUCCESS_EVENT);

            if ($(form).hasClass('prevent-multiple-submit')) {
                $(form).addClass('submitted');
            }
        }

        return passed;
    },

    findElement: function (idOrName) {
        // HTML5 has no more limitations regarding characters used in class names.
        // However jQuery is not able to handle certain characters like square brackets.
        // We have to escape them here...
        idOrName = idOrName.replace(/(:|\.|\[|\]|,)/g, '\\$1');

        let $element;

        const selectors = [`#${idOrName}`, `[name=${idOrName}]`];

        for (let [index, selector] of Object.entries({ ...selectors })) {
            $element = $(selector);

            if ($element.length >= 1) {
                break;
            }

            if (parseInt(index) + 1 === selectors.length) {
                return null;
            }
        }

        // Check if element has a special types class
        // In this case the actual value is stored in a hidden input
        _.forEach(this.specialTypesClass, function (className) {
            if ($element.hasClass(className)) {
                $element = $element.find('input[type="hidden"]');
            }
        });

        // If the element is still a DIV, we have a problem
        // Try to find any input within the element
        // This applies for example for checkboxes
        if ($element.prop('tagName').toUpperCase() === 'DIV') {
            $element = $element.find('input:not(.checkbox-proxy)');
        }

        return $element;
    },

    findGroup: function (element) {
        return element.closest('.' + this.groupClass);
    },

    markElements: function (config) {
        _.forEach(
            config,
            function (validator, index) {
                // TODO: THIS IS NOT OK AND MUST BE REMOVED
                // TODO: A rule must be verified before being added to the validator, not on the fly
                if (
                    _.isObject(validator.required) &&
                    validator.required.hasOwnProperty('condition') &&
                    validator.required.condition === true &&
                    validator.required.value === true
                ) {
                    return;
                }

                // Only mark required elements
                // Other elements may be empty but valid
                if (validator.required === true || validator.minLength === true) {
                    const element = this.findElement(index);

                    // If no element is found there is nothing to do
                    if (!element) {
                        return;
                    }

                    const group = this.findGroup(element);

                    // Since checkboxes usually don't have a label,
                    // we need to add the star to their name
                    const label = group.find('label, .ui-form-element-checkbox').first().contents().last();

                    // Finally add the star to the text of the node
                    label.replaceWith(label.text().replace(' *', '') + ' *');
                }
            }.bind(this),
        );
    },

    validateForm: function (formId, config, alertErrors, checkRequiredFields = true) {
        let passed = true;

        // Loop trough elements based on config, not HTML
        _.forEach(
            config,
            function (validators, index) {
                const element = this.findElement(index);

                // If no element is found there is nothing to do
                if (!element) {
                    return;
                }

                const group = this.findGroup(element);

                if (group.hasClass('ui-hidden-by-condition')) {
                    return;
                }

                const type = this.determineType(element);
                const value = this.getValue(element, type);

                // Remove existing error messages
                group.removeClass(this.errorGroupClass);
                group.find(`.${this.errorClass}:not(.ui-form-file-hint)`).remove();

                // Loop trough validators
                _.forEach(
                    validators,
                    function (args, name) {
                        // Check if validator is applicable for current element
                        const applicableValidators = this.applicableValidators[type];

                        if (applicableValidators.length < 1 || !applicableValidators.includes(name)) {
                            throw `Validator not allowed for field type ${type}`;
                        }

                        if (name === 'required' && args === false) {
                            return;
                        }

                        // TODO: THIS IS NOT OK AND MUST BE REMOVED
                        // TODO: A rule must be verified before being added to the validator, not on the fly
                        if (_.isObject(args) && args.hasOwnProperty('condition') && args.condition !== true) {
                            return;
                        }

                        // Validate element
                        if (!this.isValid(value, name, args, index, type, formId, checkRequiredFields)) {
                            passed = false;

                            // Show error message
                            this.showError(group, name, args, alertErrors);
                        }
                    }.bind(this),
                );
            }.bind(this),
        );

        return passed;
    },

    isValid: function (value, validator, args, elementIdOrName, type, formId, checkRequiredFields = true) {
        let expr = '';
        let trimmedValue = '';
        let number;
        let element;

        if (typeof elementIdOrName === 'string') {
            element = this.findElement(elementIdOrName);
        }

        switch (validator) {
            case 'required':
                if (!checkRequiredFields) {
                    return true;
                }
                if (type !== 'file') {
                    if (typeof value === 'undefined') {
                        return false;
                    }

                    if (typeof value === 'object' && _.isEmpty(_.pickBy(value, (value) => value))) {
                        return false;
                    }

                    if (value === '' || value === null || value === false) {
                        return false;
                    }
                } else {
                    const files = this.files(formId);

                    if (value === false && !files.hasOwnProperty(elementIdOrName)) {
                        return false;
                    }
                }

                break;

            case 'alpha':
                expr = /^[a-zA-ZÀ-ÿ]+$/i;

                if (value.trim().length > 0 && !expr.test(value)) {
                    return false;
                }
                break;

            case 'alpha_num':
                expr = /^[a-zA-ZÀ-ÿ0-9]+$/i;

                if (value.trim().length > 0 && !expr.test(value)) {
                    return false;
                }
                break;

            case 'alpha_num_dash':
                expr = /^[a-zA-Z0-9-_]+$/i;

                if (value.trim().length > 0 && !expr.test(value)) {
                    return false;
                }
                break;

            case 'min_length':
                if (value.trim().length > 0 && value.length < parseInt(args)) {
                    return false;
                }
                break;

            case 'max_length':
                if (value.trim().length > 0 && value.length > parseInt(args)) {
                    return false;
                }
                break;

            case 'min':
                if (parseInt(value) < parseInt(args)) {
                    return false;
                }
                break;

            case 'max':
                if (parseInt(value) > parseInt(args)) {
                    return false;
                }
                break;

            case 'email':
                expr =
                    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;

                if (value.length > 0 && !expr.test(value)) {
                    return false;
                }
                break;

            case 'phone':
                expr = /^(?:\+|00|011)[\d. ()-]{7,}$/;

                if (value.length > 0 && !expr.test(value)) {
                    return false;
                }
                break;

            case 'url':
                expr =
                    /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

                if (value.length > 0 && !expr.test(value)) {
                    return false;
                }
                break;

            case 'date':
                const date = Date.parse(value);

                if (value.length > 0 && isNaN(date) === true) {
                    return false;
                }
                break;

            case 'time':
                expr = /^\d{1,2}:\d{2}([ap]m)?$/;

                if (value.length > 0 && !expr.test(value)) {
                    return false;
                }
                break;

            case 'date_time':
                const parts = value.split(' ');

                if (!this.isValid(parts[0], 'date', args, elementIdOrName, type, formId)) {
                    return false;
                }

                if (
                    typeof parts[1] !== 'undefined' &&
                    !this.isValid(parts[1], 'time', args, elementIdOrName, type, formId)
                ) {
                    return false;
                }
                break;

            case 'before_or_equal':
            case 'after_or_equal':
                if (
                    value.length === 0 ||
                    (!this.isValid(value, 'date', args, elementIdOrName, type, formId) &&
                        !this.isValid(value, 'date_time', args, elementIdOrName, type, formId))
                ) {
                    break;
                }

                const dateValue = new Date(Date.parse(value));

                let beforeOrAfter = new Date();

                if (args.toLowerCase() !== 'now') {
                    beforeOrAfter = new Date(Date.parse(args));

                    // Set time to midnight in case a simple format (Y-m-d) was used.
                    if (args.split(' ').length === 1) {
                        beforeOrAfter.setHours(0, 0, 0, 0);
                    }
                }

                return validator === 'before_or_equal' ? dateValue <= beforeOrAfter : dateValue >= beforeOrAfter;

            case 'number':
                expr = /^[-]?[0-9]+$/;
                number = this.getUnformattedNumber(value, expr, element);

                if (number.length > 0 && String(number).match(expr) === null) {
                    return false;
                }

                const isNegativeNumber = number.indexOf('-') === 0;

                if (
                    (isNegativeNumber && BigInt(number) < BigInt(this.INT64_MIN)) ||
                    (!isNegativeNumber && BigInt(number) > BigInt(this.INT64_MAX))
                ) {
                    return false;
                }

                break;

            case 'positive_number':
                number = ~~Number(value);

                if (value.trim().length > 0 && !(String(number) === value && number >= 0)) {
                    return false;
                }
                break;

            case 'float':
                expr = /^[-+]?[0-9]+.[0-9]+$/;
                number = this.getUnformattedNumber(trimmedValue, expr, element);

                if (number.length > 0 && !(number == parseInt(number, 10) || number.match(expr))) {
                    return false;
                }
                break;

            case 'decimal':
                expr = new RegExp('^([-]?[0-9]+).[0-9]{' + args + '}$');
                number = this.getUnformattedNumber(value, expr, element);

                if (number.length > 0) {
                    const parts = number.match(expr);

                    if (
                        parts === null ||
                        !(
                            number == parseInt(number, 10) ||
                            this.isValid(parts[1], 'number', args, elementIdOrName, type, formId)
                        )
                    ) {
                        return false;
                    }
                }
                break;

            case 'currency':
                expr = /^[-+]?\d{1,10}(?:\.\d{0,2})?$/;
                number = this.getUnformattedNumber(value, expr, element);

                if (number.length > 0 && !(!isNaN(parseInt(number)) && expr.test(number))) {
                    return false;
                }
                break;

            case 'same':
                if (value.length > 0 && value != $('#' + args).val()) {
                    return false;
                }
                break;

            case 'file':
                const files = this.files(formId);

                if (files.hasOwnProperty(elementIdOrName) && _.isObject(files[elementIdOrName])) {
                    const extensions = args.split(',');
                    const extension = files[elementIdOrName].name.split('.').pop().toLowerCase();

                    if (extensions.indexOf(extension) === -1) {
                        return false;
                    }

                    if (files[elementIdOrName].size > config('app.maxFileSizeBytes')) {
                        return false;
                    }
                }

                break;

            case 'color':
                return value.length === 0 || value.match(new RegExp(/^#[\da-fA-F]{6}$/)) !== null;
        }

        return true;
    },

    showError: function (group, validator, args, alertErrors) {
        alertErrors = alertErrors || false;

        let message = '';

        switch (validator) {
            case 'required':
                message = ts('Value must not be empty');
                break;

            case 'min_length':
                message = ts('Value must have a minimum length of %1 characters', [args]);
                break;

            case 'max_length':
                message = ts('Value must have a maximum length of %1 characters', [args]);
                break;

            case 'alpha':
                message = ts('Value must consist of letters only');
                break;

            case 'alpha_num':
                message = ts('Value must consist of letters and numbers only');
                break;

            case 'alpha_num_dash':
                message = ts('Value must consist of letters, numbers, dashes, and underscores only');
                break;

            case 'min':
                message = ts('Value must be a number, bigger than %1', [args]);
                break;

            case 'max':
                message = ts('Value must be a number, smaller than %1', [args]);
                break;

            case 'email':
                message = ts('Value must be a valid email address (in format address@domain.tld)');
                break;

            case 'phone':
                message = ts('Value must be a valid international phone number (starting with +, 00 or 011)');
                break;

            case 'url':
                message = ts('Value must be a valid URL (starting with http:// or https://)');
                break;

            case 'date':
                message = ts('Value must be a valid date (must not contain a time)');
                break;

            case 'time':
                message = ts('Value must be a valid time (in format 01:01 or 01:01:01)');
                break;

            case 'date_time':
                message = ts(
                    'Value must be a valid date and time (a date followed by ' +
                        'a space and a time in in format 01:01 or 01:01:01)',
                );
                break;

            case 'before_or_equal':
            case 'after_or_equal':
                let beforeOrAfter;

                if (args.toLowerCase() === 'now') {
                    beforeOrAfter = ts('current date and time');
                } else {
                    beforeOrAfter =
                        args.split(' ').length === 1 ? i18n(`${args} 00:00:00`).dateTime() : i18n(args).dateTime();
                }

                message = ts(
                    `Value must be set ${validator === 'before_or_equal' ? 'before' : 'after'} than or equal to %1`,
                    [beforeOrAfter],
                );
                break;

            case 'number':
                message = ts('Value must be a positive or negative number between %1 and %2', [
                    this.INT64_MIN,
                    this.INT64_MAX,
                ]);
                break;

            case 'positive_number':
                message = ts('Value must be a positive number');
                break;

            case 'float':
                message = ts('Value must be a valid decimal number');
                break;

            case 'decimal':
                message = ts('Value must be a decimal number, having %1 digits after decimal point', [args]);
                break;

            case 'currency':
                message = ts(
                    'Value must be a valid currency value (1-10 digits ' +
                        'before and 0-2 digits after the decimal point)',
                );
                break;

            case 'same':
                message = ts('Value must match "%1"', [$('#' + args).val()]);
                break;

            case 'file':
                const maxFileSizeMb = Math.round((config('app.maxFileSizeBytes') / 1048576) * 100) / 100;
                const allowedExtensions = args.replace(/,/g, ', ');

                message = ts('File must not exceed file size of %1 MB have one of the following extensions: "%2"', [
                    maxFileSizeMb,
                    allowedExtensions,
                ]);
                break;

            case 'color':
                message = ts('Value must be a valid color HEX code (e.g. #00ffff).');
                break;
        }

        if (alertErrors === true) {
            return error(message);
        }

        // Add CSS error class to element group
        group.addClass(this.errorGroupClass);

        $('<span/>', {
            class: this.errorClass,
            html: message,
        }).appendTo(group);
    },

    determineType: function (obj) {
        if (obj.prop('tagName').toUpperCase() === 'TEXTAREA') {
            // If we find any iframe within a textarea group, assume it's a WYSIWYG editor
            if (obj.parent().find('iframe').length === 1) {
                return 'wysiwyg';
            }

            return 'textArea';
        }

        if (obj.prop('tagName').toUpperCase() === 'SELECT') {
            return 'select';
        }

        if (obj.prop('tagName').toUpperCase() === 'INPUT') {
            // Level- or multi selects must have a hidden input to store the actual value
            // Since date or time pickers also have a hidden input, we must make sure to skip them
            if (obj.attr('type').toUpperCase() === 'HIDDEN' && !obj.hasClass('ui-date-time-picker')) {
                // Level selects additionally must have a data-levels attribute
                // This is actually only require for validation
                if (obj.attr('data-levels')) {
                    return 'levelSelect';
                }

                return 'multiSelect';
            }

            if (obj.attr('type').toUpperCase() === 'CHECKBOX') {
                return 'checkbox';
            }

            if (obj.attr('type').toUpperCase() === 'FILE') {
                return 'file';
            }

            return 'input';
        }

        throw 'Could not determine element type';
    },

    getValue: function (obj, type) {
        switch (type) {
            case 'select':
            case 'input':
            case 'wysiwyg':
            case 'textArea':
                return obj.val();

            // A checkbox may have a value, but we are not interested in it
            // For us it's important if it's checked or not
            case 'checkbox':
                return obj.is(':checked');

            // Just parse the JSON data of multi- and level selects
            // Validators must care about how to handle that
            case 'multiSelect':
            case 'levelSelect':
                return obj.val().length > 0 ? JSON.parse(obj.val()) : '';

            // Since file inputs can't be populated we have to set an HTML attribute
            // This is what we are going to check here
            case 'file':
                return parseInt(obj.attr('data-file')) === 1;

            default:
                throw 'Could not read value';
        }
    },

    getUnformattedNumber: function (number, expr, element) {
        number = number.trim();

        if (number.match(expr) !== null) {
            return number;
        }

        return unformatNumber(number, element ? element.data('number-format') : null);
    },
};

App.Validator = Validator;

export { Validator };
