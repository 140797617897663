import PropTypes from 'prop-types';
import React from 'react';

const Button = class Button extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        title: PropTypes.string,
        className: PropTypes.string,
        id: PropTypes.string,
        width: PropTypes.string,
        defaultClasses: PropTypes.string,
        classPrefix: PropTypes.string,
        type: PropTypes.string,
        disabled: PropTypes.bool,
        loading: PropTypes.bool,

        onClick: PropTypes.func,
    };

    /**
     * Returns default component properties.
     *
     * @returns {object}
     */
    static defaultProps = {
        classPrefix: 'ui-form-button-',
        defaultClasses: 'ui-button',
        width: 'large',
        value: '',
        type: 'button',
        name: '',
        onClick: () => {},
        disabled: false,
        loading: false,
    };

    /**
     * Builds the class string.
     *
     * @returns {string}
     */
    buildClassName = () => {
        return [
            this.props.defaultClasses,
            this.props.type ? this.props.classPrefix + this.props.type : '',
            this.props.width ? this.props.classPrefix + 'width-' + this.props.width : '',
            this.props.disabled && !this.props.loading ? 'ui-button-disabled' : this.props.className || '',
            this.props.loading ? 'loading' : '',
        ].join(' ');
    };

    render() {
        return (
            <button
                type={this.props.type}
                name={this.props.name}
                id={this.props.id}
                onClick={!this.props.disabled ? this.props.onClick : null}
                className={this.buildClassName()}
                title={this.props.title}
                disabled={this.props.disabled}
            >
                {this.props.value || this.props.children}
            </button>
        );
    }
};

export { Button };
