import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';
import { each } from 'lodash';

export const onDocumentReady = (callBack) => {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', callBack);
    } else {
        callBack();
    }
};

export const onWindowReady = (callBack) => {
    if (document.readyState === 'complete') {
        callBack();
    } else {
        window.addEventListener('load', callBack);
    }
};

/**
 * Triggers a native event on an DOM element
 *
 * @param {string} event
 * @param {Node}   element
 */
export const trigger = (event, element) => {
    if (event === 'submit' && typeof element.requestSubmit === 'function') {
        return element.requestSubmit();
    }

    return element.dispatchEvent(new Event(event));
};

/**
 * Returns an array of DOM Nodes from a DOM node(s) or a selector
 *
 * @param {Node|Node[]|string} element
 *
 * @returns {Node[]}
 */
const getNodeArrayFromElement = (element) => {
    if (!element || element.length === 0) {
        return [];
    }

    let DOMNodes;
    if (typeof element === 'string') {
        // element is a selector
        DOMNodes = document.querySelectorAll(element);
    } else {
        // element is Node(s)
        DOMNodes = element.length ? element : [element];
    }
    if (!DOMNodes.length) return [];

    return Array.from(DOMNodes);
};

/**
 * Add an event listener
 *
 * @param  {String}             event    The event type
 * @param  {Node|Node[]|string} element  The element or the selector to attach the event to (optional, defaults to window)
 * @param  {Function}           callback The callback to run on the event
 * @param  {Boolean}            capture  If true, forces bubbling on non-bubbling events
 */
export const onEvent = (event, element, callback, capture = false) => {
    let newElement = element;
    let newCallback = callback;
    let newCapture = capture;
    if (typeof newElement === 'function') {
        newCapture = newCallback;
        newCallback = newElement;
        newElement = window;
    }
    newCapture = Boolean(newCapture);
    const DOMNodes = getNodeArrayFromElement(newElement);
    DOMNodes.forEach((DOMNode) => DOMNode.addEventListener(event, newCallback, newCapture));
};

/**
 * Returns the siblings nodes of a DOM element
 *
 * @param  {Node} element
 */
export const siblings = (element) => {
    if (element.parentNode === null) return [];

    return Array.prototype.filter.call(element.parentNode.children, (child) => child !== element);
};

/**
 * Encode a set of form elements as a string for submission.
 * It returns a text string in standard URL-encoded notation.
 *
 * @param form
 */
export const serialize = (form) => {
    return new URLSearchParams(Array.from(new FormData(form))).toString();
};

export const findOrCreateContainerElement = (id, onTopWindowDocument = true) => {
    const containerDocument = onTopWindowDocument ? getTopWindowDocument() : window.document;

    let container = containerDocument.getElementById(id);

    if (!container) {
        container = document.createElement('div');
        container.setAttribute('id', id);

        containerDocument.body.appendChild(container);
    }

    return container;
};

export const getOffsetRect = function (domElement) {
    const boundingClientRect = domElement.getBoundingClientRect().toJSON();

    boundingClientRect.left = boundingClientRect.left + window.scrollX;
    boundingClientRect.top = boundingClientRect.top + window.scrollY;
    boundingClientRect.right = boundingClientRect.right + window.scrollX;
    boundingClientRect.bottom = boundingClientRect.bottom + window.scrollY;
    boundingClientRect.x =
        boundingClientRect.x === undefined ? boundingClientRect.left : boundingClientRect.x + window.scrollX;
    boundingClientRect.y =
        boundingClientRect.y === undefined ? boundingClientRect.top : boundingClientRect.y + window.scrollY;

    return boundingClientRect;
};

export const toggleCssClasses = function (htmlElement, ...cssClasses) {
    each(cssClasses, (cssClass) => {
        each(cssClass.split(' '), (token) => {
            if (htmlElement.classList.contains(token)) {
                htmlElement.classList.remove(token);
            } else {
                htmlElement.classList.add(token);
            }
        });
    });
};
