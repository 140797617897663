import React from 'react';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { LightTooltip } from 'Shared/resources/assets/app/js/ui/AntDesign/LightTooltip';

const InheritedIcon = () => (
    <LightTooltip title={ts('Inherited')} overlayClassName="right">
        <i className="fa fa-fw fa-info-circle ui-margin-right-5" />
    </LightTooltip>
);

export { InheritedIcon };
