import { config } from 'Shared/resources/assets/app/js/helpers/configHelpers';

/**
 * Security access component equivalent for client side.
 */
const SecurityAccess = {
    /**
     * Configuration key for user permissions and roles.
     */
    CONFIG_KEY: 'app.security.access',

    /**
     * Check if current user has 'permission'.
     *
     * @param {string} permission
     *
     * @returns {boolean}
     */
    hasPermission: function (permission) {
        return this.getAllPermissions().includes(permission);
    },

    /**
     * Check if current user has 'role'.
     *
     * @param {string} role
     *
     * @returns {boolean}
     */
    hasRole: function (role) {
        return this.getRoles().includes(role);
    },

    /**
     * Alias for SecurityAccess::hasPermission.
     *
     * @param {string} permission
     *
     * @returns {*|boolean}
     */
    isAllowed: function (permission) {
        return this.hasPermission(permission);
    },

    /**
     * Check if current user is denied 'permission'.
     *
     * @param {string} permission
     *
     * @returns {boolean}
     */
    isDenied: function (permission) {
        return !this.isAllowed(permission);
    },

    /**
     * Get all roles for current user.
     *
     * @returns {string[]}
     */
    getRoles: function () {
        return config(this.CONFIG_KEY).roles;
    },

    /**
     * Get all permissions for current user.
     *
     * Includes all permissions granted through the groups he belongs to but also all additional permissions
     * specifically granted for this user
     *
     * @returns {string[]}
     */
    getAllPermissions: function () {
        return config(this.CONFIG_KEY).permissions;
    },
};

export { SecurityAccess };
