import './IntervalPositionPicker.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { LightTooltip } from 'Shared/resources/assets/app/js/ui/AntDesign/LightTooltip';

class IntervalPositionPicker extends React.PureComponent {
    static propTypes = {
        value: PropTypes.oneOf(['start', 'middle', 'end']),
        defaultValue: PropTypes.oneOf(['start', 'middle', 'end']),
        orientation: PropTypes.oneOf(['vertical', 'horizontal']),
        tooltips: PropTypes.shape({
            start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            middle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        }),
        onChange: PropTypes.func,
    };

    static defaultProps = {
        value: null,
        orientation: 'horizontal',
        tooltips: {
            start: null,
            middle: null,
            end: null,
        },
        onChange: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value || props.defaultValue || null,
            orientation: props.orientation,
        };
    }

    componentDidMount() {
        // Trigger an change event in case the value in the state is different than the value in the props.
        // This situation could happen if the defaultValue was provided and the initial value is null.
        if (this.state.value !== null && this.state.value !== this.props.value) {
            this.props.onChange(this.state.value);
        }

        // Because this component is used in the inline work times interval, we must use the intersection observer
        // to observer when the container becomes visible. There is no other way to handle it in react atm.
        this.intersectionObserver = new IntersectionObserver(this.decideOrientation, {
            root: null,
            rootMargin: '0px',
            threshold: 1,
        });
        this.intersectionObserver.observe(this.containerRef);

        window.addEventListener('resize', this.decideOrientation);
    }

    componentWillUnmount() {
        this.intersectionObserver.disconnect();

        window.removeEventListener('resize', this.decideOrientation);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.value !== this.state.value) {
            this.setState({ value: newProps.value });
        }
    }

    onPositionClick = (position) => {
        if (position === this.state.value) {
            return;
        }

        this.setState(
            {
                value: position,
            },
            this.props.onChange.bind(null, position),
        );
    };

    decideOrientation = () => {
        const containerProps = this.containerRef.getBoundingClientRect();
        const orientation = containerProps.width < 160 ? 'vertical' : 'horizontal';

        if (orientation !== this.state.orientation) {
            this.setState({ orientation });
        }
    };

    /**
     * Get the position circles.
     *
     * @param {string }position
     *
     * @returns {string[]}
     */
    getPositionCircles(position) {
        switch (position) {
            case 'start':
                return ['circle', 'circle-o', 'circle-o'];

            case 'middle':
                return ['circle-o', 'circle', 'circle-o'];

            case 'end':
                return ['circle-o', 'circle-o', 'circle'];
        }
    }

    render() {
        return (
            <div
                className={`ui-interval-position-picker ${this.state.orientation}`}
                ref={(ref) => (this.containerRef = ref)}
            >
                <div className="buttons-container">
                    {['start', 'middle', 'end'].map((position) => {
                        const button = (
                            <button
                                key={position}
                                className={`ui-button-neutral ${position} ${
                                    position === this.state.value ? 'active' : ''
                                }`}
                                onClick={this.onPositionClick.bind(null, position)}
                            >
                                {this.getPositionCircles(position).map((circle, i) => (
                                    <i key={`${i}-${circle}`} className={`fa fa-${circle}`} />
                                ))}
                            </button>
                        );

                        return this.props.tooltips[position] !== null ? (
                            <LightTooltip
                                key={`tooltip-${position}`}
                                title={this.props.tooltips[position]}
                                overlayStyle={{ maxWidth: '500px' }}
                            >
                                {button}
                            </LightTooltip>
                        ) : (
                            button
                        );
                    })}
                </div>
            </div>
        );
    }
}

export { IntervalPositionPicker };
