import React from 'react';
import _ from 'lodash';
import { TextInput } from 'Shared/resources/assets/app/js/ui/forms/inputs';

const DebouncedTextInput = class DebouncedTextInput extends React.Component {
    state = {
        value: this.props.value,
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.value !== this.state.value) {
            clearTimeout(this.timeout);

            this.setState({
                value: newProps.value,
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    onChange = (e) => {
        clearTimeout(this.timeout);

        const value = e.target.value;

        if (value === this.state.value) {
            return;
        }

        e.persist();

        this.setState({ value }, () => {
            this.timeout = setTimeout(() => {
                // If a pattern is given and the value is not valid just don't call the onChange method
                if (_.isString(this.props.pattern) && this.props.pattern.length > 0 && !e.target.validity.valid) {
                    let value = parseInt(this.state.value);

                    if (isNaN(value)) {
                        value = '';
                    }

                    return this.setState({ value });
                }

                this.props.onChange(e);
            }, this.props.timeout || 300);
        });
    };

    render() {
        const { onChange, value, ...props } = this.props;

        return <TextInput {...props} value={this.state.value} onChange={this.onChange} />;
    }
};

export { DebouncedTextInput };
