import { App } from 'Shared/resources/assets/app/js/App';
import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';

const ConfigStore = {
    get: function () {
        return getTopWindowDocument().appConfig || {};
    },

    set: function (store) {
        getTopWindowDocument().appConfig = store;
    },
};

const Config = {
    has: function (key) {
        return ConfigStore.get().hasOwnProperty(key);
    },

    get: function (key, defaultValue) {
        if (!this.has(key)) {
            if (typeof defaultValue === 'undefined') {
                throw Error(`Config value not available for key: ${key}`);
            }

            return defaultValue;
        }

        return ConfigStore.get()[key];
    },

    set: function (key, value) {
        const config = typeof key === 'object' ? key : { [key]: value };

        ConfigStore.set({
            ...ConfigStore.get(),
            ...config,
        });
    },
};

App.Config = Config;

export { Config };
