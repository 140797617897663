import './AutoComplete.scss';

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { confirm } from 'Shared/resources/assets/app/js/helpers/notificationHelpers';
import { Ajax } from 'Shared/resources/assets/app/js/utils/Ajax';
import { EventHandler } from 'Shared/resources/assets/app/js/utils/EventHandler';
import { TextInput } from 'Shared/resources/assets/app/js/ui/forms/inputs';

class AutoComplete extends React.PureComponent {
    static propTypes = {
        // The list could be an object which defines an URL and additional params to send a request which returns the actual list' elements
        // or the array of elements itself
        list: PropTypes.oneOfType([
            PropTypes.shape({
                url: PropTypes.string.isRequired,
                params: PropTypes.object,
            }),
            PropTypes.array,
        ]),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        // This should be an object with of objects where the key is the index of the deletable item and the value is
        // an object to be passed to the onDeleteItemIntent callback
        deletableItems: PropTypes.objectOf(PropTypes.object),
        onDeleteItemIntent: PropTypes.func,
        // If needed, one can provide a valueValidator in order to validate the value before calling the onChange callback.
        // If the valueValidator returns false, then the value is not set into the input and also the onChange callback is not called.
        valueValidator: PropTypes.func,
        formatters: PropTypes.shape({
            // If given, the formatter will be called before calling the onChange callback.
            returnFormatter: PropTypes.func,
            // If given, the formatter will be called when rendering each element in the list.
            // Usually it is expected the formatted item to contain the item itself too: e.g. item: 15, displayFormatted(15): 15 minutes
            displayFormatter: PropTypes.func,
        }),
        onChange: PropTypes.func,
    };

    static defaultProps = {
        list: [],
        deletableItems: {},
        onDeleteItemIntent: (index, deletableItemsPassedObject) => {},
        value: '',
        valueValidator: () => true,
        formatters: {
            returnFormatter: (value) => value,
            displayFormatter: (value) => value,
        },
        onChange: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
            list: props.list,
            filteredList: props.list,
            listFilter: '',
            listIsLoading: false,
            listIsOpened: false,
            listActiveIndex: -1,
        };
    }

    componentDidMount() {
        if (_.isPlainObject(this.props.list)) {
            this.setState({ listIsLoading: true });

            Ajax.post(this.props.list.url, this.props.list.params || {}, ({ response }) => {
                this.setState({
                    listIsLoading: false,
                    list: response,
                    filteredList: response,
                });
            });
        }

        this.keyDownEventHandler = EventHandler.keyDown()
            .onArrowDown(this.onArrowDownPressed)
            .onArrowUp(this.onArrowUpPressed)
            .onEnter(this.closeList);
    }

    componentWillUnmount() {
        this.postponeListClosing();
        this.keyDownEventHandler.unregister();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const newState = {};

        if (newProps.value !== this.state.value) {
            newState.value = newProps.value;
        }

        if (
            Array.isArray(newProps.list) &&
            (newProps.list.length !== this.state.list.length || _.xor(newProps.list, this.state.list).length !== 0)
        ) {
            newState.list = newProps.list;
            newState.listActiveIndex = -1;

            this.activeRowRef = undefined;
        }

        if (newState.hasOwnProperty('list') && this.state.list.length === this.state.filteredList.length) {
            newState.filteredList = newState.list;
        }

        if (!_.isEmpty(newState)) {
            this.setState(newState);
        }
    }

    componentDidUpdate() {
        this.scrollTogglingList();
    }

    openList = () => {
        this.setState({
            listIsOpened: true,
        });
    };

    closeList = () => {
        this.closeListTimeout = setTimeout(() => {
            this.activeRowRef = undefined;
            this.setState({
                listIsOpened: false,
                listActiveIndex: -1,
            });
        }, 300);
    };

    scrollTogglingList() {
        if (!this.activeRowRef) {
            return;
        }

        const togglingListRect = this.togglingListRef.getBoundingClientRect();
        const togglingListClientHeight = this.togglingListRef.clientHeight;
        const activeRowRect = this.activeRowRef.getBoundingClientRect();
        const isViewable =
            activeRowRect.top >= togglingListRect.top &&
            activeRowRect.top <= togglingListRect.top + togglingListClientHeight;

        if (!isViewable) {
            this.togglingListRef.scrollTop = activeRowRect.top + this.togglingListRef.scrollTop - togglingListRect.top;
        }
    }

    postponeListClosing = () => {
        clearTimeout(this.closeListTimeout);
    };

    onArrowDownPressed = () => {
        if (document.activeElement !== this.mainTextInputRef) {
            return;
        }

        const index = this.state.listActiveIndex + 1;
        const listActiveIndex = this.state.filteredList[index] === undefined ? 0 : index;

        this.setState({ listActiveIndex, listIsOpened: true });

        this.onChangeValue(this.state.filteredList[listActiveIndex]);
    };

    onArrowUpPressed = (e) => {
        if (document.activeElement !== this.mainTextInputRef) {
            return;
        }

        e.preventDefault();

        let listActiveIndex;

        if (this.state.listActiveIndex === -1) {
            listActiveIndex = this.state.filteredList.length - 1;
        } else {
            const index = this.state.listActiveIndex - 1;
            listActiveIndex = this.state.filteredList[index] === undefined ? this.state.filteredList.length - 1 : index;
        }

        this.setState({ listActiveIndex, listIsOpened: true });

        this.onChangeValue(this.state.filteredList[listActiveIndex]);
    };

    onChangeSearchField = ({ target: { value } }) => {
        this.setState({
            listActiveIndex: -1,
            listFilter: value,
            listIsOpened: true,
            filteredList: this.getFilteredList(value),
        });

        this.activeRowRef = undefined;

        this.onChangeValue(value);
    };

    onChangeValue(value, e = undefined) {
        if (e?.target.matches('.fa-trash')) {
            return;
        }

        if (!this.props.valueValidator(value)) {
            return;
        }

        const currentValue = this.state.value;

        this.setState(
            () => ({
                value,
            }),
            () => {
                if (currentValue === value) {
                    return;
                }

                const formatter = this.props.formatters.returnFormatter || ((value) => value);

                this.props.onChange(formatter(value));
            }
        );
    }

    /**
     * This method doesn't perform any deletion, it only confirms the user's intention and passes further the
     * responsability of actual deleting.
     *
     * @param {int}    index
     * @param {object} deletableItemsPassedObject
     */
    onDeleteItemIntent = (index, deletableItemsPassedObject) => {
        confirm(
            ts('Are you sure you want to delete this item?'),
            this.props.onDeleteItemIntent.bind(null, index, deletableItemsPassedObject)
        );
    };

    getFilteredList(listFilter = this.state.listFilter) {
        const filter = new RegExp('(' + _.escapeRegExp(listFilter) + ')(?![^<]*>|[^<>]*</)', 'gi');

        return this.state.list.filter((item) => String(item).match(filter));
    }

    render() {
        const { list, value, valueValidator, formatters, onChange, ...props } = this.props;
        const displayFormatter = formatters.displayFormatter || ((value) => value);

        let filterRegex;
        if (this.state.listFilter.length > 0) {
            filterRegex = new RegExp('(' + _.escapeRegExp(this.state.listFilter) + ')', 'gi');
        }

        return (
            <div
                className={`ui-filter collection ui-form-element-width-large ui-form-widget-auto-complete ${
                    this.state.listIsOpened && this.state.filteredList.length > 0 ? 'opened' : ''
                }`}
            >
                <TextInput
                    setRef={(ref) => (this.mainTextInputRef = ref)}
                    value={this.state.value === null ? '' : String(this.state.value)}
                    onFocus={this.openList}
                    onBlur={this.closeList}
                    onChange={this.onChangeSearchField}
                    {...props}
                />
                {this.state.listIsOpened && this.state.filteredList.length > 0 && (
                    <div
                        className="toggling-list"
                        ref={(ref) => (this.togglingListRef = ref)}
                        onMouseEnter={this.postponeListClosing}
                        onMouseLeave={this.closeList}
                    >
                        <div className="table">
                            {this.state.list.map((item, index) => {
                                if (!_.includes(this.state.filteredList, item)) {
                                    return null;
                                }

                                let displayedItem = String(displayFormatter(item, index));

                                if (this.state.listFilter.length > 0) {
                                    const itemAsString = String(item);

                                    displayedItem = displayedItem.replace(
                                        itemAsString,
                                        itemAsString.replace(
                                            filterRegex,
                                            '<span class="ui-highlight-background">$1</span>'
                                        )
                                    );
                                }

                                return (
                                    <div
                                        key={index}
                                        ref={(ref) =>
                                            this.state.listActiveIndex === index ? (this.activeRowRef = ref) : ref
                                        }
                                        className={`row ${this.state.listActiveIndex === index ? 'active' : ''}`}
                                        onClick={(e) => this.onChangeValue(item, e)}
                                    >
                                        <div className="left" dangerouslySetInnerHTML={{ __html: displayedItem }} />
                                        {this.props.deletableItems.hasOwnProperty(index) && (
                                            <div className="left">
                                                <i
                                                    className="fa fa-trash"
                                                    onClick={this.onDeleteItemIntent.bind(
                                                        null,
                                                        index,
                                                        this.props.deletableItems[index]
                                                    )}
                                                />
                                            </div>
                                        )}
                                        <span className="clearfix" />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export { AutoComplete };
