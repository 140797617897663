import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

const TimeCountdown = (props) => {
    const [seconds, setSeconds] = useState(props.seconds);
    const interval = useRef();

    useEffect(() => {
        if (seconds > 0) {
            interval.current = setInterval(() => setSeconds((prevSeconds) => prevSeconds - 1), 1000);
        }

        return () => clearInterval(interval.current);
    }, []);

    useEffect(() => {
        if (seconds === 0) {
            props.onFinish();
            clearInterval(interval.current);
        }
    }, [seconds]);

    return `${String(Math.floor(seconds / 60)).padStart(2, '0')}:${String(seconds % 60).padStart(2, '0')}`;
};

TimeCountdown.propTypes = {
    seconds: PropTypes.number,
    onFinish: PropTypes.func,
};

TimeCountdown.defaultProps = {
    seconds: 600,
    onFinish: () => {},
};

export { TimeCountdown };
