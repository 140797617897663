import PropTypes from 'prop-types';
import React from 'react';
import { DatePicker, DateTimePicker, TimePicker } from 'Shared/resources/assets/app/js/ui/pickers';

const FlatPicker = class FlatPicker extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['DatePicker', 'DateTimePicker', 'TimePicker']),
        className: PropTypes.string,
        id: PropTypes.string,
        width: PropTypes.string,
        defaultClasses: PropTypes.string,
        classPrefix: PropTypes.string,
        editable: PropTypes.bool,
        onChange: PropTypes.func,
    };

    /**
     * Returns default component properties.
     *
     * @returns {object}
     */
    static defaultProps = {
        type: 'DateTimePicker',
        classPrefix: 'ui-form-element-',
        defaultClasses: '',
        width: 'large',
        value: '',
        editable: true,
    };

    type = 'multi-select';

    /**
     * Builds the class string.
     *
     * @returns {string}
     */
    buildClassName = () => {
        return (
            this.props.defaultClasses +
            (this.props.width ? ' ' + this.props.classPrefix + 'width-' + this.props.width : '') +
            (this.props.className ? ' ' + this.props.className : '')
        );
    };

    /**
     * On change handler.
     *
     * @param {*} value
     */
    onChangeHandler = (value) => {
        // Do nothing if value did not change
        if (this.props.value === value) {
            return;
        }

        const customEvent = new CustomEvent('change', {
            detail: {
                target: {
                    type: this.props.type,
                    name: this.props.name,
                    value: value,
                },
            },
            bubbles: true,
            cancelable: true,
        });

        this.props.onChange(customEvent);
    };

    render() {
        const { type: pickerType } = this.props;
        let Picker;
        switch (pickerType) {
            case 'DateTimePicker':
                Picker = DateTimePicker;
                break;
            case 'DatePicker':
                Picker = DatePicker;
                break;
            case 'TimePicker':
                Picker = TimePicker;
                break;
            default:
                throw new Error(`Invalid picker type: ${pickerType}`);
        }

        return (
            <Picker
                id={this.props.id}
                name={this.props.name}
                value={this.props.value || ''}
                className={this.buildClassName()}
                onClose={this.onChangeHandler}
                editable={this.props.editable}
            />
        );
    }
};

export { FlatPicker };
