import { Translator } from 'Shared/resources/assets/app/js/utils/i18n/Translator';
import { i18nMain } from 'Shared/resources/assets/app/js/utils/i18n/i18nMain';
import { config } from './configHelpers';

/**
 * Helper for single translations
 *
 * Usage example:
 * ts('My string %1 to be %2 translated', [firstValue, secondValue]);
 *
 * @param {string} string        String to be translated
 * @param {Array}  [params = []] Parameters to be replaced
 *
 * @return {string} Translated string
 */
export const ts = function (string, params = []) {
    return Translator.translate(string, params);
};

/**
 * Helper for plural translations
 *
 * Usage example:
 * tp('I have a %1 dog|I have multiple %1 dogs', dogs.length, 'small');
 *
 * @param {string} string        String to be translated
 * @param {int}    quantity      Quantity
 * @param {object} [params = []] Parameters to be replaced
 *
 * @return {string} Translated string
 */
export const tp = function (string, quantity, params = []) {
    return Translator.translatePlural(string, quantity, params);
};

export const i18n = function (value) {
    i18nMain.init();
    i18nMain.setValue(value);

    return i18nMain;
};

export const unformatNumber = function (number, numberFormat = null) {
    number = number.trim();

    if (number.length === 0) {
        return number;
    }

    const [thousandsSeparator, decimalsSeparator] = numberFormat ?? config('app.i18n', { number: ['', '.'] }).number;

    return number.split(thousandsSeparator).join('').replace(decimalsSeparator, '.');
};
