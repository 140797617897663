import './TogglingList.scss';

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { EventHandler } from 'Shared/resources/assets/app/js/utils/EventHandler';
import { TextInput } from 'Shared/resources/assets/app/js/ui/forms/inputs';

class TogglingList extends React.PureComponent {
    static propTypes = {
        value: PropTypes.arrayOf(PropTypes.number),
        // The options should be an array similar to [
        //      PropTypes.number.isRequired, - the id which is selected
        //      PropTypes.string.isRequired, - the string to search in or to be displayed in case key 2 not provided
        //      PropTypes.oneOfType([        - the object to be displayed
        //          PropTypes.number,
        //          PropTypes.string,
        //          PropTypes.object,
        //          PropTypes.array,
        //      ])
        // ]
        options: PropTypes.arrayOf(PropTypes.array),
        toggleIcon: PropTypes.string,
        height: PropTypes.number,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        value: [],
        options: [],
        toggleIcon: 'square',
        height: null,
        onChange: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            filteredOptions: props.options,
            filter: '',
            activeIndex: -1,
        };
    }

    componentDidMount() {
        this.keyDownEventHandler = EventHandler.keyDown()
            .onArrowDown(this.onArrowDownPressed)
            .onArrowUp(this.onArrowUpPressed)
            .onSpace(this.onSpacePressed);
    }

    componentWillUnmount() {
        this.keyDownEventHandler.unregister();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const ids = _.map(newProps.options, (option) => option[0]);
        const existingIds = _.map(this.props.options, (option) => option[0]);

        if (_.xor(ids, existingIds).length) {
            const filteredOptions = this.getFilteredOptions(this.state.filter, newProps.options);
            this.setState({ filteredOptions, activeIndex: -1 });
        }
    }

    componentDidUpdate() {
        this.scrollTogglingList();
    }

    scrollTogglingList() {
        if (!this.togglingListActiveRowRef) {
            return;
        }

        const togglingListRect = this.togglingListContainerRef.getBoundingClientRect();
        const togglingListClientHeight = this.togglingListContainerRef.clientHeight;
        const activeRowRect = this.togglingListActiveRowRef.getBoundingClientRect();
        const isViewable =
            activeRowRect.top >= togglingListRect.top &&
            activeRowRect.top <= togglingListRect.top + togglingListClientHeight;

        if (!isViewable) {
            this.togglingListContainerRef.scrollTop =
                activeRowRect.top + this.togglingListContainerRef.scrollTop - togglingListRect.top;
        }
    }

    onArrowDownPressed = () => {
        if (document.activeElement !== this.togglingListTextInputRef) {
            return;
        }

        const index = this.state.activeIndex + 1;
        const activeIndex = this.state.filteredOptions[index] === undefined ? 0 : index;

        this.setState({ activeIndex });
    };

    onArrowUpPressed = (e) => {
        if (document.activeElement !== this.togglingListTextInputRef) {
            return;
        }

        e.preventDefault();

        let activeIndex;

        if (this.state.activeIndex === -1) {
            activeIndex = this.state.filteredOptions.length - 1;
        } else {
            const index = this.state.activeIndex - 1;
            activeIndex =
                this.state.filteredOptions[index] === undefined ? this.state.filteredOptions.length - 1 : index;
        }

        this.setState({ activeIndex });
    };

    onSpacePressed = (e) => {
        if (document.activeElement !== this.togglingListTextInputRef || this.state.activeIndex === -1) {
            return;
        }

        e.preventDefault();

        this.toggle(this.state.filteredOptions[this.state.activeIndex][0]);
    };

    onChangeSearchField = ({ target: { value } }) => {
        this.setState({
            activeIndex: -1,
            filter: value,
            filteredOptions: this.getFilteredOptions(value),
        });

        this.togglingListActiveRowRef = undefined;
    };

    getFilteredOptions(filter = this.state.filter, options = this.props.options) {
        if (filter === '') {
            return options;
        }

        const regex = new RegExp('(' + _.escapeRegExp(filter) + ')(?![^<]*>|[^<>]*</)', 'gi');

        return options.filter((option) => String(option[1]).match(regex));
    }

    toggle = (id) => {
        const value = _.includes(this.props.value, id)
            ? _.without(this.props.value, id)
            : this.props.value.concat([id]);

        this.props.onChange(value);
    };

    render() {
        const filteredIds = this.state.filteredOptions.map((option) => option[0]);
        const allAreSelected =
            _.intersection(this.props.value, filteredIds).length === this.state.filteredOptions.length;

        return (
            <div
                className={`ui-filter collection ui-form-element-width-large ui-form-widget-toggling-list ${
                    this.props.height !== null ? 'custom-height' : ''
                }`}
                tabIndex={0}
                style={{ height: this.props.height !== null ? `${this.props.height}px` : 'auto' }}
            >
                {this.props.toggleIcon === 'square' && (
                    <i
                        className={`fa fa-fw fa${allAreSelected ? '-check' : ''}-square-o`}
                        onClick={this.props.onChange.bind(
                            null,
                            allAreSelected
                                ? _.without(this.props.value, ...filteredIds)
                                : _.union(this.props.value, filteredIds)
                        )}
                    />
                )}
                <TextInput
                    setRef={(ref) => (this.togglingListTextInputRef = ref)}
                    placeholder={ts('Filter...')}
                    value={this.state.filter}
                    onChange={this.onChangeSearchField}
                />
                <div className="toggling-list" ref={(ref) => (this.togglingListContainerRef = ref)}>
                    <div className="table">
                        {this.state.filteredOptions.map((option, index) => (
                            <div
                                key={option[0]}
                                ref={(ref) =>
                                    this.state.activeIndex === index ? (this.togglingListActiveRowRef = ref) : ref
                                }
                                className={`cursor-pointer row ${this.state.activeIndex === index ? 'active' : ''}`}
                                onClick={this.toggle.bind(null, option[0])}
                            >
                                <div className="checkbox">
                                    <i
                                        className={`fa fa-fw fa${
                                            _.includes(this.props.value, option[0]) ? '-check' : ''
                                        }-${this.props.toggleIcon}-o`}
                                    />
                                </div>
                                {_.isString(option[2]) || _.isNumber(option[2]) ? <div>{option[2]}</div> : option[2]}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export { TogglingList };
