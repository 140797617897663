import './AbstractCollectionPicker.scss';

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import { fire, listen, popListener } from 'Shared/resources/assets/app/js/helpers/eventHelpers';
import { uniqueId } from 'Shared/resources/assets/app/js/helpers/generalHelpers';
import { Ajax } from 'Shared/resources/assets/app/js/utils/Ajax';
import { SecurityAccess } from 'Shared/resources/assets/app/js/utils/SecurityAccess';
import { EventHandler } from 'Shared/resources/assets/app/js/utils/EventHandler';
import { Selector } from 'Shared/resources/assets/app/js/ui/forms/widgets';
import { AddStandardCollectionItem } from 'Core/Modules/Collections/resources/js/forms/AddStandardCollectionItem';
import { laroute } from 'Shared/resources/assets/app/js/laroute';

/**
 * Collection picker react component.
 *
 * The component caches the values into the current (parent) document to avoid making too many requests in case
 * multiple collections are rendered dynamically (e.g. generating events for a whole month).
 *
 * Collections with the same identifiers are exchanging each-other the new added items.
 */
class AbstractCollectionPicker extends React.PureComponent {
    static propTypes = {
        collectionIdentifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
        defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
        onChange: PropTypes.func,

        listItemsUrl: PropTypes.string,
        storeItemUrl: PropTypes.string,
        enableItemsCreation: PropTypes.bool,
        enableResetFilter: PropTypes.bool,
        singleSelect: PropTypes.bool,
        selectedValuesDisplay: PropTypes.number,
        selectedValuesSeparator: PropTypes.string,
        dependencyDisplay: PropTypes.any,
        placeholder: PropTypes.string,
        disabledValues: PropTypes.array,
        deletedValues: PropTypes.shape({
            display: PropTypes.bool,
            disable: PropTypes.bool,
        }),
        options: PropTypes.object,
        addItemForm: PropTypes.func,
        onNewItemStored: PropTypes.func,
    };

    static childContextTypes = {
        keyDownEventHandler: PropTypes.object,
    };

    static contextTypes = {
        keyDownEventHandler: PropTypes.object,
    };

    static defaultProps = {
        value: [],
        defaultValue: undefined,
        enableResetFilter: undefined,
        onChange: (value) => {},

        listItemsUrl: laroute.route('planner.configuration.collections.values'),
        storeItemUrl: laroute.route('planner.configuration.collections.store-item-get-value'),
        enableItemsCreation: true,
        singleSelect: false,
        selectedValuesDisplay: 1,
        selectedValuesSeparator: ', ',
        dependencyDisplay: undefined,
        placeholder: 'Please choose...',
        disabledValues: [],
        deletedValues: {
            display: false,
            disable: true,
        },
        options: {},
        onNewItemStored: (newItemId, collectionIdentifier) => {},
    };

    static events = {
        ADDED_NEW_COLLECTION_ITEM: 'ADDED_NEW_COLLECTION_ITEM',
        LOADED_VALUES: 'LOADED_VALUES',
    };

    storeRequest = null;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            addItemFormIsOpened: false,
            values: [],
            isOpened: false,
        };

        this.componentIdentifier = AbstractCollectionPicker.generateIdentifier();
    }

    /**
     * Initialize cache for all collections.
     *
     * This method is called early, right after the core modules scrips were loaded, in order to assure the existence
     * of the cache parameters before rendering the first collection picker.
     */
    static initializeCache() {
        const document = AbstractCollectionPicker.document();

        if (!document.hasOwnProperty('collectionPickers')) {
            document.collectionPickers = {
                identifiers: [],
                cachedValues: {},
                loadRequests: {},
            };
        }
    }

    /**
     * Get the document.
     *
     * We always use the parent document (if available), to make sure we always use the same cache and events handler.
     *
     * @returns {*|HTMLDocument|Popup}
     */
    static document() {
        try {
            return window.top.document;
        } catch (e) {
            return document;
        }
    }

    /**
     * Generate a new identifier for this component.
     *
     * This method makes sure that the identifier is uniquely generated per current page load.
     *
     * @returns {string}
     */
    static generateIdentifier() {
        const document = AbstractCollectionPicker.document();

        while (true) {
            const identifier = uniqueId('collection_picker_identifier_');

            if (!_.includes(document.collectionPickers.identifiers, identifier)) {
                document.collectionPickers.identifiers.push(identifier);
                return identifier;
            }
        }
    }

    /**
     * Get the children context.
     *
     * This method returns the context its children may use later. In the case of the keyDownHandler we had to make sure
     * we always pass the same object, as the collection picker may be a child of another collection picker.
     *
     * @return {Object}
     */
    getChildContext() {
        const keyDownEventHandler = this.context.keyDownEventHandler || EventHandler.keyDown().executeLastCallbacks();

        return { keyDownEventHandler };
    }

    componentDidMount() {
        this.load(this.props);

        listen(AbstractCollectionPicker.events.ADDED_NEW_COLLECTION_ITEM, this.onAddedNewItemEventHandler);
        listen(AbstractCollectionPicker.events.LOADED_VALUES, this.onLoadValuesEventHandler);
    }

    componentWillUnmount() {
        this.abortLoadRequest();

        const document = AbstractCollectionPicker.document();
        const index = document.collectionPickers.identifiers.indexOf(this.componentIdentifier);

        document.collectionPickers.identifiers.splice(index, 1);

        popListener(AbstractCollectionPicker.events.ADDED_NEW_COLLECTION_ITEM, this.onAddedNewItemEventHandler);
        popListener(AbstractCollectionPicker.events.LOADED_VALUES, this.onLoadValuesEventHandler);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            !_.isEqual(nextProps.options, this.props.options) ||
            !_.isEqual(nextProps.deletedValues, this.props.deletedValues) ||
            nextProps.listItemsUrl !== this.props.listItemsUrl
        ) {
            this.cacheKey = null;
            this.load(nextProps);
        }
    }

    abortLoadRequest(props = this.props) {
        const loadRequest = _.get(document, this.buildLoadRequestsCacheKey(props));

        if (loadRequest && loadRequest.readyState !== 4) {
            loadRequest.abort();
        }
    }

    /**
     * Build the values cache key depending on the provided options.
     *
     * @param {string} collectionIdentifier
     * @param {object} deletedValues
     * @param {object} options
     *
     * @returns {array}
     */
    buildCacheKey({ collectionIdentifier, deletedValues, options }) {
        if (!this.cacheKey) {
            this.cacheKey = [collectionIdentifier];

            if (deletedValues.display === true) {
                this.cacheKey.push('including_deleted');
            }

            for (let [key, value] of Object.entries(options)) {
                this.cacheKey.push(`${key}:${value.replace(' ', '_')}`);
            }
        }

        return this.cacheKey;
    }

    buildCachedValuesCacheKey(props = this.props) {
        return ['collectionPickers', 'cachedValues'].concat(this.buildCacheKey(props));
    }

    buildLoadRequestsCacheKey(props = this.props) {
        return ['collectionPickers', 'loadRequests'].concat(this.buildCacheKey(props));
    }

    /**
     * Load the collection values.
     *
     * @param {string}     listItemsUrl
     * @param {string|int} collectionIdentifier
     * @param {object}     deletedValues
     * @param {object}     options
     */
    load({ listItemsUrl, collectionIdentifier, deletedValues: { display: includeDeletedValues }, options }) {
        const document = AbstractCollectionPicker.document();
        const cacheProps = { collectionIdentifier, deletedValues: { display: includeDeletedValues }, options };
        const cachedValuesCacheKey = this.buildCachedValuesCacheKey(cacheProps);

        if (_.has(document, cachedValuesCacheKey)) {
            return this.setState({
                values: _.get(document, cachedValuesCacheKey),
            });
        }

        this.setState({ isLoading: true });

        this.abortLoadRequest(cacheProps);

        const loadRequestsCacheKey = this.buildLoadRequestsCacheKey(cacheProps);

        const request = Ajax.post(
            listItemsUrl,
            JSON.stringify({ collectionIdentifier, includeDeletedValues, options }),
            ({ response }) => {
                // The response is null if the request is aborted during execution
                if (_.isEmpty(response)) {
                    return;
                }

                _.setWith(document, loadRequestsCacheKey, null, Object);

                // We expect an array of arrays in form of [[id, value, deleted], ...]
                if (!Array.isArray(response)) {
                    throw 'An array was expected but something else was received.';
                }

                _.setWith(document, cachedValuesCacheKey, response, Object);

                fire(AbstractCollectionPicker.events.LOADED_VALUES, {
                    values: response,
                    cachedValuesCacheKey: cachedValuesCacheKey.join(),
                    sourceComponentIdentifier: this.componentIdentifier,
                });
            },
            { contentType: 'application/json' }
        );

        _.setWith(document, loadRequestsCacheKey, request, Object);
    }

    /**
     * Store a new item.
     *
     * This handler is called after an item creation event is fired by one or many custom collection pickers.
     * We do it like this because there are collections which have different items data, such as locations, qualifications
     * or functions.
     *
     * @param {object} item
     */
    onStoreItem = (item) => {
        if (this.storeRequest !== null) {
            return;
        }

        this.setState({ isLoading: true });

        const { storeItemUrl, collectionIdentifier, options } = this.props;

        this.storeRequest = Ajax.post(storeItemUrl, { collectionIdentifier, options, ...item }, ({ response }) => {
            this.storeRequest = null;

            // The response is null if the request is aborted during execution
            if (response === null) {
                return this.setState({ isLoading: false });
            }

            if (!Array.isArray(response)) {
                throw 'An array in form of [id, value, deleted] was expected but something else was received.';
            }

            this.props.onNewItemStored(response[0], collectionIdentifier);

            fire(AbstractCollectionPicker.events.ADDED_NEW_COLLECTION_ITEM, {
                item: response,
                collectionIdentifier,
                sourceComponentIdentifier: this.componentIdentifier,
            });
        });
    };

    /**
     * On added new item event handler.
     *
     * @param {array}  item
     * @param {string} collectionIdentifier
     * @param {string} sourceComponentIdentifier
     */
    onAddedNewItemEventHandler = ({ item, collectionIdentifier, sourceComponentIdentifier }) => {
        if (this.props.collectionIdentifier !== collectionIdentifier) {
            return;
        }

        this.setState(
            (state) => ({
                values: state.values.concat([item]),
                isLoading: sourceComponentIdentifier === this.componentIdentifier ? false : state.isLoading,
            }),
            () => {
                if (sourceComponentIdentifier !== this.componentIdentifier) {
                    return;
                }

                // Update the cached values.
                _.setWith(document, this.buildCachedValuesCacheKey(), this.state.values, Object);

                // Add the new item to the selected items.
                const [id] = item;

                if (Array.isArray(this.props.value)) {
                    this.props.onChange(this.props.value.concat([id]));
                } else {
                    this.props.onChange(id);
                }
            }
        );
    };

    /**
     * On load values event handler.
     *
     * @param {array}  values
     * @param {string} cachedValuesCacheKey
     * @param {string} sourceComponentIdentifier
     */
    onLoadValuesEventHandler = ({ values, cachedValuesCacheKey, sourceComponentIdentifier }) => {
        if (
            sourceComponentIdentifier !== this.componentIdentifier &&
            cachedValuesCacheKey !== this.buildCachedValuesCacheKey().join()
        ) {
            return;
        }

        this.setState({
            values: values,
            isLoading: false,
        });
    };

    onToggle = (isOpened) => {
        this.setState({ isOpened });
    };

    itemsCreationIsEnabled() {
        return this.props.enableItemsCreation && SecurityAccess.hasPermission('configuration.collections');
    }

    getOptions() {
        return this.state.values
            .filter(
                ([id, value, isDeleted, dependencyDisplay]) =>
                    !this.props.dependencyDisplay || parseInt(this.props.dependencyDisplay) === dependencyDisplay
            )
            .map(([id, value, isDeleted]) => {
                let isDisabled = false;

                if (this.props.disabledValues.includes(id)) {
                    isDisabled = true;
                } else if (this.props.deletedValues.display && this.props.deletedValues.disable && isDeleted === true) {
                    isDisabled = true;
                }

                return [id, value, isDisabled];
            });
    }

    render() {
        const itemsCreationIsEnabled = this.itemsCreationIsEnabled();

        let AddItemForm = null;

        if (itemsCreationIsEnabled) {
            AddItemForm = this.props.addItemForm || AddStandardCollectionItem;
        }

        const style = {};

        if (this.state.isOpened || this.state.addItemFormIsOpened) {
            style.zIndex = 100 + +this.state.isOpened + +this.state.addItemFormIsOpened;
        }

        return (
            <div
                className={`ui-collection-picker ${
                    itemsCreationIsEnabled ? 'ui-collection-picker-add-item-active' : ''
                }`}
                style={style}
            >
                <Selector
                    singleSelect={this.props.singleSelect}
                    selectedValuesDisplay={this.props.selectedValuesDisplay}
                    selectedValuesSeparator={this.props.selectedValuesSeparator}
                    placeholder={this.props.placeholder}
                    isLoading={this.state.isLoading}
                    value={this.props.value}
                    options={this.getOptions()}
                    onToggle={this.onToggle}
                    onChange={this.props.onChange}
                    enableResetFilter={this.props.enableResetFilter}
                    {...(this.props.defaultValue !== undefined ? { defaultValue: this.props.defaultValue } : {})}
                />
                <span
                    className={`ui-collection-picker-add-item-form-toggle ui-button-neutral ${
                        this.state.addItemFormIsOpened ? 'active' : ''
                    }`}
                    onClick={() => {
                        this.setState({ addItemFormIsOpened: !this.state.addItemFormIsOpened });
                    }}
                >
                    <i className="fa fa-plus" />
                </span>
                {this.state.addItemFormIsOpened === true && (
                    <div className="ui-collection-picker-add-item-form">
                        {AddItemForm !== null ? (
                            <AddItemForm onStore={this.onStoreItem} originalProps={this.props} />
                        ) : null}
                    </div>
                )}
                <div className="clearfix" />
            </div>
        );
    }
}

export { AbstractCollectionPicker };
