import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';

const BooleanFilter = class BooleanFilter extends React.Component {
    static propTypes = {
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
        identifier: PropTypes.string,
        value: PropTypes.bool,
        isHidden: PropTypes.bool,
        zIndex: PropTypes.number,
        onChange: PropTypes.func.isRequired,
    };

    /**
     * Get the default properties.
     *
     * @returns {object}
     */
    static defaultProps = {
        identifier: 'boolean',
        isHidden: false,
        value: false,
        zIndex: 1,
    };

    render() {
        if (this.props.isHidden) {
            return null;
        }

        return (
            <div
                key={this.props.identifier}
                className={['ui-filter', 'boolean', this.props.value ? 'active' : ''].join(' ')}
                onClick={this.props.onChange.bind(null, this.props.identifier, !this.props.value)}
            >
                <div className="title" style={{ zIndex: this.props.zIndex + 1 }}>
                    {ts.apply(null, _.isArray(this.props.name) ? this.props.name : [this.props.name])}
                </div>
            </div>
        );
    }
};

export { BooleanFilter };
