import { getCsrfToken } from 'Shared/resources/assets/app/js/utils/csrfToken';

/**
 * Timeout in MS after which all requests will abort.
 */
const timeout = 60_000;

const runFetch = async ({ url, method = 'GET', ...otherProps }) => {
    try {
        const controller = new AbortController();
        const { signal } = controller;
        const requestTimeout = setTimeout(() => controller.abort(), timeout);

        const response = await fetch(url, {
            method,
            ...otherProps,
            signal,
        });

        clearTimeout(requestTimeout);

        if (!response.ok) {
            // throw error if status is not ok
            throw new Error(`HTTP error. Status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error('Backend error', { error });
    }
};

const backend = {
    get: async ({ url }) => {
        return await runFetch({ url });
    },
    post: async ({ url, data }) => {
        const isFormData = data instanceof FormData;
        return await runFetch({
            url,
            method: 'POST',
            headers: {
                ...(!isFormData && { 'Content-Type': 'application/json' }),
                'X-CSRF-TOKEN': getCsrfToken(),
            },
            body: isFormData ? data : JSON.stringify(data),
        });
    },
};

export { backend };
