import styles from './FileUploadHistory.module.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'antd';
import { DeleteOutlined, HistoryOutlined } from '@ant-design/icons';
import { isPlannerApp } from 'Shared/resources/assets/app/js/helpers/appHelpers';
import { i18n, ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { useTablePagination } from 'Shared/resources/assets/app/js/hooks/useTablePagination';
import { IconButton } from 'Shared/resources/assets/app/js/ui/AntDesign/IconButton';
import { LightTooltip } from 'Shared/resources/assets/app/js/ui/AntDesign/LightTooltip';
import { backend } from 'Shared/resources/assets/app/js/backend';

const FileUploadHistory = ({ attributeId, entityId, onDeleteEntityFile = () => {} }) => {
    const [modal, contextHolder] = Modal.useModal();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [entityFiles, setEntityFiles] = useState([]);
    const pagination = useTablePagination(entityFiles, 'planner-file-history');

    useEffect(() => {
        if (isOpen && attributeId && entityId) {
            const loadEntityFiles = async () => {
                setIsLoading(true);
                const { response: entityFiles } = await backend.post({
                    url: '/planner/file-history/list',
                    data: {
                        attributeId,
                        entityId,
                    },
                });
                setEntityFiles(entityFiles);
                setIsLoading(false);
            };
            loadEntityFiles();
        }
    }, [attributeId, entityId, isOpen]);

    const onDeleteEntityFileHandler = (entityFile) => {
        modal.confirm({
            title: ts('Confirmation'),
            content: ts('Are you sure you want to delete this file?'),
            centered: true,
            onOk: async () => {
                await backend.post({
                    url: '/planner/file-history/delete',
                    data: {
                        id: entityFile.id,
                    },
                });

                setEntityFiles(entityFiles.filter(({ id }) => id !== entityFile.id));
                onDeleteEntityFile(entityFile);
            },
        });
    };

    const onClose = () => {
        setIsOpen(false);
    };

    if (!attributeId || !entityId || !isPlannerApp()) {
        return null;
    }

    const columns = [
        {
            title: ts('File name'),
            render: (entityFile) => (
                <a href={entityFile.url} target="_blank">
                    {entityFile.name}
                </a>
            ),
        },
        {
            title: ts('Uploaded at'),
            render: (entityFile) => i18n(entityFile.created_at).dateTime(),
        },
        {
            render: (entityFile) => (
                <DeleteOutlined className={styles.deleteIcon} onClick={() => onDeleteEntityFileHandler(entityFile)} />
            ),
        },
    ];

    return (
        <>
            <LightTooltip title={ts('Show file history')}>
                <IconButton
                    icon={<HistoryOutlined />}
                    className={styles.historyIcon}
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                />
            </LightTooltip>

            <Modal
                title={ts('File history')}
                open={isOpen}
                centered
                onOk={onClose}
                onCancel={onClose}
                footer={
                    <Button type="primary" onClick={onClose}>
                        {ts('Close')}
                    </Button>
                }
            >
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={entityFiles}
                    pagination={pagination}
                    loading={isLoading}
                />
            </Modal>
            {contextHolder}
        </>
    );
};

FileUploadHistory.propTypes = {
    attributeId: PropTypes.number.isRequired,
    entityId: PropTypes.number.isRequired,
    onDeleteEntityFile: PropTypes.func,
};

export { FileUploadHistory };
