import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';

export const getNotifier = function () {
    return getTopWindowDocument().App.Notification;
};

export const alert = function () {
    return getNotifier().alert(...arguments);
};

export const confirm = function () {
    return getNotifier().confirm(...arguments);
};

export const success = function () {
    return getNotifier().success(...arguments);
};

export const error = function () {
    return getNotifier().error(...arguments);
};

export const info = function () {
    return getNotifier().info(...arguments);
};

export const warning = function () {
    return getNotifier().warning(...arguments);
};

export const prompt = function () {
    return getNotifier().prompt(...arguments);
};

export const notify = function () {
    return getNotifier().notify(...arguments);
};
