import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { i18n, ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { DatePicker, DateTimePicker, TimePicker } from 'Shared/resources/assets/app/js/ui/pickers';

const DateTime = class DateTime extends React.Component {
    static propTypes = {
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
        identifier: PropTypes.string,
        value: PropTypes.string,
        defaultValue: PropTypes.string,
        isLoading: PropTypes.bool,
        isOpened: PropTypes.bool,
        isHidden: PropTypes.bool,
        zIndex: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        onToggle: PropTypes.func,
        displayedValueFormatter: PropTypes.func,
        options: PropTypes.object,
        pickerType: PropTypes.string,
    };

    static defaultProps = {
        identifier: 'date',
        value: '',
        defaultValue: '',
        isLoading: false,
        isOpened: false,
        isHidden: false,
        zIndex: 1,
        onToggle: () => {},
        displayedValueFormatter: (formattedDate) => formattedDate,
        pickerType: 'date',
        options: {},
    };

    state = {
        isOpened: this.props.isOpened,
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.isOpened !== this.state.isOpened) {
            this.setState({
                isOpened: newProps.isOpened,
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.closeTimeout);
    }

    onToggle = (event) => {
        if (event && (event?.target.matches('.fa-times-circle') || !event?.target.closest('.title'))) {
            return;
        }

        clearTimeout(this.closeTimeout);

        const isOpened = !this.state.isOpened;

        this.setState({ isOpened }, () => {
            this.props.onToggle(this.props.identifier, isOpened);

            if (this.dateTimePickerInstance) {
                if (!isOpened && this.dateTimePickerInstance.isOpen) {
                    return this.dateTimePickerInstance.close();
                } else if (isOpened) {
                    setTimeout(() => this.dateTimePickerInstance.open(), 200);
                }
            }
        });
    };

    /**
     * On mouse leave.
     *
     * @param {Event|int} timeout
     */
    onMouseLeave = (timeout) => {
        timeout = _.isInteger(timeout) ? timeout : 500;

        clearTimeout(this.closeTimeout);

        this.closeTimeout = setTimeout(() => this.state.isOpened && this.onToggle(), timeout);
    };

    /**
     * On mouse enter.
     */
    onMouseEnter = () => {
        clearTimeout(this.closeTimeout);
    };

    /**
     * Get the formatted date depending on the given picker type.
     *
     * @param {string} date
     *
     * @return {string}
     */
    formatDate = (date) => {
        if (!_.isString(date) || date.length === 0) {
            return '';
        }

        switch (this.props.pickerType) {
            case 'date':
                return i18n(date).date();

            case 'datetime':
                return i18n(date).dateTime();

            case 'time':
                return date.substr(0, 5);
        }
    };

    /**
     * Render the picker depending on its type.
     *
     * @param {*}        pickerValue
     * @param {function} onChange
     *
     * @returns {object}
     */
    renderPicker = (pickerValue, onChange) => {
        const props = {
            className: 'ui-form-element-date',
            value: pickerValue,
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave,
            onClose: this.onMouseLeave.bind(null, 1500),
            onChange: onChange,
            options: this.props.options,
            getDateTimePickerInstance: (dateTimePickerInstance) =>
                (this.dateTimePickerInstance = dateTimePickerInstance),
        };

        switch (this.props.pickerType) {
            case 'date':
                return <DatePicker {...props} />;

            case 'datetime':
                return <DateTimePicker {...props} />;

            case 'time':
                return <TimePicker {...props} />;
        }
    };

    render() {
        if (this.props.isHidden) {
            return null;
        }

        const isActive = this.props.value.length > 0;
        const displayedValue = this.props.displayedValueFormatter(this.formatDate(this.props.value));
        const emptyValueIcon = this.props.isLoading ? (
            <i className="fa fa-spinner fa-spin" />
        ) : (
            <i className={'fa fa-sort-' + (this.state.isOpened ? 'asc' : 'desc')} />
        );

        return (
            <div
                key={this.props.identifier}
                className={[
                    'ui-filter',
                    'date-time',
                    isActive ? 'active' : '',
                    this.props.isLoading ? 'loading' : '',
                    this.state.isOpened ? 'opened' : '',
                ].join(' ')}
                onClick={this.onToggle}
                onMouseOver={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                <div className="title" style={{ zIndex: this.props.zIndex + 1 }}>
                    {ts.apply(null, _.isArray(this.props.name) ? this.props.name : [this.props.name])}
                    {isActive ? (
                        <div className="value-label" title={displayedValue}>
                            <span>{displayedValue}</span>

                            {this.props.isLoading ? (
                                <i className="fa fa-spinner fa-spin" />
                            ) : this.props.value !== this.props.defaultValue ? (
                                <i
                                    title={ts('Reset filter')}
                                    className="fa fa-times-circle"
                                    onClick={this.props.onChange.bind(
                                        null,
                                        this.props.identifier,
                                        this.props.defaultValue
                                    )}
                                />
                            ) : (
                                emptyValueIcon
                            )}
                        </div>
                    ) : (
                        emptyValueIcon
                    )}
                </div>

                <div className="toggling-list" style={{ zIndex: this.props.zIndex }}>
                    <div className="ui-form-horizontal">
                        <div className="ui-form-group">
                            <label className="ui-form-label" title={ts('Pick value')}>
                                {ts('Pick value')}
                            </label>
                            {this.renderPicker(this.props.value, (value) =>
                                this.props.onChange(this.props.identifier, value)
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export { DateTime };
