import PropTypes from 'prop-types';
import React from 'react';
import { DivergedIcon, InheritedIcon } from 'Shared/resources/assets/app/js/ui/forms/icons';

const Checkbox = ({
    id,
    name,
    value,
    label,
    title,
    className,
    onChange,
    width = 'large',
    defaultClasses = '',
    classPrefix = 'ui-form-element-',
    disabled = false,
    diverged = false,
    inherited = false,
}) => {
    const type = 'checkbox';

    const buildClassName = () => {
        return (
            defaultClasses +
            (type ? ` ${classPrefix}${type}` : '') +
            (width ? ` ${classPrefix}width-${width}` : '') +
            (className ? ` ${className}` : '')
        );
    };

    return (
        <div id={id ? `${id}-wrapper` : undefined} className={buildClassName()} title={title}>
            <label>
                <input
                    type={type}
                    id={id}
                    name={name}
                    title={title}
                    value={value}
                    onChange={onChange}
                    checked={value}
                    disabled={disabled}
                />
                {diverged ? <DivergedIcon /> : null}
                {inherited ? <InheritedIcon /> : null}
                {label}
            </label>
            {disabled ? <input type="hidden" name={name} value={value} /> : null}
        </div>
    );
};

Checkbox.propTypes = {
    name: PropTypes.string,
    value: PropTypes.bool,
    label: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    width: PropTypes.string,
    defaultClasses: PropTypes.string,
    classPrefix: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    diverged: PropTypes.bool,
    inherited: PropTypes.bool,
};

export { Checkbox };
