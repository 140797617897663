import $ from 'jquery';
import React from 'react';
import { createRoot } from 'react-dom/client';
import _ from 'lodash';
import { App } from 'Shared/resources/assets/app/js/App';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { onDocumentReady } from 'Shared/resources/assets/app/js/helpers/domHelpers';
import { isAuthenticationApp, isRecruitingApp } from 'Shared/resources/assets/app/js/helpers/appHelpers';
import { Validator } from 'Shared/resources/assets/app/js/ui/libs/Validator';
import { Popup } from 'Shared/resources/assets/app/js/ui/libs/Popup';
import { FileUploadHistory } from 'Shared/resources/assets/app/js/ui/AntDesign/FileUploadHistory';
import { GlobalConfigProvider } from 'Core/resources/assets/js/providers/GlobalConfigProvider';

const FormBuilder = {
    /**
     * A boolean to know whether a page unload event is triggered by a form submit.
     *
     * Is set to false in the form submit event.
     */
    warnBeforeUnload: true,

    /**
     * Prevents leave page warnings for form submits.
     *
     * A page has an onBeforeUnload event that is always triggered. Some form fields and form submits should not
     * trigger this functionality.
     */
    preventLeavePageWarningForFormSubmits: function () {
        $('form')
            .not('.ui-ignore-changes')
            .on('submit', (e) => {
                this.warnBeforeUnload = false;
            });
    },

    /**
     * Detect if forms have unsaved data.
     *
     * @returns {boolean}
     */
    formsHaveUnsavedData: function () {
        let hasUnsavedChanges = false;

        const $forms = $.merge($('form').not('.ui-ignore-changes'), $('.ui-do-not-ignore-changes'));

        // Detect form changes
        $forms
            .find(':input')
            .not('button')
            .not('input[type=submit]')
            .not('.ui-ignore-changes')
            .each((index, element) => {
                if ($(element).is(':checkbox') || $(element).is(':radio')) {
                    hasUnsavedChanges = hasUnsavedChanges || element.checked !== element.defaultChecked;
                } else if ($(element).is('select')) {
                    if (
                        $(element).parent().hasClass('ui-form-element-select') ||
                        $(element).parent().parent().hasClass('ui-form-level-select-option checked')
                    ) {
                        $(element)
                            .find('option')
                            .each((index, option) => {
                                const isPleaseChooseOption = option.value.length === 0;
                                if (!isPleaseChooseOption) {
                                    hasUnsavedChanges = hasUnsavedChanges || option.selected !== option.defaultSelected;
                                }
                            });
                    }
                } else {
                    hasUnsavedChanges = hasUnsavedChanges || element.value !== element.defaultValue;
                }
            });

        return this.warnBeforeUnload && hasUnsavedChanges;
    },

    /**
     * Decide if multiple form submit should be disabled.
     *
     * This is based on the FormBuilder.preventLeavePageWarningForFormSubmits() functionality. Should only
     * be called on the onBeforeUnload event of each page (FormBuilder.onBeforeUnload()).
     *
     * @returns {boolean}
     */
    shouldDisableMultipleFormSubmit: function () {
        return this.warnBeforeUnload === false;
    },

    /**
     * General onBeforeUnload event.
     *
     * Handles:
     * 1) Prevent losing form data
     * 2) Prevent the user from submitting a form more than once
     */
    onBeforeUnload: function () {
        if (isAuthenticationApp() || isRecruitingApp()) {
            return;
        }

        const onBeforeUnloadCallback = (e) => {
            if (this.formsHaveUnsavedData()) {
                // Custom text support is removed https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload#Browser_compatibility
                // However e.returnValue and what this callback return must be the same for the browser to show an alert.
                let message = ts('You have unsaved changes! Are you sure you want to leave this page?');
                e.returnValue = message;

                return message;
            }

            if (this.shouldDisableMultipleFormSubmit()) {
                FormBuilder.disableFormSubmitButton();
            }
        };

        // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Parameters
        const options = {
            capture: true,
            useCapture: true,
        };

        window.addEventListener('beforeunload', onBeforeUnloadCallback, options);
    },

    /**
     * Resolves a form to a jQuery instance.
     *
     * @param {jQuery|string|undefined} form
     *
     * @returns {object} jQuery instance of a (or multiple) form(s).
     */
    resolveForm: function (form) {
        if (typeof form === 'string') {
            // Presume we received an id of a form tag
            form = $('#' + form);
        } else if (!(form instanceof $)) {
            // Presume all forms need to have the submit buttons disabled
            form = $('form');
        }

        return form;
    },

    /**
     * Disable the form submit button(s).
     *
     * @param {jQuery|string|undefined} $form A form id or jQuery instance of a (or multiple) form(s).
     */
    disableFormSubmitButton: function ($form) {
        this.resolveForm($form).find(':submit').prop('disabled', 'disabled');
    },

    /**
     * Enable the form submit button(s).
     *
     * @param {jQuery|string|undefined} $form A form id or jQuery instance of a (or multiple) form(s).
     */
    enableFormSubmitButton: function ($form) {
        this.resolveForm($form).find(':submit').removeProp('disabled');
    },

    /**
     * Escapes special characters in a jquery selector.
     *
     * @param {string} selector
     *
     * @returns {string}
     */
    jquerySafeEscape: function (selector) {
        // Escaping square brackets
        selector = selector.replace(/(\[|\])/g, '\\$1');

        return selector;
    },

    /**
     * Observe form submit.
     *
     * @param {string} id Form ID
     */
    observeForm: function (id) {
        const $form = $('#' + id);
        const self = this;

        this.enableFakeInputs($form);

        // trigger form submit on Enter key pressed on any text input
        $form.on('keypress', function (e) {
            if ($(e.target).is(':text') && e.which === 13) {
                // detect submit button
                let $submitButton;
                let $container = $form;

                while ($container.length) {
                    $submitButton = $('input:submit,button:submit', $container);

                    if ($submitButton.length) {
                        $submitButton.trigger('click');
                        break;
                    } else {
                        $container = $container.parent();
                    }
                }

                return false;
            }
        });

        $form.on('submit', function (e) {
            self.disableFakeInputs($form);

            if ($(this).hasClass('prevent-multiple-submit') && $(this).hasClass('submitted')) {
                e.preventDefault();
            }
        });
    },

    /*
     * Observes changes within a file upload and populate validator.
     *
     * @param {string} id ID of container that contains the file input.
     */
    observeFileUpload: function (id) {
        if (!FileReader) {
            return;
        }

        const $fileUpload = $('#' + this.jquerySafeEscape(id));
        const formId = $fileUpload.closest('form').attr('id');

        if ($fileUpload.data('file') !== undefined) {
            Validator.addFiles(formId, { [id]: 'has-some-uploaded-file' });
        }

        $fileUpload.on('change', function () {
            // Some browsers may let the user deselect an already chosen file which could trigger
            // a change event with an empty string as value. Setting an empty string in the validator will end up
            // in failing the validation as the file validation requires an object to be passed.
            if (!_.isObject(this.files[0])) {
                Validator.removeFiles(formId, id);
            } else {
                Validator.addFiles(formId, { [id]: this.files[0] });
            }
        });

        // The delete icon functionality
        $fileUpload
            .closest('.ui-form-group')
            .find('.ui-form-file-delete-icon')
            .on('click', (e) => {
                const $closestFormGroup = $(e.target).closest('.ui-form-group');
                const $fileDeleteCheckbox = $closestFormGroup.find('.ui-form-file-delete-checkbox');
                const $fileDeleteHint = $closestFormGroup.find('.ui-form-file-delete-hint');
                const $fileDeleteIcon = $closestFormGroup.find('.ui-form-file-delete-icon');
                const $fileName = $closestFormGroup.find('.ui-form-file-name');
                const fileUpload = $closestFormGroup.find('input[type=file]').get(0);

                $fileDeleteIcon.toggleClass('fa-trash fa-trash-o');

                if ($fileDeleteCheckbox.is(':checked')) {
                    $fileDeleteCheckbox.prop('checked', false);
                    $fileName.removeClass('ui-line-through');
                    $fileDeleteHint.addClass('hidden');

                    Validator.addFiles(formId, { [id]: 'has-some-uploaded-file' });
                } else {
                    $fileDeleteCheckbox.prop('checked', true);
                    $fileName.addClass('ui-line-through');
                    $fileDeleteHint.removeClass('hidden');

                    if (!_.isObject(fileUpload.files[0])) {
                        Validator.removeFiles(formId, id);
                    }
                }

                Popup.adjustHeight();
            });

        if ($fileUpload.is('[data-history]')) {
            const label = $fileUpload.closest('.ui-form-group').find('label');
            let container = label.find('> span').get(0);

            if (!container) {
                container = document.createElement('span');
                label.append(container);
            }

            const root = createRoot(container);
            root.render(
                <GlobalConfigProvider>
                    <FileUploadHistory
                        attributeId={parseInt($fileUpload.data('attribute-id'))}
                        relationId={parseInt($fileUpload.data('relation-id'))}
                        onDeleteEntityFile={(entityFile) => {
                            if (parseInt($fileUpload.data('file-id')) === entityFile.file_id) {
                                $fileUpload.closest('.ui-form-group').find('.ui-form-hint').get(0).remove();
                            }
                        }}
                    />
                </GlobalConfigProvider>,
            );
        }
    },

    /**
     * Observes changes within a multi select and updates the actual value
     *
     * @param {string} id ID of container that contains the multi select
     */
    observeMultiSelect: function (id) {
        const $container = $('#' + id);

        const $checkboxes = $container.find('input[name^="_ms[' + id + ']"]');
        const $hidden = $container.find('input[id="_ms-value[' + id + ']"]');
        const $filter = $container.find('input[name="' + id + '-filter"]');

        $filter.on('keyup', function () {
            const regex = new RegExp(_.escapeRegExp($filter.val()), 'gi');

            _.map($checkboxes, function ($checkbox) {
                const $checkboxParent = $($checkbox).parent();
                $checkboxParent.parent().toggleClass('hidden', !$checkboxParent.text().match(regex));
            });
        });

        $checkboxes.on('click', this.updateMultiSelect.bind(this, $checkboxes, $hidden));

        // If the hidden already contains data, populate inputs
        // This is likely to happen if for example validation failed
        if ($hidden.val().length !== 0) {
            this.restoreMultiSelect($checkboxes, $hidden);
        }
        // Otherwise, perform a regular update to populate JSON
        // This may happen if the values are provided as array
        else {
            this.updateMultiSelect($checkboxes, $hidden);
        }
    },

    /**
     * Parses the value and updates the multi select
     *
     * @param {jQuery} $checkboxes
     * @param {jQuery} $hidden
     */
    restoreMultiSelect: function ($checkboxes, $hidden) {
        let values = JSON.parse($hidden.val());

        values = _.pickBy(values, (value) => value);

        _.forEach(values, function (value, key) {
            $checkboxes.filter('input[name$="[' + key + ']"]').prop('checked', true);
        });
    },

    /**
     * Parses the multi select and updates the value
     *
     * @param {jQuery} $checkboxes
     * @param {jQuery} $hidden
     */
    updateMultiSelect: function ($checkboxes, $hidden) {
        const value = {};

        $checkboxes.each(function () {
            const $checkbox = $(this);
            value[$checkbox.attr('data-id')] = $checkbox.is(':checked');
        });

        $hidden.val(JSON.stringify(value));
    },

    /**
     * Observes changes within a level select and updates the actual value input
     *
     * @param {string} id ID of container that contains the level select
     */
    observeLevelSelect: function (id) {
        const $container = $('#' + id);

        const $checkboxes = $container.find('input[name^="_ls[' + id + ']"]');
        const $levels = $container.find('select[name^="_ls[' + id + ']"]');
        const $hidden = $container.find('input[id="_ls-value[' + id + ']"]');
        const $filter = $container.find('input[name="' + id + '-filter"]');

        $filter.on('keyup', function () {
            const regex = new RegExp(_.escapeRegExp($filter.val()), 'gi');

            _.map($checkboxes, function ($checkbox) {
                const $checkboxParent = $($checkbox).parent();
                $checkboxParent.parent().toggleClass('hidden', !$checkboxParent.text().match(regex));
            });
        });

        $checkboxes.on('click', (event) => {
            const $checkbox = $(event.target);

            $checkbox.closest('.ui-form-level-select-option').toggleClass('checked', $checkbox.is(':checked'));
            this.updateLevelSelect($checkboxes, $levels, $hidden);
        });

        $levels.on('change', this.updateLevelSelect.bind(this, $checkboxes, $levels, $hidden));

        if ($hidden.val().length !== 0) {
            this.restoreLevelSelect($checkboxes, $levels, $hidden);
        } else {
            this.updateLevelSelect($checkboxes, $levels, $hidden);
        }
    },

    /**
     * Parses the value and updates the level select
     *
     * @param {jQuery} $checkboxes
     * @param {jQuery} $levels
     * @param {jQuery} $hidden
     */
    restoreLevelSelect: function ($checkboxes, $levels, $hidden) {
        let values = JSON.parse($hidden.val());

        values = _.pickBy(values, (value) => value);

        _.forEach(values, function (value, key) {
            $checkboxes.filter('input[name$="[' + key + '][values]"]').prop('checked', true);
            $checkboxes.filter('select[name$="[' + key + '][levels]"]').val(value);
        });
    },

    /**
     * Parses the level select and updates the value
     *
     * @param {jQuery} $checkboxes
     * @param {jQuery} $levels
     * @param {jQuery} $hidden
     */
    updateLevelSelect: function ($checkboxes, $levels, $hidden) {
        const value = {};

        $checkboxes.each(function () {
            const $checkbox = $(this);

            const valueId = $checkbox.attr('data-id');
            const $level = $levels.filter('[data-value-id="' + valueId + '"]');

            value[valueId] = $checkbox.is(':checked') ? $level.val() : false;
        });

        $hidden.val(JSON.stringify(value));
    },

    /**
     * Observes a diverging boolean and updates the hidden input accordingly.
     *
     * @param {string} id ID of container that contains the element.
     */
    observeDivergingBoolean: function (id) {
        const $container = $('#' + id);

        const $checkbox = $container.find('input[type="checkbox"]');
        const $hidden = $container.find('input[name^="_dv[' + id + ']"]');

        $checkbox.on('click', function () {
            $checkbox.val('1');
            $hidden.val('1');
            $container.removeAttr('data-diverging');
        });
    },

    /**
     * Observes a diverging multi- or level-select and updates the hidden input accordingly.
     *
     * @param {string} id ID of container that contains the element.
     */
    observeDivergingMultiLevelSelect: function (id) {
        const $container = $('#' + id);

        $container.find('[data-diverging]').each(function () {
            const $current = $(this);

            const $checkbox = $current.find('input[type="checkbox"]');
            const $hidden = $current.find('input[name^="_dv"]');

            $checkbox.on('click', function () {
                $hidden.val('1');
                $current.removeAttr('data-diverging');
            });
        });
    },

    /**
     * Disables fake inputs.
     *
     * This method must be called before submitting form, it removes fake inputs from POST data.
     * Otherwise this data may break back-end validation and other automated processes.
     *
     * @param {jQuery} $form
     */
    disableFakeInputs: function ($form) {
        $form.find('[name^="_ls["]').attr('disabled', 'disabled');
        $form.find('[name^="_ms["]').attr('disabled', 'disabled');
    },

    /**
     * Enables fake inputs.
     *
     * This method may be called from external libraries if anything happens that prevents the form
     * from being submitted. If this method is not called, the user won't be able anymore to edit
     * form elements using fake inputs.
     *
     * @param {jQuery} $form
     */
    enableFakeInputs: function ($form) {
        $form.find('[name^="_ls["]').prop('disabled', false);
        $form.find('[name^="_ms["]').prop('disabled', false);
    },
};

onDocumentReady(function () {
    // Mounting the onBeforeUnload functionality
    FormBuilder.onBeforeUnload();
    FormBuilder.preventLeavePageWarningForFormSubmits();
});

App.FormBuilder = FormBuilder;

export { FormBuilder };
