import _ from 'lodash';

/**
 * The (hopefully) available engines.
 */
const ENGINE_LOCAL = 'localStorage';
const ENGINE_SESSION = 'sessionStorage';

class Storage {
    /**
     * Storage constructor.
     *
     * @param {string}  namespace A namespace must always be provided in order to prevent collisions.
     * @param {string}  version   Version can optionally be provided for preventing conflicts with outdated code.
     * @param {string}  engine    The desired engine
     */
    constructor(namespace, version = '1', engine = ENGINE_LOCAL) {
        if (!_.includes([ENGINE_LOCAL, ENGINE_SESSION], engine)) {
            throw 'Engine not supported';
        }

        this.namespace = namespace;
        this.version = version;
        this.engine = engine;

        this.setPrefix();
    }

    /**
     * Checks if the desired engine is supported by the browser.
     *
     * @returns {boolean}
     */
    isSupported() {
        try {
            return this.engine in window && window[this.engine] !== null;
        } catch (e) {
            return false;
        }
    }

    /**
     * Adds an item to the storage.
     *
     * Will automatically JSON encode the value.
     *
     * @param {string} key
     * @param {*} value
     */
    setItem(key, value) {
        key = this.getPrefix() + key;

        try {
            value = JSON.stringify(value);
        } catch (err) {
            console.warn(err);
        }

        window[this.engine].setItem(key, value);
    }

    /**
     * Returns an item from the storage.
     *
     * Will automatically JSON decode the value.
     *
     * @param {string} key
     * @param {*}      defaultValue
     *
     * @returns {*|null}
     */
    getItem(key, defaultValue) {
        key = this.getPrefix() + key;

        let item = window[this.engine].getItem(key);

        if (item) {
            try {
                item = JSON.parse(item);
            } catch (err) {
                console.warn(err);
            }
        } else if (defaultValue !== undefined) {
            return defaultValue;
        }

        return item;
    }

    /**
     * Removes an item from the storage.
     *
     * @param {string} key
     */
    removeItem(key) {
        key = this.getPrefix() + key;

        window[this.engine].removeItem(key);
    }

    /**
     * Clears the storage considering current namespace.
     */
    clear() {
        _.forIn(window[this.engine], (value, key) => {
            if (_.startsWith(key, this.getPrefix()) === true) {
                window[this.engine].removeItem(key);
            }
        });
    }

    /**
     * Assembles the prefix.
     *
     * @private
     */
    setPrefix() {
        this.prefix = `${this.namespace}-${this.version}:`;
    }

    /**
     * Returns the prefix.
     *
     * @returns {string}
     *
     * @private
     */
    getPrefix() {
        return this.prefix;
    }
}

export { Storage };
