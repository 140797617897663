import React from 'react';

const FormSection = class FormSection extends React.Component {
    /**
     * Returns default component properties.
     *
     * @returns {object}
     */
    static defaultProps = {
        title: '',
        showTitle: true,
    };

    render() {
        return (
            <div className="ui-form-generator-section">
                <h3
                    className="ui-form-generator-section-title"
                    style={{ display: this.props.showTitle ? 'block' : 'none' }}
                >
                    {this.props.title}
                </h3>
                <div className="ui-form-generator-section-attributes">{this.props.children}</div>
            </div>
        );
    }
};

export { FormSection };
