import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { DivergedIcon, InheritedIcon } from 'Shared/resources/assets/app/js/ui/forms/icons';

const FormGroup = ({
    label,
    title,
    className,
    hint,
    groupKey,
    children,
    locked = false,
    hasErrors = false,
    diverged = false,
    inherited = false,
    required = false,
    defaultClasses = 'ui-form-group',
    classPrefix = 'ui-form-group-',
}) => {
    const buildClassName = () => {
        const classNames = [defaultClasses];

        if (!label) {
            classNames.push(`${classPrefix}no-label`);
        }

        if (hasErrors) {
            classNames.push(`${classPrefix}negative`);
        }

        if (className) {
            classNames.push(className);
        }

        if (locked) {
            classNames.push('ui-form-group-locked');
        }

        return classNames.join(' ');
    };

    return (
        <div className={buildClassName()} key={groupKey}>
            {label ? (
                <label className="ui-form-label" title={title}>
                    {diverged ? <DivergedIcon /> : null}
                    {inherited ? <InheritedIcon /> : null}
                    {label}
                    {required ? ' *' : ''}
                </label>
            ) : (
                <div className="ui-form-fake-label" />
            )}
            {React.Children.map(children, (child) => {
                if (!child) {
                    return child;
                }

                if (!_.isEmpty(label)) {
                    return child;
                }

                return React.cloneElement(child, {
                    ...child.props,
                    diverged: diverged,
                    inherited: inherited,
                });
            })}
            {hint && <span className="ui-form-hint">{hint}</span>}
        </div>
    );
};

FormGroup.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.string,
    locked: PropTypes.bool,
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    hasErrors: PropTypes.bool,
    diverged: PropTypes.bool,
    inherited: PropTypes.bool,
    required: PropTypes.bool,
    defaultClasses: PropTypes.string,
    className: PropTypes.string,
    classPrefix: PropTypes.string,
    groupKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export { FormGroup };
