import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { CheckboxCollection } from 'Shared/resources/assets/app/js/ui/filters';

const CheckboxWithLevelsCollection = class CheckboxWithLevelsCollection extends React.Component {
    static propTypes = {
        collection: PropTypes.arrayOf(PropTypes.array).isRequired, // [[id, value], [id, value]]
        levels: PropTypes.arrayOf(PropTypes.array).isRequired, // [[id, value], [id, value]]
        value: PropTypes.object.isRequired,
        // Also the other props from checkbox-collection component.
    };

    /**
     * Get the default properties.
     *
     * @returns {Object}
     */
    static defaultProps = {
        identifier: 'checkbox-with-levels-collection',
        value: {},
        onChange: () => {},
    };

    /**
     * On change level.
     *
     * @param {*} id
     * @param {*} levelValue
     */
    onChangeLevel = (id, levelValue) => {
        this.props.onChange(this.props.identifier, {
            ...this.props.value,
            [id]: levelValue,
        });
    };

    /**
     * On toggle collection item.
     *
     * @param {string} identifier
     * @param {Array}  value
     */
    onToggleCollectionItem = (identifier, value) => {
        this.props.onChange(
            identifier,
            value.reduce((carry, id) => {
                carry[id] = this.props.value.hasOwnProperty(id) ? this.props.value[id] : this.props.levels[0][0];
                return carry;
            }, {})
        );
    };

    /**
     * Get the checkbox collection props.
     *
     * @return {Object}
     */
    getCheckboxCollectionProps = () => {
        const collectionOrder = [];
        const collection = this.props.collection.reduce((carry, [id, value]) => {
            collectionOrder.push(id);

            const levels = (
                <select
                    key={`${id}-levels`}
                    value={this.props.value[id] || this.props.levels[0][0]}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => this.onChangeLevel(id, parseInt(e.target.value))}
                >
                    {this.props.levels.map(([levelId, levelValue]) => (
                        <option key={`${id}-levels-${levelId}-value`} value={levelId}>
                            {levelValue}
                        </option>
                    ))}
                </select>
            );

            carry[id] = {
                name: value,
                row: (
                    <span className="checkbox-with-levels-displayed-value">
                        <span>{value}</span>
                        <span>{levels}</span>
                    </span>
                ),
            };

            return carry;
        }, {});

        return {
            collection,
            collectionOrder,
            value: _.map(Object.keys(this.props.value), _.toInteger),
        };
    };

    /**
     * Main render method.
     *
     * @returns {Object}
     */
    render() {
        return (
            <CheckboxCollection
                {...this.props}
                {...this.getCheckboxCollectionProps()}
                onChange={this.onToggleCollectionItem}
            />
        );
    }
};

export { CheckboxWithLevelsCollection };
