import PropTypes from 'prop-types';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from 'Shared/resources/assets/app/js/App';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { GlobalConfigProvider } from 'Core/resources/assets/js/providers/GlobalConfigProvider';
import { LightTooltip } from 'Shared/resources/assets/app/js/ui/AntDesign/LightTooltip';

const ExpandableInlineList = class ExpandableInlineList extends React.PureComponent {
    /**
     * Props types.
     *
     * @type {object}
     */
    static propTypes = {
        list: PropTypes.array,
        icon: PropTypes.string,
        displayCount: PropTypes.number,
        expandable: PropTypes.bool,
    };

    /**
     * The default properties.
     *
     * @type {object}
     */
    static defaultProps = {
        list: [],
        icon: null,
        displayCount: 1,
        expandable: true,
    };

    /**
     * Component's constructor.
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            displayCount: props.displayCount,
        };
    }

    /**
     * Show all the entries in the list.
     */
    showAllEntries = () => {
        if (!this.props.expandable) {
            return;
        }

        this.setState({
            displayCount: null,
        });
    };

    /**
     * Render the show more action.
     *
     * @return {Object}
     */
    renderShowMoreAction(entries) {
        if (entries.length >= this.props.list.length) {
            return null;
        }

        return (
            <LightTooltip
                title={
                    <div>
                        {this.props.list.slice(this.state.displayCount).map((item, index) => (
                            <div key={index}>{item}</div>
                        ))}
                    </div>
                }
            >
                <a className="ui-link" onClick={this.showAllEntries}>
                    {ts(`+%1 more`, [this.props.list.length - entries.length])}
                </a>
            </LightTooltip>
        );
    }

    render() {
        const entries =
            this.state.displayCount === null ? this.props.list : this.props.list.slice(0, this.state.displayCount);

        return (
            <div className="ui-expandable-inline-list">
                {this.props.icon !== null && <i className={`fa fa-fw fa-${this.props.icon} ui-margin-right-5`} />}
                <span>
                    {entries.map((entry, index) => (
                        <span key={index}>{entry}</span>
                    ))}
                </span>
                {this.renderShowMoreAction(entries)}
            </div>
        );
    }
};

App.renderExpandableInlineList = (elementId, props) => {
    const container = document.getElementById(elementId);
    const root = createRoot(container);
    root.render(
        <GlobalConfigProvider>
            <ExpandableInlineList {...props} />
        </GlobalConfigProvider>,
    );
};

export { ExpandableInlineList };
