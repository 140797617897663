import { App } from 'Shared/resources/assets/app/js/App';
import { onDocumentReady } from 'Shared/resources/assets/app/js/helpers/domHelpers';
import { getApp } from 'Shared/resources/assets/app/js/helpers/appHelpers';
import { config } from 'Shared/resources/assets/app/js/helpers/configHelpers';
import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';
import { Pluralizer } from 'Shared/resources/assets/app/js/utils/i18n/Pluralizer';
import { Ajax } from 'Shared/resources/assets/app/js/utils/Ajax';
import { laroute } from 'Shared/resources/assets/app/js/laroute';

const topWindowDocument = getTopWindowDocument();

/**
 * This is a rough JS port of the StaffCloud\Shared\Library\Translation\Translator class.
 */
const Translator = {
    TRANSLATED_IDENTIFIER: '',
    PLACEHOLDER: '%',

    addTranslations: function (translations) {
        topWindowDocument.translations = {
            ...(topWindowDocument.translations || {}),
            ...translations,
        };
    },

    translate: function (string, params) {
        string = this.translateString(string);

        return this.replaceParams(string, params);
    },

    translatePlural: function (string, quantity, params) {
        string = this.translateString(string);
        string = Pluralizer.init(string, quantity).extract();

        return this.replaceParams(string, params);
    },

    translateString: function (string) {
        if (typeof string !== 'string') {
            return string;
        }

        if ((topWindowDocument.translations || {}).hasOwnProperty(string)) {
            string = topWindowDocument.translations[string];
        } else {
            topWindowDocument.missingTranslations = (topWindowDocument.missingTranslations || []).concat([string]);

            this.addTranslations({ [string]: string });
        }

        return string;
    },

    replaceParams: function (string, params) {
        if (typeof params === 'undefined') {
            return string;
        }

        let output = string;

        for (let x = 0, last = params.length; x < last; x++) {
            output = output.replace(this.PLACEHOLDER + (x + 1), params[x]);
        }

        return output;
    },

    addMissingTranslations: function () {
        const application = getApp();
        const module = config('app.module');

        if (application.length === 0) {
            return;
        }

        const missingTranslations = (topWindowDocument.missingTranslations || []).filter(
            (key) => key !== null && key.length !== 0
        );

        // Don't send invalid translations
        if (missingTranslations.length === 0) {
            return;
        }

        Ajax.post(laroute.route('jsTranslation'), { missingTranslations, application, module });

        topWindowDocument.missingTranslations = [];
    },
};

onDocumentReady(() => {
    setInterval(Translator.addMissingTranslations, 5000);
});

App.Translator = Translator;

export { Translator };
