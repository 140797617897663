import React from 'react';
import _ from 'lodash';
import { Selector } from 'Shared/resources/assets/app/js/ui/forms/widgets';

/**
 * This is just a Selector widget adapted to handle values similar to MultiSelect.
 * So this component can be used interchangeably as a drop-in replacement for a MultiSelect component.
 */
const FormBuilderSelect = class FormBuilderSelect extends React.Component {
    /**
     * On change handler.
     *
     * @param {*} values
     */
    onChangeHandler = (values) => {
        // For multi select we need to format the values to the format accepted by the form generator MultiSelect
        // format [1,3] transforms to {1:true, 2:false, 3:true}
        const formattedValues = this.props.singleSelect
            ? values
            : _.defaultsDeep(
                  _.zipObject(values, _.fill(Array(values.length), true)),
                  _.mapValues(_.fromPairs(this.props.options), () => false)
              );

        // We actually return a custom event in order to stay compatible with MultiSelect
        this.props.onChange({
            detail: {
                target: {
                    type: this.props.singleSelect ? 'single-selector' : 'multi-selector',
                    name: this.props.name,
                    value: formattedValues,
                },
            },
            bubbles: true,
            cancelable: true,
            target: {
                type: this.props.singleSelect ? 'single-selector' : 'multi-selector',
                name: this.props.name,
                value: formattedValues,
            },
        });
    };

    render() {
        let value = this.props.singleSelect
            ? this.props.value
            : _.keys(_.pickBy(this.props.value)).map((i) => parseInt(i));

        return (
            <Selector
                {...this.props}
                // We need to transform the values from the MultiSelect format
                // to the format expected by Selector
                // {1:true, 2:false, 3:true} will be transformed to [1,3]
                value={value}
                onChange={this.onChangeHandler}
            />
        );
    }
};

export { FormBuilderSelect };
