const getCsrfToken = () => {
    const csrfTokenElement = document.getElementsByName('csrf-token')[0];

    return csrfTokenElement ? csrfTokenElement.attributes.content.value : '';
};

const setCsrfToken = (csrfToken) => {
    if (csrfToken) {
        const csrfTokenElement = document.getElementsByName('csrf-token')[0];

        if (csrfTokenElement) {
            csrfTokenElement.attributes.content.value = csrfToken;
        }
    }
};

export { setCsrfToken, getCsrfToken };
