import { useState } from 'react';
import {
    getDefaultPaginationPageSize,
    getDefaultPaginationProps,
} from 'Shared/resources/assets/app/js/helpers/tableHelper';
import { Storage } from 'Shared/resources/assets/app/js/utils/storage';

export const useTablePagination = (collection, storageName) => {
    const localStorage = new Storage(storageName, '1');
    const [paginationData, setPaginationData] = useState(
        localStorage.getItem('pagination', { page: 1, pageSize: getDefaultPaginationPageSize() }),
    );

    const pagination = getDefaultPaginationProps(collection?.length || 0);

    if (pagination !== false) {
        pagination.current = paginationData.page;
        pagination.pageSize = paginationData.pageSize;
        pagination.onChange = (page, pageSize) => {
            const paginationObject = { page, pageSize };

            localStorage.setItem('pagination', paginationObject);
            setPaginationData(paginationObject);
        };
    }

    return pagination;
};
