import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { EventHandler } from 'Shared/resources/assets/app/js/utils/EventHandler';

class CheckboxCollection extends React.Component {
    static propTypes = {
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // could be missing if customToggler is set.
        collection: PropTypes.objectOf(
            PropTypes.shape({
                name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                row: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            })
        ).isRequired,
        collectionOrder: PropTypes.array,
        collectionKeysAreNumeric: PropTypes.bool,
        identifier: PropTypes.string,
        customToggler: PropTypes.object,
        placeholder: PropTypes.string,
        value: PropTypes.array,
        enableSelectAll: PropTypes.bool,
        enableResetFilter: PropTypes.bool,
        selectedValuesDisplay: PropTypes.number,
        selectedValuesSeparator: PropTypes.string,
        isLoading: PropTypes.bool,
        isOpened: PropTypes.bool,
        isHidden: PropTypes.bool,
        alwaysDisplayCollectionFilter: PropTypes.bool,
        zIndex: PropTypes.number,
        tabIndex: PropTypes.number,
        icon: PropTypes.string,
        togglingListBottomLinks: PropTypes.array,
        closeTogglingListOnBottomLinkClick: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onToggle: PropTypes.func,
        displayAsBadges: PropTypes.bool,
    };

    static defaultProps = {
        identifier: 'checkbox-collection',
        placeholder: '',
        value: [],
        enableSelectAll: true,
        enableResetFilter: true,
        selectedValuesDisplay: 1,
        selectedValuesSeparator: ', ',
        collectionKeysAreNumeric: true,
        isLoading: false,
        isOpened: false,
        isHidden: false,
        alwaysDisplayCollectionFilter: false,
        zIndex: 1,
        tabIndex: 0,
        icon: 'square',
        togglingListBottomLinks: [],
        closeTogglingListOnBottomLinkClick: true,
        className: '',
        onToggle: () => {},
        displayAsBadges: false,
    };

    filterInputRef = null;

    componentDidMount() {
        this.keyDownEventHandler = EventHandler.keyDown()
            .onArrowDown(this.onArrowDownPressed)
            .onArrowUp(this.onArrowUpPressed)
            .onSpace(this.onSpacePressed)
            .onEnter(this.onEnterPressed);
    }

    componentWillUnmount() {
        clearTimeout(this.closeTimeout);

        this.keyDownEventHandler.unregister();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const state = {};

        if (newProps.isOpened !== this.state.isOpened) {
            state.isOpened = newProps.isOpened;
        }

        if (!_.isEqual(this.props.collection, newProps.collection)) {
            state.filteredCollectionIds = this.getFilteredCollectionIds(this.state.collectionFilter, newProps);
            state.listActiveIndex = -1;

            this.activeRowRef = undefined;
        }

        if (!_.isEmpty(state)) {
            this.setState(state);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.isOpened) {
            return;
        }

        if (this.togglingListRef) {
            const rightDifference = Math.ceil(this.togglingListRef.getBoundingClientRect().right - window.innerWidth);

            if (rightDifference > 0) {
                this.togglingListRef.classList.add('stick-to-right');
            }
        }

        if (
            prevState.isOpened !== this.state.isOpened &&
            this.filterInputRef instanceof HTMLInputElement &&
            this.state.listActiveIndex === -1
        ) {
            this.filterInputRef.focus({ preventScroll: true });
        }

        this.scrollTogglingList();
    }

    /**
     * On filter collection action.
     *
     * @param {Event} event
     */
    onFilterCollection = (event) => {
        const string = event.target.value;

        this.setState(() => ({
            collectionFilter: string,
            filteredCollectionIds: this.getFilteredCollectionIds(string),
            listActiveIndex: -1,
        }));

        this.activeRowRef = undefined;
    };

    /**
     * On toggle action.
     *
     * @param {boolean|undefined} isOpened
     * @param {Event|undefined}   event
     */
    onToggle = (isOpened, event) => {
        if (event && (event?.target.matches('.fa-times-circle') || !event?.target.closest('.title'))) {
            return;
        }

        clearTimeout(this.closeTimeout);

        if (_.isBoolean(isOpened) && this.state.isOpened === isOpened) {
            return;
        }

        isOpened = _.isBoolean(isOpened) ? isOpened : !this.state.isOpened;

        this.setState(
            (state) => ({
                isOpened,
                listActiveIndex: !isOpened ? -1 : state.listActiveIndex,
            }),
            () => {
                this.props.onToggle(this.props.identifier, isOpened);

                if (this.state.listActiveIndex === -1) {
                    this.activeRowRef = undefined;
                }
            }
        );
    };

    /**
     * On mouse leave.
     */
    onMouseLeave = () => {
        clearTimeout(this.closeTimeout);

        this.closeTimeout = setTimeout(() => this.state.isOpened && this.onToggle(), 500);
    };

    /**
     * On mouse enter.
     */
    onMouseEnter = () => {
        clearTimeout(this.closeTimeout);
    };

    /**
     * Handle the filter change.
     *
     * @param {array}   values
     * @param {boolean} remove
     */
    onChange = (values, remove) => {
        values = values.filter((id) => this.props.collection[id].isDisabled !== true).map(this.toInteger);

        if (remove) {
            values = _.without(this.props.value, ...values);
        } else {
            values = _.uniq(_.concat(this.props.value, values));
        }

        this.props.onChange(this.props.identifier, values.map(this.toInteger));
    };

    /**
     * On arrow down pressed.
     */
    onArrowDownPressed = () => {
        if (document.activeElement === this.filterContainerRef && !this.state.isOpened) {
            this.setState(() => ({
                listActiveIndex: this.state.listActiveIndex === -1 ? 0 : this.state.listActiveIndex,
            }));

            return this.onToggle(true);
        }

        if (!this.state.isOpened) {
            return;
        }

        this.filterContainerRef.focus();

        const index = this.state.listActiveIndex + 1;
        const listActiveIndex = this.state.filteredCollectionIds[index] === undefined ? 0 : index;

        this.setState(() => ({ listActiveIndex }));
        this.onToggle(true);
    };

    /**
     * On arrow up pressed.
     *
     * @param {Event} e
     */
    onArrowUpPressed = (e) => {
        e.preventDefault();

        if (document.activeElement === this.filterContainerRef && !this.state.isOpened) {
            this.setState(() => ({
                listActiveIndex:
                    this.state.listActiveIndex === -1 ? this.state.listActiveIndex - 1 : this.state.listActiveIndex,
            }));

            return this.onToggle(true);
        }

        if (!this.state.isOpened) {
            return;
        }

        this.filterContainerRef.focus();

        let listActiveIndex;

        if (this.state.listActiveIndex === -1) {
            listActiveIndex = this.state.filteredCollectionIds.length - 1;
        } else {
            const index = this.state.listActiveIndex - 1;
            listActiveIndex =
                this.state.filteredCollectionIds[index] === undefined
                    ? this.state.filteredCollectionIds.length - 1
                    : index;
        }

        this.setState(() => ({ listActiveIndex }));
        this.onToggle(true);
    };

    /**
     * On space pressed.
     */
    onSpacePressed = () => {
        if (!this.state.isOpened || this.state.listActiveIndex === -1) {
            return;
        }

        this.filterContainerRef.focus();

        const id = this.state.filteredCollectionIds[this.state.listActiveIndex];
        const isSelected = _.includes(this.props.value, id);

        this.onChange([id], isSelected);
    };

    /**
     * On enter pressed.
     */
    onEnterPressed = () => {
        if (!this.state.isOpened) {
            return;
        }

        const selectedIds = _.take(this.state.filteredCollectionIds, 1) || [];

        this.props.onChange(this.props.identifier, selectedIds.map(this.toInteger));
    };

    toInteger = (id) => (this.props.collectionKeysAreNumeric ? parseInt(id) : id);

    /**
     * Scroll the toggling list to the active index.
     */
    scrollTogglingList = () => {
        if (!this.activeRowRef) {
            return;
        }

        const togglingListRect = this.togglingListRef.getBoundingClientRect();
        const togglingListClientHeight = this.togglingListRef.clientHeight;
        const activeRowRect = this.activeRowRef.getBoundingClientRect();
        const isViewable =
            activeRowRect.top >= togglingListRect.top &&
            activeRowRect.top <= togglingListRect.top + togglingListClientHeight;

        if (!isViewable) {
            this.togglingListRef.scrollTop = activeRowRect.top + this.togglingListRef.scrollTop - togglingListRect.top;
        }
    };

    /**
     * Get the filter label.
     *
     * @returns {string}
     */
    getLabel = () => {
        // append code condition here badges
        if (this.props.value.length <= this.props.selectedValuesDisplay && this.props.displayAsBadges) {
            const missingValueName = this.props.isLoading ? ts('Loading...') : ts('Unknown item label');
            return _.map(this.props.value, (value, index) => {
                let name = _.get(this.props.collection, [value, 'name'], missingValueName);

                return (
                    <span className="checkbox-badges-display" key={`checkbox-badge-${index}`}>
                        {name}
                        <i className="fa fa-times-circle" onClick={this.onChange.bind(null, [value], true)}></i>
                    </span>
                );
            });
        }

        if (this.props.value.length <= this.props.selectedValuesDisplay) {
            const missingValueName = this.props.isLoading ? ts('Loading...') : ts('Unknown item label');
            return _.map(this.props.value, (value) =>
                _.get(this.props.collection, [value, 'name'], missingValueName)
            ).join(this.props.selectedValuesSeparator);
        }

        if (this.props.value.length === Object.keys(this.props.collection).length) {
            return this.props.displayAsBadges ? <span>{ts('All selected')}</span> : ts('All selected');
        }

        return this.props.displayAsBadges ? (
            <span>{ts('%1 selected', [this.props.value.length])}</span>
        ) : (
            ts('%1 selected', [this.props.value.length])
        );
    };

    /**
     * Get the IDs of the provided collection.
     *
     * @param {object} props
     *
     * @returns {Array|*}
     */
    getCollectionIds = (props = this.props) => _.get(props, 'collectionOrder', Object.keys(props.collection), []);

    /**
     * Get filtered collections ids.
     *
     * @param {string} collectionFilter
     * @param {Object} props
     *
     * @return {array}
     */
    getFilteredCollectionIds = (collectionFilter = this.state.collectionFilter, props = this.props) => {
        const collectionIds = this.getCollectionIds(props);

        if (_.isEmpty(collectionFilter)) {
            return collectionIds;
        }

        const filter = new RegExp(_.escapeRegExp(collectionFilter), 'gi');

        return _.filter(collectionIds, (itemId) => {
            const name = _.get(props, ['collection', itemId, 'name'], '') || '';

            return name.toString().match(filter);
        });
    };

    /**
     * Render the collection filter.
     *
     * @returns {array}
     */
    renderCollectionFilter = () => {
        const headerParts = [
            <div key="header-text-filter">
                <input
                    ref={(ref) => (this.filterInputRef = ref)}
                    type="text"
                    value={this.state.collectionFilter}
                    placeholder={ts('Filter...')}
                    className="ui-form-element-text"
                    onChange={this.onFilterCollection}
                />
            </div>,
        ];

        let selectAll = <div key="header-select-all" />;

        if (this.props.enableSelectAll) {
            const enabledFilteredCollectionIds = this.state.filteredCollectionIds.filter(
                (id) => _.get(this.props.collection, [id, 'isDisabled'], false) === false
            );
            const intersectionIteratee = this.props.collectionKeysAreNumeric ? parseInt : (value) => value;
            const isChecked =
                _.intersectionBy(enabledFilteredCollectionIds, this.props.value, intersectionIteratee).length ===
                enabledFilteredCollectionIds.length;

            selectAll = (
                <div key="header-select-all">
                    <i
                        className={'fa fa-' + (isChecked ? 'check-' : '') + 'square-o'}
                        onClick={this.onChange.bind(null, enabledFilteredCollectionIds, isChecked)}
                    />
                </div>
            );
        }

        headerParts.unshift(selectAll);

        const collectionFilter = [
            <div className="row header" key="filter-header">
                {headerParts}
            </div>,
        ];

        if (this.state.collectionFilter.length > 0 && !this.state.filteredCollectionIds.length) {
            collectionFilter.push(
                <div className="row" key="no-filter-results">
                    <div />
                    <div className="ui-alert-warning">{ts('Your search returned no results.')}</div>
                </div>
            );
        }

        return collectionFilter;
    };

    state = {
        isOpened: this.props.isOpened,
        filteredCollectionIds: this.getCollectionIds(),
        collectionFilter: '',
        listActiveIndex: -1,
    };

    render() {
        if (this.props.isHidden) {
            return null;
        }

        const collectionIds = this.getCollectionIds();
        const valueLabel = this.getLabel();
        const emptyValueIcon = this.props.isLoading ? (
            <i className="fa fa-spinner fa-spin" />
        ) : (
            <i className={'fa fa-sort-' + (this.state.isOpened ? 'asc' : 'desc')} />
        );

        return (
            <div
                key={this.props.identifier}
                className={[
                    'ui-filter',
                    'collection',
                    this.props.isLoading ? 'loading' : '',
                    this.props.value.length !== 0 ? 'active' : '',
                    this.state.isOpened ? 'opened' : '',
                    !this.props.enableResetFilter ? 'disabled-reset-filter' : '',
                    this.props.className,
                ].join(' ')}
                onClick={this.onToggle.bind(null, undefined)}
                onMouseEnter={this.onMouseEnter}
                onFocus={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                ref={(ref) => (this.filterContainerRef = ref)}
                tabIndex={this.props.tabIndex}
            >
                {!this.props.customToggler ? (
                    <div className="title" style={{ zIndex: this.props.zIndex + 1 }}>
                        {this.props.name !== undefined && this.props.name !== null
                            ? ts.apply(null, _.isArray(this.props.name) ? this.props.name : [this.props.name])
                            : null}
                        {this.props.value.length !== 0 ? (
                            <div className="value-label" title={valueLabel}>
                                {this.props.displayAsBadges ? <div> {valueLabel} </div> : <span>{valueLabel}</span>}

                                {this.props.isLoading ? (
                                    <i className="fa fa-spinner fa-spin" />
                                ) : this.props.enableResetFilter ? (
                                    <i
                                        title={ts('Reset filter')}
                                        className="fa fa-times-circle"
                                        onClick={this.onChange.bind(
                                            null,
                                            this.props.value.filter(
                                                (id) =>
                                                    _.get(this.props.collection, [id, 'isDisabled'], false) === false
                                            ),
                                            true
                                        )}
                                    />
                                ) : (
                                    emptyValueIcon
                                )}
                            </div>
                        ) : this.props.placeholder.length > 0 ? (
                            <div className="value-label placeholder">
                                <span>{ts(this.props.placeholder)}</span>
                                {emptyValueIcon}
                            </div>
                        ) : (
                            emptyValueIcon
                        )}
                    </div>
                ) : (
                    <div className="title" style={{ zIndex: this.props.zIndex + 1 }}>
                        {this.props.customToggler}
                    </div>
                )}
                {this.state.isOpened && (
                    <div
                        className="toggling-list"
                        style={{ zIndex: this.props.zIndex }}
                        ref={(ref) => (this.togglingListRef = ref)}
                    >
                        {collectionIds.length === 0 && <div>{ts('No items to display.')}</div>}

                        {collectionIds.length !== 0 && (
                            <div className="table">
                                {(this.props.alwaysDisplayCollectionFilter || collectionIds.length > 5) &&
                                    this.renderCollectionFilter()}

                                {this.state.filteredCollectionIds.map((id, index) => {
                                    if (this.props.collectionKeysAreNumeric) {
                                        id = parseInt(id);
                                    }

                                    const item = this.props.collection[id];
                                    const itemIsSelected = _.includes(this.props.value, id);
                                    const itemIsDisabled = item.isDisabled || false;
                                    const itemIsActive = index === this.state.listActiveIndex;

                                    let itemRow = item.row || ts('Unknown item label');

                                    if (this.state.collectionFilter.length > 0 && typeof itemRow === 'string') {
                                        const filter = new RegExp(
                                            '(' + _.escapeRegExp(this.state.collectionFilter) + ')',
                                            'gi'
                                        );
                                        itemRow = itemRow.replace(
                                            filter,
                                            '<span class="ui-highlight-background">$1</span>'
                                        );
                                    }

                                    return (
                                        <div
                                            key={id}
                                            ref={(ref) => {
                                                if (itemIsActive) {
                                                    this.activeRowRef = ref;
                                                }
                                            }}
                                            className={[
                                                'row',
                                                itemIsSelected ? 'active' : '',
                                                itemIsDisabled ? 'disabled' : '',
                                                itemIsActive ? 'hover' : '',
                                            ].join(' ')}
                                            onClick={!itemIsDisabled && this.onChange.bind(null, [id], itemIsSelected)}
                                        >
                                            <div>
                                                <i
                                                    className={
                                                        'fa fa-' +
                                                        (itemIsSelected ? 'check-' : '') +
                                                        this.props.icon +
                                                        '-o'
                                                    }
                                                />
                                            </div>

                                            {typeof itemRow === 'string' ? (
                                                <div dangerouslySetInnerHTML={{ __html: itemRow }} />
                                            ) : (
                                                <div>{itemRow}</div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                        {this.props.togglingListBottomLinks.length > 0 && (
                            <div className="bottom-links">
                                {this.props.togglingListBottomLinks.map((link, index) => (
                                    <div key={'bottom-link-' + index}>
                                        {React.cloneElement(link, {
                                            ...link.props,
                                            onClick: () => {
                                                const originalOnClick = link.props.onClick || (() => {});

                                                if (this.props.closeTogglingListOnBottomLinkClick) {
                                                    this.onToggle(false);
                                                }

                                                originalOnClick();
                                            },
                                        })}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export { CheckboxCollection };
