import React from 'react';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AntDesignConfigProvider } from 'Core/resources/assets/js/providers/AntDesignConfigProvider';

const queryClient = new QueryClient();

const GlobalConfigProvider = ({ children }) => (
    <QueryClientProvider client={queryClient}>
        <AntDesignConfigProvider>{children}</AntDesignConfigProvider>
    </QueryClientProvider>
);

GlobalConfigProvider.propTypes = {
    children: PropTypes.object.isRequired,
};

export { GlobalConfigProvider };
