import _ from 'lodash';

/**
 * Abstract event handler.
 *
 * @type {AbstractEventHandler}
 */
const AbstractEventHandler = class AbstractEventHandler {
    /**
     * @type {boolean}
     * @private
     */
    _isRegistered;

    /**
     * @type {object}
     * @private
     */
    _callbacks;

    /**
     * @type {int}
     * @private
     */
    _executeLastCallbacks;

    /**
     * Get the event listener type.
     *
     * @returns {string}
     *
     * @abstract
     */
    getEventListenerType() {
        return '';
    }

    /**
     * Handle the event.
     *
     * @param {Event} event
     *
     * @abstract
     * @protected
     */
    _handleEvent(event) {}

    /**
     * Register the handler.
     *
     * @returns {AbstractEventHandler}
     */
    register() {
        this._callbacks = {};
        this._isRegistered = true;

        const eventListenerType = this.getEventListenerType();

        if (_.isEmpty(eventListenerType)) {
            throw 'The eventListenerType cannot be empty. Maybe you forgot to implement the getEventListenerType method?';
        }

        document.addEventListener(eventListenerType, this._handleEvent.bind(this), false);

        return this;
    }

    /**
     * Unregister the handler.
     */
    unregister() {
        this._callbacks = {};
        this._isRegistered = false;

        document.removeEventListener(this.getEventListenerType(), this._handleEvent.bind(this), false);
    }

    /**
     * Unregister the last {count} registered callbacks for all event types.
     *
     * @param {int} count
     */
    unregisterLastCallbacks(count = 1) {
        for (const eventType in this._callbacks) {
            for (let i = 0; i < count; i++) {
                this._callbacks[eventType].pop();
            }
        }
    }

    /**
     * Set the execute last callbacks parameters. When set, only the last {count} registered callbacks will be executed
     * for all event types.
     *
     * @param {int} count
     */
    executeLastCallbacks(count = 1) {
        this._executeLastCallbacks = count;

        return this;
    }

    /**
     * Check if the handler was registered.
     *
     * @returns {boolean}
     */
    isRegistered() {
        return _.isBoolean(this._isRegistered) && this._isRegistered;
    }

    /**
     * Register a callback.
     *
     * @param {string}              eventType
     * @param {function[]|function} callback
     */
    registerCallback(eventType, callback) {
        if (!this.isRegistered()) {
            throw 'The event handler was not registered yet. You must do it before registering any callback to it.';
        }

        callback = _.isArray(callback) ? callback : [callback];

        if (!this._callbacks.hasOwnProperty(eventType)) {
            this._callbacks[eventType] = callback;
        } else {
            this._callbacks[eventType].push(...callback);
        }

        return this;
    }

    /**
     * Execute the callbacks registered for the given event type.
     *
     * @param {string} eventType
     * @param {Event}  event
     *
     * @protected
     */
    _executeCallbacks(eventType, event) {
        let callbacks = _.get(this._callbacks, eventType, []);

        if (_.isInteger(this._executeLastCallbacks)) {
            callbacks = callbacks.slice(-this._executeLastCallbacks);
        }

        for (const callback of callbacks) {
            callback(event);
        }
    }
};

/**
 * Key down event handler.
 *
 * @extends AbstractEventHandler
 */
class KeyDownEventHandler extends AbstractEventHandler {
    /**
     * @inheritDoc
     */
    getEventListenerType() {
        return 'keydown';
    }

    /**
     * @inheritDoc
     */
    _handleEvent(event) {
        if (event.keyCode === 90 && event.ctrlKey) {
            return this._executeCallbacks('keyDownCtrlZ', event);
        }

        if (event.keyCode === 65 && event.ctrlKey) {
            return this._executeCallbacks('keyDownCtrlA', event);
        }

        if (event.keyCode === 27) {
            return this._executeCallbacks('keyDownEscape', event);
        }

        if (event.keyCode === 13) {
            return this._executeCallbacks('keyDownEnter', event);
        }

        if (event.keyCode === 9) {
            return this._executeCallbacks('keyDownTab', event);
        }

        if (event.keyCode === 38) {
            return this._executeCallbacks('keyDownArrowUp', event);
        }

        if (event.keyCode === 40) {
            return this._executeCallbacks('keyDownArrowDown', event);
        }

        if (event.keyCode === 37) {
            return this._executeCallbacks('keyDownArrowLeft', event);
        }

        if (event.keyCode === 39) {
            return this._executeCallbacks('keyDownArrowRight', event);
        }

        if (event.keyCode === 46) {
            return this._executeCallbacks('keyDownDelete', event);
        }

        if (event.keyCode === 32) {
            return this._executeCallbacks('keyDownSpace', event);
        }
    }

    /**
     * On ESC pressed.
     *
     * @param {function|function[]} callback
     *
     * @returns {KeyDownEventHandler}
     */
    onEsc(callback) {
        return this.registerCallback('keyDownEscape', callback);
    }

    /**
     * On Enter pressed.
     *
     * @param {function|function[]} callback
     *
     * @returns {KeyDownEventHandler}
     */
    onEnter(callback) {
        return this.registerCallback('keyDownEnter', callback);
    }

    /**
     * On CTRL + Z pressed.
     *
     * @param {function|function[]} callback
     *
     * @returns {KeyDownEventHandler}
     */
    onCtrlZ(callback) {
        return this.registerCallback('keyDownCtrlZ', callback);
    }

    /**
     * On CTRL + A pressed.
     *
     * @param {function|function[]} callback
     *
     * @returns {KeyDownEventHandler}
     */
    onCtrlA(callback) {
        return this.registerCallback('keyDownCtrlA', callback);
    }

    /**
     * On DEL pressed.
     *
     * @param {function|function[]} callback
     *
     * @returns {KeyDownEventHandler}
     */
    onDelete(callback) {
        return this.registerCallback('keyDownDelete', callback);
    }

    /**
     * On TAB pressed.
     *
     * @param {function|function[]} callback
     *
     * @returns {KeyDownEventHandler}
     */
    onTab(callback) {
        return this.registerCallback('keyDownTab', callback);
    }

    /**
     * On ARROW UP pressed.
     *
     * @param {function|function[]} callback
     *
     * @returns {KeyDownEventHandler}
     */
    onArrowUp(callback) {
        return this.registerCallback('keyDownArrowUp', callback);
    }

    /**
     * On ARROW DOWN pressed.
     *
     * @param {function|function[]} callback
     *
     * @returns {KeyDownEventHandler}
     */
    onArrowDown(callback) {
        return this.registerCallback('keyDownArrowDown', callback);
    }

    /**
     * On ARROW LEFT pressed.
     *
     * @param {function|function[]} callback
     *
     * @returns {KeyDownEventHandler}
     */
    onArrowLeft(callback) {
        return this.registerCallback('keyDownArrowLeft', callback);
    }

    /**
     * On ARROW RIGHT pressed.
     *
     * @param {function|function[]} callback
     *
     * @returns {KeyDownEventHandler}
     */
    onArrowRight(callback) {
        return this.registerCallback('keyDownArrowRight', callback);
    }

    /**
     * On SPACE pressed.
     *
     * @param {function|function[]} callback
     *
     * @returns {KeyDownEventHandler}
     */
    onSpace(callback) {
        return this.registerCallback('keyDownSpace', callback);
    }
}

/**
 * Event handler.
 *
 * @type {{keyPress(): *, new(): EventHandler, prototype: EventHandler}}
 */
class EventHandler {
    /**
     * Get a new key down event handler.
     *
     * @param {boolean} register Whether the handler must be registered or not.
     *
     * @returns {KeyDownEventHandler}
     */
    static keyDown(register = true) {
        const handler = new KeyDownEventHandler();

        return register ? handler.register() : handler;
    }
}

export { EventHandler };
