import _ from 'lodash';

const Pluralizer = {
    /**
     * Separator for separating values
     */
    SEPARATOR: '|',

    /**
     * Opening tag for starting an expression
     */
    EXPRESSION_OPEN: '[',

    /**
     * Closing tag for ending an expression
     */
    EXPRESSION_CLOSE: ']',

    /**
     * Separator for separating a range within an expression
     */
    EXPRESSION_SEPARATOR: ',',

    /**
     * Holds the originating string containing multiple values.
     */
    string: '',

    /**
     * Holds the number based on which the extraction will be done.
     */
    number: null,

    /**
     * Initialize pluralizer.
     *
     * @param {string} string
     * @param {int}    number
     *
     * @return {object}
     */
    init: function (string, number) {
        this.string = string;
        this.number = number;

        this.validateString();

        return this;
    },

    /**
     * Extracts the proper part of the string and returns it.
     *
     * @return {string}
     */
    extract: function () {
        // Split values
        var values = this.string.split(this.SEPARATOR);

        // If we have exactly two values we don't need to apply the expression syntax
        if (values.length === 2) {
            return this.pluralizeWithoutExpression(values);
        }

        return this.pluralizeWithExpression(values);
    },

    /**
     * Validates the string.
     */
    validateString: function () {
        // Check if separator is present
        if (this.string.indexOf(this.SEPARATOR) === -1) {
            throw 'Invalid plural string';
        }
    },

    /**
     * Evaluates a simple string without expressions.
     *
     * @param {array} values
     *
     * @return {string}
     */
    pluralizeWithoutExpression: function (values) {
        return this.number == 1 ? values[0] : values[values.length - 1];
    },

    /**
     * Evaluates an advanced string with expressions.
     *
     * @param {array} values
     *
     * @return {string}
     */
    pluralizeWithExpression: function (values) {
        var result = null;

        // Loop trough values
        _.forEach(
            values,
            function (value) {
                this.validateExpression(value);
                var expression = this.extractExpressionFromString(value);

                // If expression is a number it's an exact one
                if (!isNaN(expression)) {
                    if (this.matchExactExpression(parseInt(expression))) {
                        result = this.removeExpression(value);

                        return false;
                    }
                }
                // Otherwise it's a range expression
                else {
                    if (this.matchRangeExpression(expression)) {
                        result = this.removeExpression(value);

                        return false;
                    }
                }

                return true;
            }.bind(this)
        );

        // If we have no match until now the number is probably out of range
        if (result === null) {
            throw 'Plural expression out of range';
        } else {
            return result;
        }
    },

    /**
     * Checks if a string contains a valid expression.
     *
     * @param {string} string String with expression
     */
    validateExpression: function (string) {
        if (
            string.indexOf(this.EXPRESSION_OPEN) === -1 ||
            string.indexOf(this.EXPRESSION_CLOSE) === -1 ||
            string.indexOf(this.SEPARATOR) !== -1
        ) {
            throw 'Invalid plural expression';
        }
    },

    /**
     * Extracts the expression from a string.
     *
     * @param {string} string String with expression
     *
     * @return {string}
     */
    extractExpressionFromString: function (string) {
        // Create pattern
        var pattern = new RegExp('\\' + this.EXPRESSION_OPEN + '(.*)\\' + this.EXPRESSION_CLOSE);

        // Perform matching
        var matches = string.match(pattern);

        return matches[1];
    },

    /**
     * Returns bool if an exact expression matches the number.
     *
     * @param {string} expression Exact expression
     *
     * @return {Boolean}
     */
    matchExactExpression: function (expression) {
        return Boolean(this.number === expression);
    },

    /**
     * Returns bool if range expression matches the number.
     *
     * @param {string} expression Range expression
     *
     * @return {boolean}
     */
    matchRangeExpression: function (expression) {
        this.validateRangeExpression(expression);

        // Split the expression
        var parts = expression.split(this.EXPRESSION_SEPARATOR);

        // If first part is set and bigger than quantity it doesn't match
        if (!isNaN(parts[0]) && parseInt(parts[0]) > this.number) {
            return false;
        }

        // If second part is set and smaller than quantity it doesn't match
        if (!isNaN(parts[1]) && parseInt(parts[1]) < this.number) {
            return false;
        }

        // Expression seems to match
        return true;
    },

    /**
     * Checks if a range expression is valid.
     *
     * @param {string} expression Range expression
     */
    validateRangeExpression: function (expression) {
        // Check if expression separator is present
        if (expression.indexOf(this.EXPRESSION_SEPARATOR) === -1) {
            throw 'Invalid plural range expression';
        }
    },

    /**
     * Removes the expression from a string.
     *
     * @param {string} string String with expression
     *
     * @return {string}
     */
    removeExpression: function (string) {
        return string.substr(string.indexOf(this.EXPRESSION_CLOSE) + 1).trim();
    },
};

export { Pluralizer };
