import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'Shared/resources/assets/app/js/ui/forms/buttons';

const StickyButtons = ({ buttons, className = '' }) => {
    if (!buttons || !Array.isArray(buttons) || buttons.length !== 2) {
        throw Error(
            'The sticky buttons component must have the buttons property which must be an array holding ' +
                'the properties of exactly 2 buttons'
        );
    }

    const [firstButton, secondButton] = buttons;
    const firstButtonClassName = firstButton.className ?? '';

    return (
        <span className={`ui-sticky-buttons-container ${className}`}>
            <Button {...firstButton} className={`${firstButtonClassName} ui-button-sticky`} />
            <Button {...secondButton} className={firstButtonClassName} />
        </span>
    );
};

StickyButtons.propTypes = {
    buttons: PropTypes.array.isRequired,
    className: PropTypes.string,
};

export { StickyButtons };
