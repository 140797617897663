import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import _ from 'lodash';

import { config } from 'Shared/resources/assets/app/js/helpers/configHelpers';

dayjs.extend(customParseFormat);

const i18nMain = {
    /**
     * The configuration for the i18n framework.
     */
    configuration: [],

    /**
     * The current value to be formatted.
     */
    value: '',

    /**
     * Initialize the framework.
     */
    init: function () {
        if (this.configuration.length === 0) {
            this.configuration = config('app.i18n');
        }
    },

    /**
     * Set the current value.
     *
     * @param {string|Date} value
     */
    setValue: function (value) {
        try {
            // The function toISOString ignores timezone offset
            // We need to manually fix that
            if (typeof value.toISOString === 'function') {
                this.value = new Date(value.getTime() - value.getTimezoneOffset() * 60000).toISOString();
            } else {
                this.value = value.toString();
            }
        } catch (e) {
            this.value = '';
        }
    },

    /**
     * Currency formatter.
     *
     * @param {string|null} currencyCode
     *
     * @returns {string}
     */
    currency: function (currencyCode = null) {
        var currencyPrecision = this.getCurrencyPrecision();
        var number = this.number(currencyPrecision);

        return this.currencyToken(currencyCode).replace('%', number);
    },

    currencyToken: function (currencyCode = null) {
        return currencyCode === null ? this.configuration.currency : this.getCurrencyUnits()[currencyCode][1];
    },

    /**
     * Formats a given number in the provided ISO currency code.
     *
     * @param currencyCode {string}
     * @return {string}
     */
    currencyCode: function (currencyCode) {
        var currencyPrecision = this.getCurrencyPrecision();
        var languageCode = this.getLanguageCode();
        var number = parseFloat(parseFloat(this.value).toFixed(currencyPrecision));

        return number.toLocaleString(languageCode, {
            style: 'currency',
            currency: currencyCode,
        });
    },

    /**
     * Get the currency symbol.
     *
     * @param {string|null} currencyCode
     *
     * @returns {string|void|*|XML}
     */
    getCurrencySymbol: function (currencyCode = null) {
        return this.currencyToken(currencyCode).replace('%', '');
    },

    getCurrencyDefaultFormat: function () {
        return config('app.i18n').currencyDefaultFormat;
    },

    getCurrencyUnits: function () {
        return config('app.i18n').currencyUnits;
    },

    /**
     * Check if currency format uses currency symbol before amount.
     */
    currencySymbolPrecedesAmount: function (currencyCode = null) {
        return this.currencyToken(currencyCode).trim().endsWith('%');
    },

    /**
     * Date formatter.
     *
     * @returns {string}
     */
    date: function () {
        const format = i18nMain.getFormatFromDateTimeType('date');

        return dayjs(this.getDateFromValue()).format(format);
    },

    /**
     * Date time formatter.
     *
     * @returns {string}
     */
    dateTime: function () {
        const format = i18nMain.getFormatFromDateTimeType('dateTime');
        const val = this.getDateTimeFromValue();

        return dayjs(val).format(format);
    },

    /**
     * Time formatter.
     *
     * @returns {string}
     */
    time: function () {
        const format = i18nMain.getFormatFromDateTimeType('time');

        return dayjs(this.getDateTimeFromValue()).format(format);
    },

    /**
     * Length formatter.
     *
     * @returns {string}
     */
    length: function () {
        return this.value + ' ' + this.configuration.length;
    },

    /**
     * Number formatter.
     *
     * @param {int} decimals
     *
     * @returns {string}
     */
    number: function (decimals) {
        decimals = _.isNumber(decimals) ? decimals : 2;

        var thousandSeparator = this.configuration.number[0];
        var decimalPoint = this.configuration.number[1];

        var toFixedFix = function (number, precision) {
            var power = Math.pow(10, precision);
            return '' + (Math.round(number * power) / power).toFixed(precision);
        };

        var number = (this.value + '').replace(/[^0-9+\-Ee.]/g, '');
        number = !isFinite(+number) ? 0 : +number;

        var precision = !isFinite(+decimals) ? 0 : Math.abs(decimals);

        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        var parts = (precision ? toFixedFix(number, precision) : '' + Math.round(number)).split('.');

        // Check if thousand separator needs to be added
        if (parts[0].length > 3) {
            parts[0] = parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandSeparator);
        }

        if ((parts[1] || '').length < precision) {
            parts[1] = parts[1] || '';
            parts[1] += new Array(precision - parts[1].length + 1).join('0');
        }

        return parts.join(decimalPoint);
    },

    /**
     * Weight formatter.
     *
     * @returns {string}
     */
    weight: function () {
        return this.value + ' ' + this.configuration.weight;
    },

    /**
     * Get a Date object from the current value
     *
     * @returns {Date}
     */
    getDateFromValue: function () {
        return dayjs(this.value, 'YYYY-MM-DD').toDate();
    },

    /**
     * Get a DateTime object from the current value
     *
     * @returns {Date}
     */
    getDateTimeFromValue: function () {
        return dayjs(this.value, 'YYYY-MM-DD HH:mm:ss').toDate();
    },

    getCurrencyPrecision: function () {
        try {
            return parseInt(config('app.currency.precision'));
        } catch (e) {
            return 2;
        }
    },

    getLanguageCode: function () {
        try {
            return config('app.currentLanguage').code || 'en';
        } catch (e) {
            return 'en';
        }
    },

    getNumberFormat: function () {
        return [...this.configuration.number, this.getCurrencyPrecision()];
    },

    /**
     * @param dateTimeType (one of: 'date', 'dateTime', 'time')
     *
     * @returns {string}
     */
    getFormatFromDateTimeType: function (dateTimeType) {
        if (!['date', 'dateTime', 'time'].includes(dateTimeType)) {
            throw Error(`Invalid date time type ${dateTimeType}`);
        }

        const dateTimeConfig = config('app.i18n')[dateTimeType];

        return dateTimeConfig
            .replace('d', 'DD')
            .replace('m', 'MM')
            .replace('y', 'YYYY')
            .replace('Y', 'YYYY')
            .replace('H', 'HH')
            .replace('i', 'mm')
            .replace('g', 'hh');
    },
};

export { i18nMain };
