import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { TogglingList } from 'Shared/resources/assets/app/js/ui/forms/widgets';

const RadioTogglingList = class RadioTogglingList extends React.PureComponent {
    /**
     * Props types.
     *
     * @type {object}
     */
    static propTypes = {
        value: PropTypes.number,
        // The options should be an array similar to [
        //      PropTypes.number.isRequired, - the id which is selected
        //      PropTypes.string.isRequired, - the string to search in or to be displayed in case key 2 not provided
        //      PropTypes.oneOfType([        - the object to be displayed
        //          PropTypes.number,
        //          PropTypes.string,
        //          PropTypes.object,
        //          PropTypes.array,
        //      ])
        // ]
        options: PropTypes.arrayOf(PropTypes.array),
        allowEmpty: PropTypes.bool,
        onChange: PropTypes.func,
    };

    /**
     * The default properties.
     *
     * @type {object}
     */
    static defaultProps = {
        value: null,
        options: [],
        allowEmpty: false,
        onChange: () => {},
    };

    /**
     * On change value.
     *
     * @param {array} value
     */
    onChange = (value) => {
        if (!this.props.allowEmpty && value.length === 0) {
            return;
        }

        if (value.length === 0) {
            return this.props.onChange(null);
        }

        this.props.onChange(_.difference(value, [this.props.value]));
    };

    render() {
        const { value, onChange, toggleIcon, ...props } = this.props;

        return (
            <TogglingList
                {...props}
                value={this.props.value === null ? [] : [this.props.value]}
                onChange={this.onChange}
                toggleIcon="circle"
            />
        );
    }
};

export { RadioTogglingList };
