import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Button } from 'Shared/resources/assets/app/js/ui/forms/buttons';

const DropDownButtons = class DropDownButtons extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string,
        className: PropTypes.string, // Usually this is the className of the ui-button which gives the background-color
        containerClassName: PropTypes.string,
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
                disabled: PropTypes.bool,
                onClick: PropTypes.func.isRequired,
            })
        ).isRequired,
        isLoading: PropTypes.bool,
        direction: PropTypes.string,
        onToggle: PropTypes.func,
    };

    static defaultProps = {
        id: undefined,
        className: 'ui-button-primary',
        isLoading: false,
        containerClassName: '',
        direction: 'bottom',
        onToggle: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpened: false,
            firstHiddenButtonStyle: {},
        };
    }

    componentWillUnmount() {
        this.clearCloseTimeout();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.visibleButtonsRef &&
            this.firstHiddenButtonRef &&
            _.isEmpty(this.state.firstHiddenButtonStyle) &&
            this.visibleButtonsRef.getBoundingClientRect().width ===
                this.firstHiddenButtonRef.getBoundingClientRect().width
        ) {
            this.setState({
                firstHiddenButtonStyle: {
                    [this.props.direction === 'bottom' ? 'borderTopRightRadius' : 'borderBottomRightRadius']: 0,
                },
            });
        }

        if (this.state.isOpened !== prevState.isOpened) {
            this.props.onToggle(this.state.isOpened);
        }
    }

    onToggle = () => {
        this.setState({ isOpened: !this.state.isOpened });
    };

    getButtonsWithDefaultProps() {
        return this.props.buttons.map((button) => {
            button = _.defaults({}, button, { disabled: false });

            // Replace the onClick handler with an empty function if the button is disabled.
            if (button.disabled) {
                button.onClick = () => {};
            }

            return button;
        });
    }

    setCloseTimeout = () => {
        this.closeTimeout = setTimeout(() => this.setState({ isOpened: false }), 500);
    };

    clearCloseTimeout = () => {
        clearTimeout(this.closeTimeout);
    };

    render() {
        const buttons = this.getButtonsWithDefaultProps();

        if (buttons.length === 0) {
            return null;
        }

        if (this.props.buttons.length === 1) {
            const button = _.first(this.props.buttons);

            return (
                <Button
                    className={button.disabled === true ? 'ui-button-disabled' : this.props.className}
                    value={button.content}
                    onClick={!button.disabled ? button.onClick : null}
                />
            );
        }

        const enabledButtonsCount = _.sumBy(buttons, (button) => +!button.disabled);
        const togglingIsDisabled =
            enabledButtonsCount === 0 || (enabledButtonsCount === 1 && buttons[0].disabled === false);
        const togglingIconClass = this.props.isLoading
            ? 'fa-spinner fa-spin'
            : `fa-caret-${
                  this.state.isOpened
                      ? this.props.direction === 'bottom'
                          ? 'up'
                          : 'down'
                      : this.props.direction === 'bottom'
                      ? 'down'
                      : 'up'
              }`;

        return (
            <span
                id={this.props.id}
                className={`ui-sticky-buttons-container ui-drop-down-buttons-container ${
                    this.props.containerClassName
                } ${this.state.isOpened ? 'opened' : ''} ${this.props.direction}`}
                onMouseEnter={this.clearCloseTimeout}
                onMouseLeave={this.setCloseTimeout}
            >
                <span ref={(ref) => (this.visibleButtonsRef = ref)}>
                    <Button
                        value={buttons[0].content}
                        onClick={buttons[0].onClick}
                        className={`${
                            buttons[0].disabled ? 'ui-button-disabled' : this.props.className
                        } ui-button-sticky`}
                    />
                    <Button
                        value={<i className={`fa fa-fw ${togglingIconClass}`} />}
                        onClick={!togglingIsDisabled ? this.onToggle : null}
                        className={togglingIsDisabled ? 'ui-button-disabled' : this.props.className}
                    />
                </span>
                {this.state.isOpened && (
                    <div className="secondary-buttons">
                        <ul>
                            {buttons.map((button, index) =>
                                index === 0 ? null : (
                                    <li
                                        key={index}
                                        ref={(ref) =>
                                            index === (this.props.direction === 'bottom' ? 1 : buttons.length - 1) &&
                                            (this.firstHiddenButtonRef = ref)
                                        }
                                        className={button.disabled ? 'ui-button-disabled' : this.props.className}
                                        style={
                                            index === (this.props.direction === 'bottom' ? 1 : buttons.length - 1)
                                                ? this.state.firstHiddenButtonStyle
                                                : {}
                                        }
                                        onClick={button.onClick}
                                    >
                                        {button.content}
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                )}
            </span>
        );
    }
};

export { DropDownButtons };
