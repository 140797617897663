import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { confirm } from 'Shared/resources/assets/app/js/helpers/notificationHelpers';
import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';

/**
 * Abstract add collection item form react component.
 */
class AbstractAddForm extends React.PureComponent {
    /**
     * Context params which are inherited and used inside this class.
     */
    static contextTypes = {
        keyDownEventHandler: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            item: this.getEmptyItem(),
        };
    }

    componentDidMount() {
        this.context.keyDownEventHandler.onEsc(this.confirmItemClear).onEnter(this.storeItem);

        this.focusField();
    }

    componentWillUnmount() {
        this.context.keyDownEventHandler.unregisterLastCallbacks();
    }

    clearItem() {
        this.setState({
            item: this.getEmptyItem(),
            errors: {},
        });

        this.focusField();
    }

    confirmItemClear = () => {
        const alertify = getTopWindowDocument().App.Notification.getInstance();
        const maintainFocus = alertify.defaults.maintainFocus;
        const activeElement = document.activeElement;
        // Restore the maintainFocus option after 2 seconds. It is enough time to let the dialog be completely closed.
        const restoreMaintainFocus = () => setTimeout(() => (alertify.defaults.maintainFocus = maintainFocus), 2000);

        alertify.defaults.maintainFocus = false;

        const message =
            typeof this.getItemClearConfimationMessage === 'function'
                ? this.getItemClearConfimationMessage()
                : ts('Are you sure you want to cancel this item creation?');

        confirm(
            message,
            () => {
                this.clearItem();
                restoreMaintainFocus();
            },
            () => {
                activeElement && activeElement instanceof HTMLInputElement && activeElement.type === 'text'
                    ? activeElement.focus()
                    : this.focusField();
                restoreMaintainFocus();
            }
        );
    };

    storeItem = () => {
        this.validate().then(() => {
            const item = this.state.item;

            this.clearItem(true);

            this.props.onStore(item);
        });
    };

    getValidationProps(fieldPath) {
        if (!_.has(this.state.errors, fieldPath)) {
            return {};
        }

        return {
            hasErrors: true,
            hint: (
                <ul>
                    {_.get(this.state.errors, fieldPath).map((error, index) => (
                        <li key={index}>{error}</li>
                    ))}
                </ul>
            ),
        };
    }

    onValueChanged = (fieldPath, value) => {
        const item = _.cloneDeep(this.state.item);

        _.setWith(item, fieldPath, value, Object);

        this.setState({ item });
    };
}

export { AbstractAddForm };
