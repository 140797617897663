import { Event } from 'Shared/resources/assets/app/js/utils/Event';

export const listen = function () {
    return Event.listen(...arguments);
};

export const stopListening = function () {
    return Event.stopListening(...arguments);
};

export const popListener = function () {
    return Event.pop(...arguments);
};

export const fire = function () {
    return Event.fire(...arguments);
};
