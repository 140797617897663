import { Flatpickr } from 'Shared/resources/assets/app/js/ui/pickers/flatpickr';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';

/**
 * Initial configure of the Flatpickr instances.
 */
const configureFlatpickr = function () {
    if (window.flatpickrWasConfigured !== undefined) {
        return;
    }

    Flatpickr.localize({
        weekdays: {
            shorthand: [ts('SUN'), ts('MON'), ts('TUE'), ts('WED'), ts('THU'), ts('FRI'), ts('SAT')],
            longhand: [
                ts('Sunday'),
                ts('Monday'),
                ts('Tuesday'),
                ts('Wednesday'),
                ts('Thursday'),
                ts('Friday'),
                ts('Saturday'),
            ],
        },
        months: {
            shorthand: [
                ts('Jan'),
                ts('Feb'),
                ts('Mar'),
                ts('Apr'),
                ts('May'),
                ts('Jun'),
                ts('Jul'),
                ts('Aug'),
                ts('Sep'),
                ts('Oct'),
                ts('Nov'),
                ts('Dec'),
            ],
            longhand: [
                ts('January'),
                ts('February'),
                ts('March'),
                ts('April'),
                ts('May'),
                ts('June'),
                ts('July'),
                ts('August'),
                ts('September'),
                ts('October'),
                ts('November'),
                ts('December'),
            ],
        },
        daysInMonth: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
        firstDayOfWeek: 1,
        ordinal: function ordinal(nth) {
            var s = nth % 100;
            if (s > 3 && s < 21) return 'th';
            switch (s % 10) {
                case 1:
                    return 'st';
                case 2:
                    return 'nd';
                case 3:
                    return 'rd';
                default:
                    return 'th';
            }
        },
        rangeSeparator: ts(' to '),
        weekAbbreviation: ts('Wk'),
        scrollTitle: ts('Scroll to increment'),
        toggleTitle: ts('Click to toggle'),
    });

    Flatpickr.defaultConfig.altInputClass += ' ui-ignore-changes';

    window.flatpickrWasConfigured = true;
};

export { configureFlatpickr };
