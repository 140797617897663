import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';

export const defaultTypeMessage = ts(`Value is not a valid %1`, ['${type}']);

export const defaultValidateMessages = {
    default: ts(`Validation error`),
    required: ts(`Required`),
    enum: ts(`Must be one of [%1]`, ['${enum}']),
    whitespace: ts(`Field cannot be empty`),
    date: {
        format: ts(`Value is invalid for format date`),
        parse: ts(`Value could not be parsed as date`),
        invalid: ts(`Value is invalid date`),
    },
    types: {
        string: defaultTypeMessage,
        method: defaultTypeMessage,
        array: defaultTypeMessage,
        object: defaultTypeMessage,
        number: defaultTypeMessage,
        date: defaultTypeMessage,
        boolean: defaultTypeMessage,
        integer: defaultTypeMessage,
        float: defaultTypeMessage,
        regexp: defaultTypeMessage,
        email: defaultTypeMessage,
        url: defaultTypeMessage,
        hex: defaultTypeMessage,
    },
    string: {
        len: ts(`Value must be exactly %1 characters`, ['${len}']),
        min: ts(`Value must be at least %1 characters`, ['${min}']),
        max: ts(`Value cannot be longer than %1 characters`, ['${max}']),
        range: ts(`Value must be between %1 and %2 characters`, ['${min}', '${max}']),
    },
    number: {
        len: ts(`Value must equal %1`, ['${len}']),
        min: ts(`Value cannot be less than %1`, ['${min}']),
        max: ts(`Value cannot be greater than %1`, ['${max}']),
        range: ts(`Value must be between %1 and %2`, ['${min}', '${max}']),
    },
    array: {
        len: ts(`Value must be exactly %1 in length`, ['${len}']),
        min: ts(`Value cannot be less than %1 in length`, ['${min}']),
        max: ts(`Value cannot be greater than %1 in length`, ['${max}']),
        range: ts(`Value must be between %1 and %2 in length`, ['${min}', '${max}']),
    },
    pattern: {
        mismatch: ts(`Value does not match pattern %1`, ['${pattern}']),
    },
};
