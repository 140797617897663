import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { DebouncedTextInput } from 'Shared/resources/assets/app/js/ui/forms/inputs';

const TextFilter = class TextFilter extends React.Component {
    static propTypes = {
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        identifier: PropTypes.string,
        value: PropTypes.string,
        isLoading: PropTypes.bool,
        isOpened: PropTypes.bool,
        isHidden: PropTypes.bool,
        zIndex: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        onToggle: PropTypes.func,
    };

    /**
     * Get the default properties.
     *
     * @returns {object}
     */
    static defaultProps = {
        identifier: 'text',
        value: '',
        isLoading: false,
        isOpened: false,
        isHidden: false,
        zIndex: 1,
        onToggle: () => {},
    };

    state = {
        isOpened: this.props.isOpened,
    };

    inputRef = null;

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.isOpened !== this.state.isOpened) {
            this.setState({
                isOpened: newProps.isOpened,
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.closeTimeout);
    }

    onToggle = (event) => {
        if (event && (event?.target.matches('.fa-times-circle') || !event?.target.closest('.title'))) {
            return;
        }

        const isOpened = !this.state.isOpened;

        this.setState({ isOpened }, () => {
            if (isOpened && this.inputRef) {
                this.inputRef.focus();
            }

            this.props.onToggle(this.props.identifier, isOpened);
        });
    };

    /**
     * On mouse leave.
     *
     * @param {Event|int} timeout
     */
    onMouseLeave = (timeout) => {
        timeout = _.isInteger(timeout) ? timeout : 500;

        this.closeTimeout = setTimeout(() => this.state.isOpened && this.onToggle(), timeout);
    };

    /**
     * On mouse enter.
     */
    onMouseEnter = () => {
        if (this.state.isOpened) {
            clearTimeout(this.closeTimeout);
        }
    };

    /**
     * Main render method.
     *
     * @returns {Object}
     */
    render() {
        if (this.props.isHidden) {
            return null;
        }

        const isActive = this.props.value.length > 0;

        return (
            <div
                key={this.props.identifier}
                className={[
                    'ui-filter',
                    'text',
                    isActive ? 'active' : '',
                    this.props.isLoading ? 'loading' : '',
                    this.state.isOpened ? 'opened' : '',
                ].join(' ')}
                onClick={this.onToggle}
                onMouseOver={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                <div className="title" style={{ zIndex: this.props.zIndex + 1 }}>
                    {ts.apply(null, _.isArray(this.props.name) ? this.props.name : [this.props.name])}
                    {isActive ? (
                        <div className="value-label" title={this.props.value}>
                            <span>{this.props.value}</span>

                            {this.props.isLoading ? (
                                <i className="fa fa-spinner fa-spin" />
                            ) : (
                                <i
                                    title={ts('Reset filter')}
                                    className="fa fa-times-circle"
                                    onClick={this.props.onChange.bind(null, this.props.identifier, '')}
                                />
                            )}
                        </div>
                    ) : this.props.isLoading ? (
                        <i className="fa fa-spinner fa-spin" />
                    ) : (
                        <i className={'fa fa-sort-' + (this.state.isOpened ? 'asc' : 'desc')} />
                    )}
                </div>

                <div className="toggling-list" style={{ zIndex: this.props.zIndex }}>
                    <div className="ui-form-horizontal">
                        <div className="ui-form-group">
                            <label className="ui-form-label" title={ts('Search')}>
                                {ts('Search')}
                            </label>
                            <DebouncedTextInput
                                placeholder={ts('Type in the text to search for')}
                                className="ui-form-element-text"
                                value={this.props.value}
                                setRef={(ref) => (this.inputRef = ref)}
                                onChange={(e) => this.props.onChange(this.props.identifier, e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export { TextFilter };
