import { isEmpty } from 'lodash';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { alert, error } from 'Shared/resources/assets/app/js/helpers/notificationHelpers';
import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';
import { getCsrfToken, setCsrfToken } from 'Shared/resources/assets/app/js/utils/csrfToken';

const Ajax = {
    /**
     * Timeout in MS after which all requests will abort.
     */
    TIMEOUT: 60000,

    /**
     * Contains the last response object.
     */
    response: null,

    /**
     * Resets the response to default.
     */
    resetResponse: function () {
        this.response = {
            response: null,
            successful: true,
            reloadPage: false,
            errorCode: null,
            errorMessages: [],
        };
    },

    /**
     * Make an AJAX call using a GET request.
     *
     * @param {string}   url        Do not provide base URL or prefixing slash.
     * @param {function} [callback] Will be called after request is finished, must accept exactly one parameter
     *                              which will be populated with the Ajax.response object.
     * @param {object}   [settings] Will be handed to jQuery $.ajax.
     *
     * @param errorCallback
     * @returns {jqXHR} jqXHR object
     */
    get: function (url, callback, settings, errorCallback, alwaysCallback) {
        return this.send('GET', url, settings, callback, errorCallback, alwaysCallback);
    },

    /**
     * Make an AJAX call using a POST request.
     *
     * @param {string}   url        Do not provide base URL or prefixing slash.
     * @param {object}   data       The actual post data.
     * @param {function} [callback] Will be called after request is finished, must accept exactly one parameter
     *                              which will be populated with the Ajax.response object.
     * @param {object}   [settings] Will be handed to jQuery $.ajax.
     *
     * @param errorCallback
     * @param alwaysCallback
     * @returns {jqXHR} jqXHR object
     */
    post: function (url, data, callback, settings, errorCallback, alwaysCallback) {
        return this.send('POST', url, settings, callback, data, errorCallback, alwaysCallback);
    },

    /**
     * Make an AJAX call using a PUT request.
     *
     * @param url
     * @param data
     * @param callback
     * @param settings
     * @param errorCallback
     * @param alwaysCallback
     * @return {jqXHR}
     */
    put: function (url, data, callback, settings, errorCallback, alwaysCallback) {
        return this.send('PUT', url, settings, callback, data, errorCallback, alwaysCallback);
    },

    /**
     * Make an AJAX call using a DELETE request.
     *
     * @param url
     * @param callback
     * @param settings
     * @param errorCallback
     * @param alwaysCallback
     * @return {jqXHR}
     */
    delete: function (url, callback, settings, errorCallback, alwaysCallback) {
        return this.send('DELETE', url, settings, callback, errorCallback, alwaysCallback);
    },

    /**
     * Performs the actual AJAX request
     *
     * @param {string}   type     Request type, POST or GET.
     * @param {string}   url      Do not provide base URL but prefixing slash.
     * @param {object}   settings Will be handed to jQuery $.ajax.
     * @param {function} callback Will be called after request is finished, must accept exactly one parameter
     *                            which will be populated with the Ajax.response object.
     * @param {object}   [data]   Data to be sent, this only applies to POST requests.
     *
     * @param errorCallback
     * @param alwaysCallback
     * @returns {jqXHR} jqXHR object
     */
    send: function (type, url, settings, callback, data, errorCallback, alwaysCallback) {
        data = data || null;

        this.resetResponse();

        const ajaxConfig = {
            url: url,
            type: type,
            timeout: this.TIMEOUT,
            dataType: 'json', // Disallow sending processed HTML to front-end. Bad practice.
            headers: {
                'X-CSRF-TOKEN': getCsrfToken(),
            },
            ...(settings || {}),
        };

        if (data) {
            ajaxConfig.data = data;
        }

        // Construct and send request
        // Construct response with data
        return (
            getTopWindowDocument()
                .$.ajax(ajaxConfig)
                .done((data) => {
                    this.response.successful = data.successful;
                    this.response.response = data.response;
                    this.response.errorMessages = data.errorMessages;
                    this.response.reloadPage = data.reloadPage;

                    if (data.customErrorMessage) {
                        this.response.customErrorMessage = data.customErrorMessage;
                    }

                    // If request failed, update response accordingly
                })
                .fail((jqXhr, textStatus, errorThrown) => {
                    this.response.errorResponse = jqXhr.responseJSON;

                    // Requests can fail without an actual error having occurred
                    // For example if the request was aborted by the user or application
                    if (textStatus === 'abort' || isEmpty(errorThrown)) {
                        this.response.successful = true;
                        this.response.response = null;
                    } else {
                        this.response.successful = false;
                        this.response.errorCode = jqXhr.status;
                        this.response.errorMessages.push(textStatus);

                        if (textStatus === 'timeout') {
                            this.response.errorCode = 408; // HTTP timeout status code
                        }
                    }
                })
                // Finally, evaluate the response
                .always(() => {
                    setCsrfToken(this.response.csrfToken);

                    // If the response was successful, we can still have errors in application layer
                    // The callback must only be executed if there are no errors at all
                    if (this.response.successful) {
                        if (this.response.reloadPage === true) {
                            this.reloadPage();
                            return;
                        }

                        if (this.response.response) {
                            if (this.response.response.pricingPlan !== undefined) {
                                this.showPricingPlanAlert();
                                return;
                            }

                            if (this.response.response.security !== undefined) {
                                this.showSecurityAlert();
                                return;
                            }
                        }

                        if (typeof callback === 'function') {
                            callback(this.response);
                        }
                    } else if (!this.response.successful) {
                        if (typeof errorCallback === 'function') {
                            errorCallback(this.response);
                            return;
                        }

                        let message;

                        switch (this.response.errorCode) {
                            case 408: // Timeout
                                message = ts(
                                    'A request has timed out. Please try to use a different configuration or contact support.',
                                );
                                break;

                            case 419: // CSRF token mismatch
                                return window.top.document.location.reload();

                            case 500: // Internal server error
                                message = ts('A server error occurred. Please contact support.');
                                break;

                            default:
                                if (this.response.customErrorMessage) {
                                    message = this.response.customErrorMessage;
                                } else {
                                    message = ts(
                                        'An error with code %1 occurred. If this error persists, please contact support.',
                                        [this.response.errorCode],
                                    );
                                }
                        }
                        alert(message);
                    }

                    if (typeof alwaysCallback === 'function') {
                        /**
                         * If you specify errorCallback, then the standard alert with an error is not displayed.
                         * alwaysCallback does not suppress an alert with an error.
                         */
                        alwaysCallback(this.response);
                    }
                })
        );
    },

    /**
     * Reloads the page.
     *
     * Depending on the provided configuration, a notification is shown before the reload.
     */
    reloadPage: function () {
        if (this.response.response.hasOwnProperty('beforeReloadMessage')) {
            alert(this.response.response.beforeReloadMessage, function () {
                getTopWindowDocument().location.reload();
            });
        } else {
            getTopWindowDocument().location.reload();
        }
    },

    /**
     * Show the pricing plan alert.
     *
     * This usually means that a volume has been exceeded or a feature has been disabled.
     */
    showPricingPlanAlert: function () {
        let message;

        if (this.response.response.pricingPlan.hasOwnProperty('volumeAccess')) {
            message = ts('Your %1 volume has been exceeded.', [ts(this.response.response.pricingPlan.volumeName)]);
            message += ' ' + ts('This feature is currently not available. Please contact your administrator.');
        } else {
            message = ts('The requested feature or module is not enabled.');
        }

        error(message);
    },

    /**
     * Show the security alert.
     *
     * This usually means the user tried to call a URL he doesn't have permissions to.
     */
    showSecurityAlert: function () {
        let message = "You don't have permissions to access the requested feature.";

        if (typeof this.response.response.security === 'string') {
            message = this.response.response.security;
        }

        error(ts(message));
    },
};

export { Ajax };
