import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import _ from 'lodash';
import iro from '@jaames/iro';
import { App } from 'Shared/resources/assets/app/js/App';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { fire, listen, stopListening } from 'Shared/resources/assets/app/js/helpers/eventHelpers';
import { uniqueId } from 'Shared/resources/assets/app/js/helpers/generalHelpers';
import { Validator } from 'Shared/resources/assets/app/js/ui/libs';
import { GlobalConfigProvider } from 'Core/resources/assets/js/providers/GlobalConfigProvider';

const ColorPicker = class ColorPicker extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        color: PropTypes.string,
        presets: PropTypes.array,
        position: PropTypes.oneOf(['top-left', 'top-right', 'bottom-left', 'bottom-right']),
        preventAutoPositioning: PropTypes.bool,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        id: uniqueId('color-picker-'),
        color: '#900',
        presets: [
            '#c01313',
            '#606',
            '#404',
            '#ff9000',
            '#ff0',
            '#006',
            '#008',
            '#235dab',
            '#0780df',
            '#354c5e',
            '#00aecd',
            '#060',
            '#a9d582',
            '#cafd99',
            '#660',
            '#000',
            '#666',
            '#ccc',
            '#d0d0d0',
            '#ffffff',
        ],
        position: 'top-right',
        preventAutoPositioning: false,
        onChange: () => {},
    };

    state = {
        showColorPicker: false,
        color: this.props.color,
        position: this.props.position,
    };

    componentDidMount() {
        this.colorPicker = new iro.ColorPicker('#' + this.props.id + '-picker', {
            // Color picker options guide: https://iro.js.org/guide.html#Color-Picker-Options
            width: 150,
            height: 150,
            color: this.state.color,
            anticlockwise: true,
            borderWidth: 0,
            sliderMargin: 5,
            sliderHeight: 22,
        });

        this.colorPicker.on('color:change', this.onColorChangeAction);

        listen(UiColorPicker.EVENT_COLOR_CHANGED, this.onColorChangedEventReceived);

        // We have to wait a bit until the dom is rendered completely.
        setTimeout(this.determinePosition, 500);

        // We must recalculate the position on window resize too.
        $(window).on('resize', () => this.determinePosition());
    }

    componentWillUnmount() {
        stopListening(UiColorPicker.EVENT_COLOR_CHANGED, this.onColorChangedEventReceived);
    }

    UNSAFE_componentWillReceiveProps(props) {
        const color = _.get(props, 'color', this.state.color);
        const position = _.get(props, 'position', this.state.position);

        if (color !== this.state.color) {
            // Changing the picker color like this will trigger an color change event which updates the state accordingly.
            this.colorPicker.color.hexString = color;
        }

        if (position !== this.state.position) {
            this.determinePosition(position);
        }
    }

    onColorChangedEventReceived = (event) => {
        if (event.id === this.props.id && this.state.color !== event.color) {
            this.setState({
                color: event.color,
            });
        }
    };

    onColorChangeAction = (color) => {
        this.setState({
            color: color.hexString,
        });

        if (_.isEmpty(this.props.color) && !this.state.showColorPicker) {
            return;
        }

        this.props.onChange(color.hexString);
    };

    toggleColorPickerAction = () => {
        this.setState({
            showColorPicker: !this.state.showColorPicker,
        });
    };

    closeColorPickerAction = () => {
        this.setState({
            showColorPicker: false,
        });
    };

    scheduleColorPickerClosingAction = () => {
        this.closeColorPickerTimeout = setTimeout(this.closeColorPickerAction, 500);
    };

    cancelColorPickerClosingAction = () => {
        clearTimeout(this.closeColorPickerTimeout);
    };

    /**
     * Determine if the component is fully visible with the given position.
     *
     * If it's not visible the position will be chosen accordingly to be able to see the entire component when it's opened.
     *
     * @param {string|undefined} position
     */
    determinePosition = (position) => {
        if (this.props.preventAutoPositioning) {
            return;
        }

        const rootElement = this.getComponentRootElement();

        if (rootElement === null) {
            return;
        }

        const componentMeasures = {
            ...$(rootElement).offset(),
            width: 276,
            height: 250,
        };
        const documentMeasures = {
            width: $(document).width(),
            height: $(document).height(),
        };

        const positionProperties = (position || this.state.position).split('-');
        const finalPositionProperties = positionProperties;

        if (
            positionProperties[1] === 'right' &&
            componentMeasures.left + componentMeasures.width > documentMeasures.width
        ) {
            finalPositionProperties[1] = 'left';
        } else if (positionProperties[1] === 'left' && componentMeasures.left - componentMeasures.width < 0) {
            finalPositionProperties[1] = 'right';
        }

        if (
            positionProperties[0] === 'top' &&
            componentMeasures.top + componentMeasures.height > documentMeasures.height
        ) {
            finalPositionProperties[0] = 'bottom';
        } else if (
            positionProperties[0] === 'bottom' &&
            componentMeasures.top + componentMeasures.height < documentMeasures.height
        ) {
            finalPositionProperties[0] = 'top';
        }

        position = finalPositionProperties.join('-');

        if (position !== this.state.position) {
            this.setState({
                position: position,
            });
        }
    };

    getComponentRootElement = () => {
        try {
            return ReactDOM.findDOMNode(this);
        } catch (e) {
            return null;
        }
    };

    render() {
        return (
            <div
                id={this.props.id}
                className={['ui-color-picker', this.state.position + '-position'].join(' ')}
                onMouseEnter={this.cancelColorPickerClosingAction}
                onMouseLeave={this.scheduleColorPickerClosingAction}
            >
                <div
                    id={this.props.id + '-swatch'}
                    className="ui-color-picker-swatch"
                    style={{ backgroundColor: this.state.color }}
                    onClick={this.toggleColorPickerAction}
                />

                <div className={['ui-color-picker-container', !this.state.showColorPicker ? 'hidden' : ''].join(' ')}>
                    <div id={this.props.id + '-picker'} className="ui-color-picker-picker" />

                    <div className="ui-color-picker-presets">
                        <input
                            type="text"
                            value={this.state.color}
                            onChange={(event) => {
                                this.colorPicker.color.hexString = event.target.value;
                            }}
                        />

                        {this.props.presets.map((color, key) => (
                            <div
                                key={'preset-' + key}
                                className={['preset', this.state.color === color ? 'active' : ''].join(' ')}
                                style={{ backgroundColor: color }}
                                onClick={() => {
                                    if (this.state.color === color) {
                                        this.props.onChange(color);
                                    }
                                    this.colorPicker.color.hexString = color;
                                }}
                            />
                        ))}

                        <div className="close" onClick={this.closeColorPickerAction}>
                            <i className="fa fa-close" /> {ts('Close')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

/**
 * Color picker.
 *
 * @param {Object} element
 * @param {Object} config
 */

function UiColorPicker(element, config) {
    /**
     * Color changed event.
     */
    UiColorPicker.EVENT_COLOR_CHANGED = 'event_color_changed';

    const $element = $(element);

    const container = $element.next('.color-picker-root').get(0);
    const colorPickerId = $element.data('unique-id') + '-picker';

    $element.on('change keyup', (e) => {
        const color = e.target.value.trim();

        if (!Validator.isValid(color, 'color')) {
            return;
        }

        fire(UiColorPicker.EVENT_COLOR_CHANGED, {
            id: colorPickerId,
            color: color,
        });
    });

    const root = createRoot(container);
    root.render(
        <GlobalConfigProvider>
            <ColorPicker
                id={colorPickerId}
                color={Validator.isValid(element.value, 'color') ? element.value : ''}
                onChange={(color) => (element.value = color)}
                {...config}
            />
        </GlobalConfigProvider>,
    );
}

// Needed in Shared/resources/views/form_builder/input.blade.php
App.Ui = App.Ui ?? {};
App.Ui.ColorPicker = UiColorPicker;

export { ColorPicker };
