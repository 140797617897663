import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { CheckboxCollection, FilterGroup, RadioCollection } from 'Shared/resources/assets/app/js/ui/filters';

const Selector = class Selector extends React.Component {
    static propTypes = {
        singleSelect: PropTypes.bool,
        isLoading: PropTypes.bool,
        isOpened: PropTypes.bool,
        defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
        options: PropTypes.arrayOf(PropTypes.array.isRequired).isRequired,
        placeholder: PropTypes.string,
        selectedValuesDisplay: PropTypes.number,
        selectedValuesSeparator: PropTypes.string,
        enableResetFilter: PropTypes.bool,

        onChange: PropTypes.func,
        onToggle: PropTypes.func,
        displayAsBadges: PropTypes.bool,
    };

    /**
     * Returns default component properties.
     *
     * @returns {object}
     */
    static defaultProps = {
        singleSelect: false,
        isLoading: false,
        classPrefix: 'ui-form-element-',
        defaultClasses: 'ui-form-filter-collection',
        width: 'large',
        options: [],
        value: null,
        defaultValue: null,
        placeholder: '',
        selectedValuesDisplay: 3,
        selectedValuesSeparator: ', ',
        enableResetFilter: undefined,
        displayAsBadges: false,
        onChange: () => {},
        onToggle: () => {},
    };

    /**
     * Builds the class string.
     *
     * @returns {string}
     */
    buildClassName = () => {
        const type = this.props.singleSelect ? 'single-select' : 'multiple-select';
        return (
            this.props.defaultClasses +
            (' ' + this.props.classPrefix + type) +
            (this.props.width ? ' ' + this.props.classPrefix + 'width-' + this.props.width : '') +
            (this.props.className ? ' ' + this.props.className : '')
        );
    };

    render() {
        const collection = {};
        const collectionOrder = [];
        const collectionKeysAreNumeric = !isNaN(_.get(this.props.options, '0.0', 0));

        this.props.options.map((option) => {
            collection[option[0]] = {
                name: option[1],
                row: (
                    <span key={option[0]} className={'select-option-' + option[0]}>
                        {option[3] || option[1]}
                    </span>
                ),
                isDisabled: option[2] || false,
            };
            collectionOrder.push(option[0]);
        });

        const props = {
            identifier: 'multi-select-options',
            isLoading: this.props.isLoading,
            placeholder: this.props.placeholder,
            value: this.props.value,
            name: '',
            collection: collection,
            collectionOrder: collectionOrder,
            collectionKeysAreNumeric: collectionKeysAreNumeric,
            enableResetFilter: this.props.enableResetFilter,
            togglingListBottomLinks: this.props.togglingListBottomLinks || [],
            onChange: (identifier, values) => this.props.onChange(values),
            onToggle: (identifier, isOpened) => this.props.onToggle(isOpened),
        };

        if (this.props.isOpened !== undefined) {
            props.isOpened = this.props.isOpened;
        }

        if (this.props.collectionClassName !== undefined) {
            props.className = this.props.collectionClassName;
        }

        if (this.props.singleSelect) {
            if (this.props.defaultValue !== null) {
                props.defaultValue = this.props.defaultValue;
            }
        } else {
            props.selectedValuesDisplay = this.props.selectedValuesDisplay;
            props.selectedValuesSeparator = this.props.selectedValuesSeparator;
            props.value = props.value || [];
        }

        if (this.props.displayAsBadges !== undefined) {
            props.displayAsBadges = this.props.displayAsBadges;
        }

        return (
            <div className={this.buildClassName()}>
                <FilterGroup name={null}>
                    {this.props.singleSelect ? <RadioCollection {...props} /> : <CheckboxCollection {...props} />}
                </FilterGroup>
            </div>
        );
    }
};

export { Selector };
