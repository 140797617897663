import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { uniqueId } from 'Shared/resources/assets/app/js/helpers/generalHelpers';

const FilterGroup = class FilterGroup extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        className: PropTypes.string,
        id: PropTypes.string,
        zIndex: PropTypes.number,
    };

    /**
     * Get the default properties.
     *
     * @returns {object}
     */
    static defaultProps = {
        name: 'Filter',
        className: '',
        id: uniqueId('ui-filter-group-'),
        zIndex: 0,
    };

    /**
     * Define initial component state
     *
     * @returns {object}
     */
    state = {
        openedFilter: null,
    };

    /**
     * Toggle filter by identifier.
     *
     * @param {string} identifier
     */
    onToggle = (identifier) => {
        this.setState({
            openedFilter: this.state.openedFilter === identifier ? null : identifier,
        });
    };

    render() {
        const filtersCount = React.Children.count(this.props.children);

        return (
            <div className={`ui-filter-group ${this.props.className}`} id={this.props.id}>
                {this.props.name !== null && <div className="ui-filter-group-name">{ts(this.props.name)}:</div>}
                {React.Children.map(this.props.children, (filter, key) => {
                    if (filter === null) {
                        return;
                    }

                    return _.get(filter.props, 'className', '').indexOf('ui-filter-group-skip') !== -1 ? (
                        filter
                    ) : (
                        <div className="ui-filter-container">
                            {React.cloneElement(filter, {
                                ...filter.props,
                                zIndex: this.props.zIndex + (filtersCount - key) * 2,
                                tabIndex: this.props.zIndex + key,
                                isOpened: this.state.openedFilter === filter.props.identifier,
                                onToggle: (identifier, isOpened) => {
                                    this.onToggle(identifier, isOpened);
                                    const onToggle = filter.props.onToggle || (() => {});
                                    onToggle(identifier, isOpened);
                                },
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
};

export { FilterGroup };
