export const isParentSameDomain = function () {
    try {
        const doc = window.parent.document;

        if (!doc) {
            throw new Error('The parent is not accessible');
        }

        return true;
    } catch (e) {
        return false;
    }
};

export const getTopWindowDocument = function () {
    if (window.location.hostname.indexOf('office') !== -1) {
        return window.document;
    }

    return isParentSameDomain() ? window.top.document : window.document;
};

export const isIFrame = function () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};
