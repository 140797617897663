import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { WysiwygEditor } from 'Shared/resources/assets/app/js/ui/libs';
import { TextInputMultipleLines } from 'Shared/resources/assets/app/js/ui/forms/inputs';

const HTMLEditor = ({ plugins = [], onChange, settings = {}, ...otherProps }) => {
    const editorRef = useRef();
    const containerRef = useRef();

    useEffect(() => {
        const pluginNames = plugins.map((plugin) => plugin.name).join(',');
        const pluginAttributes = JSON.stringify(plugins.length ? plugins[0].attributes : '');

        const onChangeEditorData = ({ editor }) => {
            onChange(editor.getData());
        };

        const destroyCurrentEditor = () => {
            if (editorRef.current) {
                editorRef.current.destroy();
            }
        };

        WysiwygEditor.init(containerRef.current, {
            plugins: pluginNames,
            pluginAttributes,
            settings,
            onInstanceReadyCallback: ({ editor }) => {
                destroyCurrentEditor();
                if (otherProps.value) {
                    editor.setData(otherProps.value);
                }
                editor.on('change', onChangeEditorData);
                editorRef.current = editor;
            },
        });

        return () => destroyCurrentEditor();
        // eslint-disable-next-line
    }, []);

    return <TextInputMultipleLines getRef={(ref) => (containerRef.current = ref)} {...otherProps} />;
};

HTMLEditor.propTypes = {
    plugins: PropTypes.array,
    onChange: PropTypes.func,
    otherProps: PropTypes.array,
};

export { HTMLEditor };
