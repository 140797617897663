import React from 'react';
import _ from 'lodash';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { Config } from 'Shared/resources/assets/app/js/utils/Config';
import { FormGroup } from 'Shared/resources/assets/app/js/ui/forms/containers';
import { Button } from 'Shared/resources/assets/app/js/ui/forms/buttons';
import { Input } from 'Shared/resources/assets/app/js/ui/forms/inputs';
import { AbstractAddForm } from 'Core/Modules/Collections/resources/js/forms/AbstractAddForm';

/**
 * Standard collection item creation form.
 */
class AddStandardCollectionItem extends AbstractAddForm {
    /**
     * Get an empty item.
     *
     * @returns {object}
     */
    getEmptyItem() {
        const item = {
            code: '',
            name: {},
        };

        this.getActiveLanguages().forEach((activeLanguage) => {
            item.name[activeLanguage.code] = '';
        });

        return item;
    }

    /**
     * Check if the provided language is the app's default language.
     *
     * @param {object} language
     *
     * @returns {boolean}
     */
    isDefaultLanguage(language) {
        return language.id === this.getDefaultLanguage().id;
    }

    /**
     * Get the default language.
     *
     * @returns {object}
     */
    getDefaultLanguage() {
        return Config.get('app.defaultLanguage');
    }

    /**
     * Get active languages.
     *
     * @returns {array}
     */
    getActiveLanguages() {
        return Config.get('app.activeLanguages');
    }

    /**
     * Validate the item parameters.
     *
     * @returns {Promise<any>}
     */
    validate() {
        return new Promise((resolve) => {
            const errors = {};
            const defaultLanguage = this.getDefaultLanguage();

            if (_.get(this.state.item.name, defaultLanguage.code, '') === '') {
                _.setWith(errors, ['name', defaultLanguage.code], [ts('Required')], Object);
            }

            this.setState({ errors }, () => {
                if (_.isEmpty(errors)) {
                    resolve();
                }
            });
        });
    }

    /**
     * Focus the name input.
     */
    focusField() {
        this.defaultLanguageNameInputRef.focus();
    }

    render() {
        return (
            <div className="ui-locations-picker-add-item-form ui-form-vertical">
                {this.getActiveLanguages().map((language) => {
                    const isDefaultLanguage = this.isDefaultLanguage(language);
                    const setRef = isDefaultLanguage ? (ref) => (this.defaultLanguageNameInputRef = ref) : () => {};

                    return (
                        <FormGroup
                            key={language.code}
                            label={ts('Name [%1]', [language.short])}
                            required={isDefaultLanguage}
                            {...this.getValidationProps(['name', language.code])}
                        >
                            <Input
                                type="text"
                                setRef={setRef}
                                value={_.get(this.state.item.name, language.code, '')}
                                onChange={(e) => this.onValueChanged(['name', language.code], e.target.value)}
                            />
                        </FormGroup>
                    );
                })}

                <FormGroup label={ts('Code')} {...this.getValidationProps('code')}>
                    <Input
                        type="text"
                        value={this.state.item.code}
                        onChange={(e) => this.onValueChanged('code', e.target.value)}
                    />
                </FormGroup>

                <div className="centered-content">
                    <Button className="ui-button-secondary" value={ts('Create')} onClick={this.storeItem} />
                </div>
            </div>
        );
    }
}

export { AddStandardCollectionItem };
