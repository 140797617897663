import React from 'react';
import { createRoot } from 'react-dom/client';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { onDocumentReady } from 'Shared/resources/assets/app/js/helpers/domHelpers';
import { TimeCountdown } from 'Shared/resources/assets/app/js/ui/TimeCountdown';
import { laroute } from 'Shared/resources/assets/app/js/laroute';
import { GlobalConfigProvider } from 'Core/resources/assets/js/providers/GlobalConfigProvider';

onDocumentReady(() => {
    const container = document.getElementById('two-factor-time-countdown');

    if (!container) {
        return;
    }

    const seconds = parseInt(container.dataset.seconds);

    const root = createRoot(container);
    root.render(
        <GlobalConfigProvider>
            <TimeCountdown
                seconds={seconds}
                onFinish={() => {
                    document.getElementById('two-factor-auth-form').action =
                        laroute.route('post-resend-two-factor-auth');

                    const button = document.getElementById('two-factor-auth-submit-button');
                    button.value = ts('Resend');
                    button.classList.remove('ui-button-secondary');
                    button.classList.add('ui-button-primary');
                }}
            />
        </GlobalConfigProvider>
    );
});
