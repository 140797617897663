import PropTypes from 'prop-types';
import React from 'react';
import { ConfigProvider, Tooltip, theme } from 'antd';

const { useToken } = theme;

const LightTooltip = ({ overlayClassName, ...otherProps }) => {
    const { token } = useToken();

    return (
        <ConfigProvider
            theme={{
                components: {
                    Tooltip: {
                        zIndexPopup: 2001,
                    },
                },
            }}
        >
            <Tooltip color={token.colorBgBase} overlayInnerStyle={{color: token.colorTextBase}} {...otherProps} />
        </ConfigProvider>
    );
};

LightTooltip.propTypes = {
    overlayClassName: PropTypes.string,
};

export { LightTooltip };
