import { App } from 'Shared/resources/assets/app/js/App';
import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';

const Event = {
    listen: function (name, callback = () => {}, keepUniqueCallbacks = false) {
        const map = this.getEventsMap();

        map[name] || (map[name] = []);

        if (keepUniqueCallbacks) {
            this.stopListening(name, callback);
        }

        map[name].push(callback);
    },

    stopListening: function (name, callback) {
        const map = this.getEventsMap();

        map[name] = map[name].filter((item) => item.toString() !== callback.toString());
    },

    pop: function (name) {
        const map = this.getEventsMap();
        const lastItem = map[name][map[name].length - 1];

        map[name] = map[name].slice(0, map[name].length - 1);

        return lastItem;
    },

    fire: function (name, data = null) {
        const map = this.getEventsMap();

        if (!map[name]) {
            return;
        }

        map[name].forEach(function (callback) {
            callback(data);
        });
    },

    getEventsMap: function () {
        const topWindowDocument = getTopWindowDocument();

        if (!topWindowDocument.hasOwnProperty('appCustomEventsMap')) {
            topWindowDocument.appCustomEventsMap = {};
        }

        return topWindowDocument.appCustomEventsMap;
    },
};

App.Event = Event;

export { Event };
