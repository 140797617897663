const App = {
    ...window.App,
};

/* TODO:
 * the following is a workaround to have the App object globally available
 * to the scripts that make use of it in the php blade files.
 * They should be refactored and the JS code should be placed in JS modules.
 * Once this is done, this file can be deleted
 */
window.document.App = App;
window.App = App;

export { App };
