import React from 'react';
import PropTypes from 'prop-types';

import { ConfigProvider } from 'antd';
import * as AntDesignTheme from 'Core/resources/assets/js/AntDesign/AntDesignTheme.json';

import enUS from 'antd/locale/en_US';
import deDE from 'antd/locale/de_DE';
import itIT from 'antd/locale/it_IT';
import frFR from 'antd/locale/fr_FR';
import nlNL from 'antd/locale/nl_NL';

import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';

import { defaultValidateMessages } from 'Core/resources/assets/js/components/DynamicForm/validators/messages';
import { i18nMain } from 'Shared/resources/assets/app/js/utils/i18n/i18nMain';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', { weekStart: 1 }); // we always set Monday as the first day of the week

const currentLocale = i18nMain.getLanguageCode();
dayjs.locale(currentLocale);
const antLocaleMap = {
    en: enUS,
    de: deDE,
    it: itIT,
    fr: frFR,
    nl: nlNL,
};
const locale = antLocaleMap[currentLocale] ?? enUS;

const AntDesignConfigProvider = ({ children }) => (
    <ConfigProvider locale={locale} theme={AntDesignTheme} form={{ validateMessages: defaultValidateMessages }}>
        {children}
    </ConfigProvider>
);

AntDesignConfigProvider.propTypes = {
    children: PropTypes.object.isRequired,
};

export { AntDesignConfigProvider };
