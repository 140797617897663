import React from 'react';
import _ from 'lodash';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { DatePicker } from 'Shared/resources/assets/app/js/ui/pickers';

class MultipleDatesPicker extends React.Component {
    static defaultProps = {
        className: '',
        // large, medium, small
        width: 'large',
        multipleEnabled: false,
        onChange: () => {},
        options: {},
    };

    constructor(props) {
        super(props);
        let value = [];

        if (props.hasOwnProperty('defaultValue')) {
            value = props.defaultValue;
        }

        if (props.hasOwnProperty('value')) {
            value = props.value;
        }

        this.state = {
            multipleEnabled: props.multipleEnabled === true || value.length > 1,
            value: value,
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        let value = this.state.value;

        if (props.hasOwnProperty('value')) {
            value = props.value;
        } else if (props.hasOwnProperty('defaultValue')) {
            value = props.defaultValue;
        }

        this.setState({
            multipleEnabled: this.state.multipleEnabled || value.length > 1,
            value: value,
        });
    }

    getSortedValue = () => {
        const value = [...this.state.value];

        value.sort(function (a, b) {
            return new Date(a) - new Date(b);
        });

        return value;
    };

    reset = () => {
        this.setState({
            value: [],
        });
    };

    getMultipleTogglerClassName = () => {
        const classNames = [];

        if (this.state.multipleEnabled) {
            classNames.push(this.state.value.length > 1 ? 'ui-button-disabled' : 'ui-button-neutral active');
        } else {
            classNames.push('ui-button-neutral');
        }

        return classNames.join(' ');
    };

    /**
     * Get the suitable date picker depending on the multiple selection toggling state.
     */
    getDatePicker = () => {
        if (!this.state.multipleEnabled) {
            return (
                <DatePicker
                    key="single-date-selector"
                    value={this.state.value[0] || ''}
                    onChange={(value) => {
                        value = value === '' ? [] : [value];
                        this.setState({ value }, () => this.props.onChange(value));
                    }}
                />
            );
        }

        return (
            <DatePicker
                key="multiple-dates-selector"
                placeholder={ts('Please select multiple dates in the calendar below.')}
                value={this.state.value.join('; ')}
                onChange={(value) => {
                    value = _.filter(value.split('; '));

                    this.setState({ value }, () => this.props.onChange(value));
                }}
                options={{
                    ...this.props.options,
                    altFormatMultiple: ts('%1 dates selected between %2 and %3', [':count', ':min', ':max']),
                    inline: true,
                    mode: 'multiple',
                    allowInput: false,
                    weekNumbers: true,
                }}
            />
        );
    };

    render() {
        const multipleTogglerClassName = this.getMultipleTogglerClassName();

        let content = [
            this.getDatePicker(),
            <span
                className={multipleTogglerClassName}
                key="multiple-toggler"
                onClick={() => {
                    if (multipleTogglerClassName === 'ui-button-disabled') {
                        return;
                    }

                    this.setState({ multipleEnabled: !this.state.multipleEnabled });
                }}
            >
                <i className="fa fa-th" />
            </span>,
        ];

        const classNames = [
            'ui-multiple-dates-picker',
            `ui-form-element-width-${this.props.width}`,
            this.props.className,
        ];

        return <div className={classNames.join(' ')}>{content}</div>;
    }
}

export { MultipleDatesPicker };
