import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { DatePicker, DateTimePicker, TimePicker } from 'Shared/resources/assets/app/js/ui/pickers';
import dayjs from 'dayjs';

const DateTimeRange = class DateTimeRange extends React.Component {
    static propTypes = {
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
        identifier: PropTypes.string,
        value: PropTypes.object,
        isLoading: PropTypes.bool,
        isOpened: PropTypes.bool,
        isHidden: PropTypes.bool,
        zIndex: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        onToggle: PropTypes.func,
        pickerType: PropTypes.string,
        options: PropTypes.object,
        default: PropTypes.object,
    };

    static defaultProps = {
        identifier: 'date',
        value: { from: '', to: '' },
        isLoading: false,
        isOpened: false,
        isHidden: false,
        zIndex: 1,
        onToggle: () => {},
        pickerType: 'date',
        options: {},
        default: { from: '', to: '' },
    };

    state = {
        isOpened: this.props.isOpened,
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.isOpened !== this.state.isOpened) {
            this.setState({
                isOpened: newProps.isOpened,
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.closeTimeout);
    }

    onToggle = (event) => {
        if (event && (event?.target.matches('.fa-times-circle') || !event?.target.closest('.title'))) {
            return;
        }

        clearTimeout(this.closeTimeout);

        this.setState(
            {
                isOpened: !this.state.isOpened,
            },
            this.props.onToggle.bind(null, this.props.identifier, this.state.isOpened)
        );
    };

    onMouseLeave = (timeout) => {
        timeout = _.isInteger(timeout) ? timeout : 500;

        clearTimeout(this.closeTimeout);

        this.closeTimeout = setTimeout(() => this.state.isOpened && this.onToggle(), timeout);
    };

    onMouseEnter = () => {
        clearTimeout(this.closeTimeout);
    };

    formatDate = (date) => {
        switch (this.props.pickerType) {
            case 'date':
                return dayjs(date).format('YYYY.MM.DD');

            case 'datetime':
                return dayjs(date).format('YYYY.MM.DD HH:mm');

            case 'time':
                return date.substr(0, 5);
        }
    };

    renderPicker = (pickerValue, onChange) => {
        const props = {
            className: 'ui-form-element-date',
            value: pickerValue,
            onMouseEnter: this.onMouseEnter,
            onClose: this.onMouseLeave.bind(null, 1500),
            onChange: onChange,
            options: this.props.options,
        };

        switch (this.props.pickerType) {
            case 'date':
                return <DatePicker {...props} />;

            case 'datetime':
                return <DateTimePicker {...props} />;

            case 'time':
                return <TimePicker {...props} />;
        }
    };

    renderTitle() {
        const fromIsSet = !_.isEmpty(this.props.value.from);
        const toIsSet = !_.isEmpty(this.props.value.to);
        const isActive = fromIsSet || toIsSet;

        let valueLabel = '';
        let title = '';

        if (isActive) {
            const from = fromIsSet ? this.formatDate(this.props.value.from) : '...';
            const to = toIsSet ? this.formatDate(this.props.value.to) : '...';
            const isDefault = _.isEqual(this.props.value, this.props.default);

            valueLabel = `${from} - ${to}`;
            title = (
                <div className="value-label" title={valueLabel}>
                    <span>{valueLabel}</span>

                    {this.props.isLoading ? (
                        <i className="fa fa-spinner fa-spin" />
                    ) : (
                        !isDefault && (
                            <i
                                title={ts('Reset filter')}
                                className="fa fa-times-circle"
                                onClick={this.props.onChange.bind(null, this.props.identifier, this.props.default)}
                            />
                        )
                    )}
                </div>
            );
        } else if (this.props.isLoading) {
            title = <i className="fa fa-spinner fa-spin" />;
        } else {
            title = <i className={'fa fa-sort-' + (this.state.isOpened ? 'asc' : 'desc')} />;
        }

        return (
            <div className="title" style={{ zIndex: this.props.zIndex + 1 }}>
                {ts.apply(null, _.isArray(this.props.name) ? this.props.name : [this.props.name])}
                {title}
            </div>
        );
    }

    render() {
        if (this.props.isHidden) {
            return null;
        }

        const isActive = !_.isEmpty(this.props.value.from) || !_.isEmpty(this.props.value.to);

        return (
            <div
                key={this.props.identifier}
                className={[
                    'ui-filter',
                    'date-time-range',
                    isActive ? 'active' : '',
                    this.props.isLoading ? 'loading' : '',
                    this.state.isOpened ? 'opened' : '',
                ].join(' ')}
                onClick={this.onToggle}
                onMouseOver={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                {this.renderTitle()}
                <div className="toggling-list" style={{ zIndex: this.props.zIndex }}>
                    <div className="ui-form-horizontal">
                        <div className="ui-form-group">
                            <label className="ui-form-label" title={ts('From')}>
                                {ts('From')}
                            </label>
                            {this.renderPicker(_.get(this.props.value, 'from', ''), (value) =>
                                this.props.onChange(this.props.identifier, { ...this.props.value, from: value })
                            )}
                        </div>
                        <div className="ui-form-group">
                            <label className="ui-form-label" title={ts('To')}>
                                {ts('To')}
                            </label>
                            {this.renderPicker(_.get(this.props.value, 'to', ''), (value) =>
                                this.props.onChange(this.props.identifier, { ...this.props.value, to: value })
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export { DateTimeRange };
